import { ChangeDetectorRef, Component, ComponentRef, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellFileResourceService } from 'src/app/shared/generated/api/well-file-resource.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellFileResourceSimpleDto } from 'src/app/shared/generated/model/well-file-resource-simple-dto';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import {Alert} from 'src/app/shared//models/alert';
import { environment } from 'src/environments/environment';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { FileResourceSimpleDto } from 'src/app/shared/generated/model/file-resource-simple-dto';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { WellFileResourceUpdateDto } from 'src/app/shared/generated/model/well-file-resource-update-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';


@Component({
  selector: 'well-attachments',
  templateUrl: './well-attachments.component.html',
  styleUrls: ['./well-attachments.component.scss']
})
export class WellAttachmentsComponent implements OnInit {

  private currentUser: UserDto; 
  private wellID: number;
  public wellFileResources: WellFileResourceSimpleDto[];

  public fileUpload: File;
  public fileDescription: string;
  private fileUploadElement: HTMLElement;
  public fileUploadElementID = "file-upload";
  public fileUploadField = "FileResource";

  private updateModalComponent: ComponentRef<ModalComponent>;
  public wellFileResourceToUpdate: WellFileResourceSimpleDto;
  public model: WellFileResourceUpdateDto;
  
  public isLoadingSubmit = false;
  public richTextTypeID = CustomRichTextTypeEnum.WellRegistryAttachments;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private wellFileResourceService: WellFileResourceService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private formValidationService: FormValidationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const id = this.route.snapshot.paramMap.get(routeParams.wellID);
      if (id) {
        this.wellID = parseInt(id);

        this.wellService.wellsWellIDWellFileResourcesGet(this.wellID).subscribe(wellFileResources => {
          this.wellFileResources = wellFileResources;
        });
      }

      this.model = new WellFileResourceUpdateDto();
      this.formValidationService.createValidationModelFromFieldList([this.fileUploadField]);

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public continue() {
    this.router.navigateByUrl(`well-registry/well/${this.wellID}/edit/submit`);
  }

  public openFileResourceLink(fileResource: FileResourceSimpleDto) {
    window.open(`${environment.mainAppApiUrl}/fileResources/${fileResource.FileResourceGUID}`, '_blank');
  }

  public onClickFileUpload() {
    if (!this.fileUploadElement) {
      this.fileUploadElement = document.getElementById(this.fileUploadElementID);
    }

    this.fileUploadElement.click();
  }

  public onFileUploadChange(event: any) {
    if (!event.target.files || !event.target.files.length) {
      this.fileUpload = null;
    }

    const [file] = event.target.files;
    this.fileUpload = event.target.files.item(0);
  }

  public createWellFileResource() {
    this.formValidationService.clearErrors();

    if (!this.fileUpload) {
      this.formValidationService.pushError(this.fileUploadField, "Please select a file to upload.");
      return;
    }

    this.isLoadingSubmit = true;
    this.wellFileResourceService.wellFileResourcesPost(this.wellID, this.fileUpload, this.fileDescription).subscribe(wellFileResource => {
      this.isLoadingSubmit = false;
      this.alertService.pushAlert(new Alert("File successfully uploaded", AlertContext.Success));

      this.wellFileResources.splice(0, 0, wellFileResource);
      this.fileUpload = null;
      this.fileDescription = "";
    }, error => {
      this.isLoadingSubmit = false;
      this.formValidationService.pushErrorsFromHttpResponse(error);
      this.cdr.detectChanges();
    });
  }

  public deleteWellFileResource(wellFileResource: WellFileResourceSimpleDto, index: number) {
    this.alertService.clearAlerts();

    const message = `You are about to delete <b>${wellFileResource.FileResource.OriginalBaseFilename}</b> as an attachment. Are you sure you wish to proceed?`;
    this.confirmService.confirm({title: "Delete Attachment", message: message, buttonTextYes: "Delete", buttonClassYes: "btn-danger", buttonTextNo: "Cancel"}).then(confirmed => {
      if (confirmed) {

        this.wellFileResourceService.wellFileResourcesWellFileResourceIDDelete(wellFileResource.WellFileResourceID).subscribe(x => {
          this.alertService.pushAlert(new Alert("File successfully deleted", AlertContext.Success));
    
          this.wellFileResources.splice(index, 1);
        });
      }
    });
  }

  open(template: TemplateRef<any>, wellFileResource): void {
    this.wellFileResourceToUpdate = wellFileResource;
    this.model.FileDescription = wellFileResource.FileDescription;

    this.updateModalComponent = this.modalService.open(template, this.viewContainerRef);
  }

  close() : void {
    if (!this.updateModalComponent) return;
    this.modalService.close(this.updateModalComponent);
  }

  public updateWellFileResource() {
    this.close();
    this.alertService.clearAlerts();

    this.wellFileResourceService.wellFileResourcesWellFileResourceIDPut(this.wellFileResourceToUpdate.WellFileResourceID, this.model).subscribe(() => {
      this.wellFileResourceToUpdate.FileDescription = this.model.FileDescription;
      
      this.wellFileResourceToUpdate = null;
      this.model.FileDescription = "";

      this.alertService.pushAlert(new Alert("File description successfully updated", AlertContext.Success));
    });
  }
}
