import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomPageService } from 'src/app/shared/generated/api/custom-page.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EditorComponent } from "@tinymce/tinymce-angular";
import TinyMCEHelpers from "src/app/shared/helpers/tiny-mce-helpers";
import { CustomPageDto, CustomPageUpsertDto } from 'src/app/shared/generated/model/models';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { routeParams } from 'src/app/app-routing.module';

@Component({
  selector: 'fairyshrimp-custom-page-detail',
  templateUrl: './custom-page-detail.component.html',
  styleUrls: ['./custom-page-detail.component.scss']
})
export class CustomPageDetailComponent implements OnInit {
  @Input() customPageVanityUrl: string;
  @ViewChild('tinyMceEditor') tinyMceEditor : EditorComponent;
  public tinyMceConfig: object;
  public customPageContent: SafeHtml;
  public customPageDisplayName: string;
  public viewableRoleIDs: Array<number>;
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public isEmptyContent: boolean = false;
  
  public editedContent: string;
  public originalContent: string = '';
  
  private currentUser: UserDto;
  
  ngAfterViewInit(): void {
    // We need to use ngAfterViewInit because the image upload needs a reference to the component
    // to setup the blobCache for image base64 encoding
    this.tinyMceConfig = TinyMCEHelpers.DefaultInitConfig()
  }
  public customPage: CustomPageDto;

  constructor(
    private customPageService: CustomPageService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer) {
      // force route reload whenever params change
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit() {
    const vanityUrl = this.route.snapshot.paramMap.get(routeParams.customPageVanityUrl);

    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.customPageService.customPagesGetByURLCustomPageVanityURLRolesGet(vanityUrl).subscribe(pageRoleDtos => {
        this.viewableRoleIDs = pageRoleDtos.map(pageRole => pageRole.RoleID);
      });
    });
    
    if (vanityUrl) {
      this.customPageService.customPagesGetByURLCustomPageVanityURLGet(vanityUrl).subscribe(customPage => {
        this.loadCustomPage(customPage);
        this.customPageContent = this.sanitizer.bypassSecurityTrustHtml(customPage.CustomPageContent);
        this.customPageDisplayName = customPage.CustomPageDisplayName;
        this.originalContent = customPage.CustomPageContent;
        this.editedContent = this.originalContent;
      });
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  canExit() {
    return !this.isEditing || this.originalContent == this.editedContent;
  };

  public canReadCustomPages(): boolean {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Read);
  }

  public showEditButton(): boolean {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Update);
  }

  public enterEdit(): void {
    this.isEditing = true;
  }

  public cancelEdit(): void {
    this.isEditing = false;
  }

  public saveEdit(): void {
    this.isEditing = false;
    this.isLoading = true;
    const updateDto = new CustomPageUpsertDto({
      CustomPageDisplayName: this.customPageDisplayName,
      CustomPageVanityUrl: this.customPage.CustomPageVanityUrl,
      CustomPageContent: this.editedContent,
      MenuItemID: this.customPage.MenuItem.MenuItemID,
      ViewableRoleIDs: this.viewableRoleIDs
     });

    this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, updateDto).subscribe(x => {
      this.customPageContent = this.sanitizer.bypassSecurityTrustHtml(x.CustomPageContent);
      this.editedContent = x.CustomPageContent;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
    });
  }

  private loadCustomPage(customPage: CustomPageDto)
  {
    this.customPage = customPage; 
    this.isEmptyContent = !!customPage.CustomPageContent;
    this.isLoading = false;
  }
}