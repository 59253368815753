import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NotFoundComponent } from "./pages";
import { HeaderNavComponent } from "./components";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";
import { SubscriptionInsufficientComponent } from "./pages/subscription-insufficient/subscription-insufficient.component";
import { NgProgressModule } from "ngx-progressbar";
import { RouterModule } from "@angular/router";
import { WatershedDetailPopupComponent } from "./components/watershed-detail-popup/watershed-detail-popup.component";
import { LinkRendererComponent } from "./components/ag-grid/link-renderer/link-renderer.component";
import { FontAwesomeIconLinkRendererComponent } from "./components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import { WatershedMapComponent } from "./components/watershed-map/watershed-map.component";
import { MultiLinkRendererComponent } from "./components/ag-grid/multi-link-renderer/multi-link-renderer.component";
import { CustomRichTextComponent } from "./components/custom-rich-text/custom-rich-text.component";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { FieldDefinitionComponent } from "./components/field-definition/field-definition.component";
import { AlertDisplayComponent } from "./components/alert-display/alert-display.component";
import { FieldDefinitionGridHeaderComponent } from "./components/field-definition-grid-header/field-definition-grid-header.component";
import { CustomPinnedRowRendererComponent } from "./components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "./components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CsvDownloadButtonComponent } from "./components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "./components/clear-grid-filters-button/clear-grid-filters-button.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { ContextMenuRendererComponent } from "./components/ag-grid/context-menu/context-menu-renderer.component";
import { FooterNavComponent } from "./footer-nav/footer-nav.component";
import { DropdownToggleDirective } from "./directives/dropdown-toggle.directive";
import { PopperDirective } from "./directives/popper.directive";
import { PopperComponent } from './components/popper/popper.component';
import { ParcelMapComponent } from "./components/parcel-map/parcel-map.component";
import { ButtonComponent } from './components/button/button.component';
import { AlertComponent } from './components/alert/alert.component';
import { ModalComponent } from "./components/modal/modal.component";
import { ParcelMinimapComponent } from './components/parcel-minimap/parcel-minimap.component';
import { FieldValidationAreaComponent } from './components/field-validation-area/field-validation-area.component';
import { WellLocationMapComponent } from './components/well-location-map/well-location-map.component';
import { AssociatedParcelsEditComponent } from './components/associated-parcels-edit/associated-parcels-edit.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ProgressIconComponent } from "./components/progress-icon/progress-icon.component";
import { LoadingDirective } from "./directives/loading.directive";
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserProfileEditComponent } from './components/user-profile-edit/user-profile-edit.component';
import { NgxMaskModule } from "ngx-mask";
import { MissingRequiredFieldsModalComponent } from './components/missing-required-fields-modal/missing-required-fields-modal.component';

@NgModule({
    declarations: [
        AlertDisplayComponent,
        HeaderNavComponent,
        NotFoundComponent,
        UnauthenticatedComponent,
        SubscriptionInsufficientComponent,
        WatershedMapComponent,
        WatershedDetailPopupComponent,
        LinkRendererComponent,
        FontAwesomeIconLinkRendererComponent,
        MultiLinkRendererComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CustomPinnedRowRendererComponent,
        CustomDropdownFilterComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ConfirmModalComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        DropdownToggleDirective,
        PopperDirective,
        PopperComponent,
        ParcelMapComponent,
        ButtonComponent,
        AlertComponent,
        ModalComponent,
        ParcelMinimapComponent,
        FieldValidationAreaComponent,
        WellLocationMapComponent,
        AssociatedParcelsEditComponent,
        ProgressIconComponent,
        LoadingDirective,
        UserProfileComponent,
        UserProfileEditComponent,
        MissingRequiredFieldsModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgProgressModule,
        RouterModule,
        EditorModule,
        NgSelectModule,
        NgxMaskModule.forChild(),
    ],
    exports: [
        AlertDisplayComponent,
        CommonModule,
        FormsModule,
        NotFoundComponent,
        WatershedMapComponent,
        HeaderNavComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ConfirmModalComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        PopperDirective,
        ParcelMapComponent,
        ButtonComponent,
        ParcelMinimapComponent,
        FieldValidationAreaComponent,
        WellLocationMapComponent,
        AssociatedParcelsEditComponent,
        ProgressIconComponent,
        LoadingDirective,
        UserProfileComponent,
        UserProfileEditComponent,
        EditorModule
    ],
    providers:[
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' }
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
