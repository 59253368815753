import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RoleService } from '../../generated/api/role.service';
import { StateService } from '../../generated/api/state.service';
import { UserService } from '../../generated/api/user.service';
import { PermissionEnum } from '../../generated/enum/permission-enum';
import { RoleDto } from '../../generated/model/role-dto';
import { StateDto } from '../../generated/model/state-dto';
import { UserDto } from '../../generated/model/user-dto';
import { Alert } from '../../models/alert';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { RightsEnum } from '../../models/enums/rights.enum';
import { AlertService } from '../../services/alert.service';
import { MissingRequiredFieldsService } from 'src/app/services/missing-required-fields.service';

@Component({
  selector: 'user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements OnInit {
  @Input() user : UserDto;
  @Input() hideCancel: false;
  @Output() onEditClose = new EventEmitter<UserDto>();
  public states$ : Observable<StateDto[]>;
  public roles$ : Observable<RoleDto[]>;
  public currentUser$: Observable<UserDto>;
  public userEditingIsCurrentUser: boolean = false;

  public userModel : UserDto;

  constructor(
    private userService: UserService, 
    private alertService: AlertService, 
    private stateService: StateService, 
    private roleService: RoleService, 
    private authenticationService: AuthenticationService,
    private missingRequiredFieldsService: MissingRequiredFieldsService
  ) { }

  ngOnInit(): void {
    if (!this.user.Phone || this.user.Phone.length != 10){
      this.alertService.pushAlert(
        new Alert('Phone Numbers are required to complete your account. Please provide and save below.', AlertContext.Warning, false));
    }
    
  }

  ngOnChanges(): void {
    this.states$ = this.stateService.statesGet();
    this.roles$ = this.roleService.rolesGet();
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
      tap(currentUser => {
        if(this.user){
          this.userEditingIsCurrentUser = currentUser.UserID == this.user.UserID;
        }
      })
    );
    this.userModel = {...this.user};
  }

  onSubmit() : void {
    this.alertService.clearAlerts();
    var missingFields = this.validateRequiredFields();
    if(!missingFields){
      this.userService.usersUserIDProfilePut(this.user.UserID, this.userModel).subscribe(response => {
        if(this.userEditingIsCurrentUser) {
          this.authenticationService.refreshUserInfo(response);
        }
        this.onEditClose.next(response);
      })
    }
    else {
      this.missingRequiredFieldsService.error({title: 'There are missing required fields.', message: `You are missing the following required fields.<ul>${missingFields}</ul>`, buttonText:"Return"});
    }

  }

  validateRequiredFields(){
    var missingFields = '';
    
    if (!this.userModel.Phone || this.userModel.Phone.length != 10){
      this.alertService.pushAlert(
        new Alert('Phone Numbers are required to complete your account. Please provide and save below.', AlertContext.Warning, false));
      
      this.alertService.pushAlert(
        new Alert('Invalid phone number. Please enter a phone number in the format (###) ###-####.', AlertContext.Danger)
      )
      missingFields+="<li>Phone Number</li>";
    }
    if (!this.userModel.FirstName){
      this.alertService.pushAlert(
        new Alert('First Name is a required field. Please enter a first name.', AlertContext.Danger)
      )
      missingFields+="<li>First Name</li>";
    }
    if (!this.userModel.LastName){
      this.alertService.pushAlert(
        new Alert('Last Name is a required field. Please enter a last name.', AlertContext.Danger)
      )
      missingFields+="<li>Last Name</li>";
    }
    
    return missingFields;
  }
  
  cancel(): void {
    this.alertService.pushAlert(new Alert("Cancelled profile edit", AlertContext.Primary, true))
    this.onEditClose.next(null);
  }

  compareStates(itemOne: StateDto, itemTwo: StateDto) : boolean {
    return itemOne?.StateID == itemTwo?.StateID;
  }

  checkReceiveSupportEmails(): void {
    if (this.userModel.Role.RoleID != 1){
      this.userModel.ReceiveSupportEmails = false;
    }
  }

  canEditRoles(currentUser: UserDto) : boolean {
    return this.authenticationService.hasPermission(currentUser, PermissionEnum.UserRights, RightsEnum.Update);
  }

}
