<div class="list-view">
    <div class="page-header">
        <h2 class="page-title">Parcels</h2>

        <div class="copy copy-2">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div class="map-wrapper" *ngIf="parcels">
            <parcel-map mapID="parcelMap" mapHeight="540px" [(highlightedParcelID)]="highlightedParcelID" [disableDefaultClick]="true" [highlightParcelOnClick]="true" visibleParcelStyle="parcel_labeled">
            </parcel-map>
        </div>

        <div class="filter-btn-group flex-end mb-3">
            <div class="clear-btn mr-2">
                <fairyshrimp-clear-grid-filters-button [grid]="parcelGrid"></fairyshrimp-clear-grid-filters-button>
            </div>

            <div class="download-btn">
                <fairyshrimp-csv-download-button [grid]="parcelGrid" fileName="parcels" [colIDsToExclude]="csvDownloadColIDsToExclude"></fairyshrimp-csv-download-button>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #parcelGrid style="width: 100%; height: 720px;" class="ag-theme-balham" 
                [rowData]="parcels" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100"
                rowSelection="single" (selectionChanged)="onSelectionChanged($event)" (filterChanged)="selectHighlightedParcelIDRowNode()">
            </ag-grid-angular>
        </div>
    </div>
</div>