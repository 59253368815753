import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CustomRichTextService } from 'src/app/shared/generated/api/custom-rich-text.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { EditorComponent } from "@tinymce/tinymce-angular";
import TinyMCEHelpers from "../../helpers/tiny-mce-helpers";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomRichTextDto } from 'src/app/shared/generated/model/custom-rich-text-dto';
import { PermissionEnum } from '../../generated/enum/permission-enum';
import { RightsEnum } from '../../models/enums/rights.enum';
import { ContentTypeEnum } from '../../generated/enum/content-type-enum';

@Component({
  selector: 'custom-rich-text',
  templateUrl: './custom-rich-text.component.html',
  styleUrls: ['./custom-rich-text.component.scss']
})
export class CustomRichTextComponent implements OnInit, AfterViewInit{
  @Input() customRichTextTypeID: number;
  @ViewChild('tinyMceEditor') tinyMceEditor : EditorComponent;
  public tinyMceConfig: object;

  private currentUser: UserDto;

  public customRichTextTitle: string;
  public editedTitle: string;
  public showTitle: boolean = false;

  public customRichTextContent: SafeHtml;
  public editedContent: string;
  public isEmptyContent: boolean = false;

  public isLoading: boolean = true;
  public isEditing: boolean = false;


  constructor(
    private customRichTextService: CustomRichTextService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit(): void {
    // We need to use ngAfterViewInit because the image upload needs a reference to the component
    // to setup the blobCache for image base64 encoding
    this.tinyMceConfig = TinyMCEHelpers.DefaultInitConfig(this.tinyMceEditor)
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
    });

    this.customRichTextService.customRichTextCustomRichTextTypeIDGet(this.customRichTextTypeID).subscribe(x => {
      this.loadCustomRichText(x);
      
      if (x.CustomRichTextType?.ContentType?.ContentTypeID == ContentTypeEnum.FormInstructions) {
        this.showTitle = true;
      }
    });
  }

  private loadCustomRichText(customRichText: CustomRichTextDto) {
    this.customRichTextTitle = customRichText.CustomRichTextTitle;
    this.editedTitle = this.customRichTextTitle;

    this.customRichTextContent = this.sanitizer.bypassSecurityTrustHtml(customRichText.CustomRichTextContent);
    this.editedContent = customRichText.CustomRichTextContent;
    this.isEmptyContent = customRichText.IsEmptyContent;

    this.isLoading = false;
  }

  public showEditButton(): boolean {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);
  }

  public enterEdit(): void {
    this.isEditing = true;
  }

  public cancelEdit(): void {
    this.isEditing = false;
  }

  public saveEdit(): void {
    this.isEditing = false;
    this.isLoading = true;

    const updateDto = new CustomRichTextDto({ CustomRichTextTitle: this.editedTitle, CustomRichTextContent: this.editedContent });
    this.customRichTextService.customRichTextCustomRichTextTypeIDPut(this.customRichTextTypeID, updateDto).subscribe(x => {
      this.loadCustomRichText(x);
    }, error => {
      this.isLoading = false;
      this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
    });
  }
}