<div ngbDropdown container="body" class="menu-container" *ngIf="!params.hidden">
    <button class="context-menu-btn btn btn-primary" id="menu-dropdown" [dropdownToggle]="contextMenu">{{params.title}}</button>
    <div #contextMenu class="context-menu" aria-labelledby="menu-dropdown">
        <ng-container *ngFor="let action of params.value">
            <button *ngIf="action.ActionHandler" (click)="action.ActionHandler()" ngbDropdownItem>
                <span *ngIf="action.ActionIcon" [class]="action.ActionIcon"></span>
                {{action.ActionName}}
            </button>
            <a *ngIf="!action.ActionHandler" ngbDropdownItem [routerLink]="[action.ActionLink]">
                <span *ngIf="action.ActionIcon" [class]="action.ActionIcon"></span>
                {{action.ActionName}}
            </a>
        </ng-container>
    </div>
</div>