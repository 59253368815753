import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WellService } from '../generated/api/well.service';
import { WellProgressDto } from '../generated/model/well-progress-dto';

@Injectable({
    providedIn: 'root'
})
export class ProgressService{

    private wellProgress: WellProgressDto;
    public progressSubject: Subject<WellProgressDto> = new Subject();

    constructor (
        private wellService: WellService
    ) { }

    public requestWellProgress(wellID: number): Observable<WellProgressDto> {
        this.wellService.wellsWellIDProgressGet(wellID).subscribe(wellProgress => {
            this.wellProgress = wellProgress;
            this.emitProgress();
        });

        return this.progressSubject.asObservable();
    }

    private emitProgress() {
        // Ensure change detection always see change
        this.progressSubject.next(this.wellProgress);
    }

    public isWorkflowComplete() {
        return this.wellProgress?.IsLocationStepComplete && this.wellProgress?.IsContactsStepComplete 
            && this.wellProgress?.IsBasicsStepComplete && this.wellProgress?.IsSupportingInfoStepComplete;
    }

    public pushLocationStepProgress(isLocationStepComplete: boolean) {
        this.wellProgress.IsLocationStepComplete = isLocationStepComplete;
        this.emitProgress();
    }

    public pushContactsStepProgress(isContactsStepComplete: boolean) {
        this.wellProgress.IsContactsStepComplete = isContactsStepComplete;
        this.emitProgress();
    }

    public pushBasicsStepProgress(isBasicsStepComplete: boolean) {
        this.wellProgress.IsBasicsStepComplete = isBasicsStepComplete;
        this.emitProgress();
    }

    public pushSupportingInfoStepProgress(isSupportingInfoStepComplete: boolean) {
        this.wellProgress.IsSupportingInfoStepComplete = isSupportingInfoStepComplete;
        this.emitProgress();
    }
}