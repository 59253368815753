<footer class="site-footer grid-12">
    <div class="sitemap">
        <div class="col">
            <h4 class="footer-header">My Account</h4>
            <ul>
                <ng-container *ngIf="authenticationService.isAuthenticated() else unauthenticated">
                    <li><button class="btn-link btn" (click)="authenticationService.logout()">Sign Out</button></li>
                    <li><button class="btn-link btn" (click)="authenticationService.resetPassword()">Change Password</button></li>
                </ng-container>
                <ng-template #unauthenticated>
                    <li><button class="btn-link btn" (click)="authenticationService.login()">Sign In</button></li>
                    <li><button class="btn-link btn" (click)="authenticationService.login()">Create Account</button></li>
                    <li><button class="btn-link btn" (click)="authenticationService.resetPassword()">Forgot Password?</button></li>
                </ng-template>
            </ul>
        </div>

        <div class="col">
            <h4 class="footer-header" *ngIf="urlsForFooter?.length > 0">Learn More</h4>
            <ul>
                <!-- <li><a href="/disclaimer/false">Disclaimer</a></li> -->
                <li *ngFor="let dto of urlsForFooter">
                    <a [routerLink]="dto.Url"> {{ dto.Name }} </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="credits">
        <custom-rich-text [customRichTextTypeID]=customRichTextID></custom-rich-text>
    </div>
</footer>