<app-alert-display> </app-alert-display>
<ng-container *ngIf="currentUser$ | async as currentUser">
    <ng-container *ngIf="user$ | async as user">
        <div class="page-header flex-between">
            <h1 class="page-title">
                {{ user?.FullName || (user?.FirstName || user?.LastName ? user?.FirstName + " " + user?.LastName : null) }}

                <a class="impersonate__link" *ngIf="canImpersonate(user, currentUser)" (click)="impersonateUser(user)">(Impersonate)</a>
            </h1>
            <fresca-button (onClick)="resetPassword(user)" class="mb-3" cssClass=" btn btn-secondary" iconClass=""><i class="fas fa-key"></i> Reset Password</fresca-button>
        </div>
        <div class="page-body grid-12">
            <div class="card g-col-6">
                <div class="card-header flex-between">
                    <h3 class="card-title">Contact Information</h3>
                    <div class="card-actions" *ngIf="!isEditingUserProfile">
                        <button *ngIf="canUpdateUser(user, currentUser)" class="btn btn-secondary" (click)="isEditingUserProfile = !isEditingUserProfile"><span class="fas fa-edit"></span> Edit</button>
                    </div>
                </div>
                <div class="card-body">
                    <ng-container *ngIf="isEditingUserProfile; else userProfileDetails">
                        <user-profile-edit [user]="user" (onEditClose)="editClosed($event)"></user-profile-edit>
                    </ng-container>
                    <ng-template #userProfileDetails>
                        <user-profile [user]="user"> </user-profile>
                    </ng-template>
                </div>
            </div>
            <div class="card g-col-6">
                <div class="card-header flex-between">
                    <h3 class="card-title">Parcels</h3>

                    <div class="card-actions">
                        <a *ngIf="canUpdateUser(user, currentUser)" class="btn btn-secondary-outline" routerLink="manage-parcels"> <i class="fas fa-clipboard-list"></i> Manage </a>
                    </div>
                </div>

                <div class="card-body">
                    <ng-container *ngIf="userParcels$ | async as userParcels">
                        <div *ngIf="userParcels?.length > 0" class="copy copy-3">
                            <ul class="parcels-list">
                                <li *ngFor="let parcel of userParcels">
                                    {{ parcel.ParcelNumber }}
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="userParcels?.length == 0" class="copy copy-3">
                            <p>
                                <em>This user hasn't claimed any parcels yet</em>
                            </p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
