<div class="list-view">
    <div class="page-header flex-between">
        <h2 class="page-title">Wells</h2>

        <fresca-button [routerLink]="['/well-registry/new']" class="mb-3">Register a Well</fresca-button>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>


        <div class="filter-btn-group flex-end mb-3">
            <div class="clear-btn mr-2">
                <fairyshrimp-clear-grid-filters-button [grid]="wellGrid"></fairyshrimp-clear-grid-filters-button>
            </div>

            <div class="download-btn">
                <fairyshrimp-csv-download-button [grid]="wellGrid" fileName="wells" [colIDsToExclude]="csvDownloadColIDsToExclude"></fairyshrimp-csv-download-button>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #wellGrid style="width: 100%; height: 720px;" class="ag-theme-balham" [getRowNodeId]="getRowId"  (gridReady)="onGridReady($event)"
                [rowData]="wells" [columnDefs]="wellColumnDefs" [defaultColDef]="defaultColDef">
            </ag-grid-angular>
        </div>

        <hr class="section-spacer" />

        <div class="flex-between mt-5 mb-2">
            <h2 class="module-title">Well Inventory</h2>
            <div class="filter-btn-group">
                <div class="clear-btn">
                    <fairyshrimp-clear-grid-filters-button [grid]="referenceWellGrid"></fairyshrimp-clear-grid-filters-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #referenceWellGrid style="width: 100%; height: 300px;" class="ag-theme-balham" 
            
                [rowData]="referenceWells" [columnDefs]="referenceWellColumnDefs" [defaultColDef]="defaultColDef">
            </ag-grid-angular>
        </div>
    </div>
</div>