import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AgGridAngular } from "ag-grid-angular";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { ColDef } from "ag-grid-community";
import { ParcelService } from "src/app/shared/generated/api/parcel.service";
import { ParcelDto } from "src/app/shared/generated/model/parcel-dto";
import { MultiLinkRendererComponent } from "src/app/shared/components/ag-grid/multi-link-renderer/multi-link-renderer.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";

@Component({
    selector: "fairyshrimp-parcel-list",
    templateUrl: "./parcel-list.component.html",
    styleUrls: ["./parcel-list.component.scss"],
})
export class ParcelListComponent implements OnInit, OnDestroy {
    @ViewChild("parcelGrid") parcelGrid: AgGridAngular;

    private currentUser: UserDto;

    public parcels: ParcelDto[];
    public selectedParcelIDs: number[];

    public _highlightedParcelID: number;
    public set highlightedParcelID(value : number) {
      if (value != this._highlightedParcelID) {
        this._highlightedParcelID = value;
        this.selectHighlightedParcelIDRowNode();
      }
    }
    public get highlightedParcelID() {
      return this._highlightedParcelID;
    }

    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public csvDownloadColIDsToExclude = ['0'];
    public richTextTypeID: number = CustomRichTextTypeEnum.ParcelList;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private utilityFunctionsService: UtilityFunctionsService,
        private parcelService: ParcelService
    ) {}

    ngOnInit() {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;

            this.parcelService.parcelsGet().subscribe(parcels => {
                this.parcels = parcels;
                this.selectedParcelIDs = parcels.map(x => x.ParcelID);
                
                this.cdr.detectChanges();
            });

            this.createColumnDefs();
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    private createColumnDefs() {
        this.columnDefs = [
          {
            valueGetter: (params: any) => {
              return { LinkValue: "new", LinkDisplay: "Register a Well", queryParams: { parcelID: params.data.ParcelID } };
            }, cellRendererFramework: LinkRendererComponent,
            cellRendererParams: { inRouterLink: '/well-registry/', cssClasses: "btn btn-primary btn-sm" },
            width: 150, sortable: false, filter: false
          },
          { headerName: 'APN', field: 'ParcelNumber' },
          this.utilityFunctionsService.createDecimalColumnDef('Area (Acres)', 'ParcelAreaInAcres', 120, 2),
          { headerName: 'Landowner', field: 'OwnerName' },
          { headerName: 'In Care Of', field: 'InCareOfName' },
          { 
            headerName: 'Claimed By',
            valueGetter: params => {
              const links = params.data.Users.map(x => {
                return { LinkValue: x.UserID, LinkDisplay: x.FullName }
              });

              return { links: links, downloadDisplay: params.data.Users.map(x => x.FullName).join(', ') };
            },
            filterValueGetter: params => params.data.Users.map(x => x.FullName).join(', '),
            comparator: this.utilityFunctionsService.multiLinkRendererComparator,
            cellRendererFramework: MultiLinkRendererComponent,
            cellRendererParams: { inRouterLink: '/manage/users/'},
            width: 250
          },
          {
            headerName: 'Well on Parcel', 
            valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.HasWell),
            filterFramework: CustomDropdownFilterComponent, filterParams: {}
          },
          { 
            headerName: 'Water Sources', field: 'ParcelWaterSourceType.ParcelWaterSourceTypeDisplayName', 
            filterFramework: CustomDropdownFilterComponent, 
            filterParams: { field: 'ParcelWaterSourceType.ParcelWaterSourceTypeDisplayName' }
          },
          { 
            headerName: 'Owner Address', 
            valueGetter: params => this.utilityFunctionsService.getAddressFromParts(params.data.OwnerStreetAddress, params.data.OwnerCity, params.data.OwnerState, params.data.OwnerZip)
          },
          { 
            headerName: 'Mailing Address', 
            valueGetter: params => this.utilityFunctionsService.getAddressFromParts(params.data.MailAddress, params.data.MailCity, params.data.MailState, params.data.MailZip)

          },
          { headerName: 'Assessment Number', field: 'AssessmentNumber' },
          { headerName: 'Assessment Name', field: 'AssessmentName' },
          { headerName: 'Assessment Description', field: 'AssessmentDescription' },
          {
            headerName: 'Use Code', field: 'UseCode',
            filterFramework: CustomDropdownFilterComponent,
            filterParams: { field: 'UseCode' }
          },
          { 
            headerName: 'UseName', field: 'UseName',
            filterFramework: CustomDropdownFilterComponent,
            filterParams: { field: 'UseName' }
          },
          { headerName: 'TRA', field: 'TRA' },
          { headerName: 'Taxability', field: 'Taxability' },
          { headerName: 'Commun', field: 'Commun' },
          { headerName: 'Assessor Map Link', field: 'AssessorMapLink' },
          { headerName: 'Assessor Notes', field: 'AssessorNotes' }
        ];

        this.defaultColDef = { sortable: true, filter: true, resizable: true }
    }

    public onSelectionChanged(event) {
        let selection = this.parcelGrid.api.getSelectedRows()[0];
        if (selection && selection.ParcelID) {
          this.highlightedParcelID = selection.ParcelID;
        }
      }
    
    public selectHighlightedParcelIDRowNode() {
      this.parcelGrid.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
        if (rowNode.data.ParcelID == this.highlightedParcelID) {
          rowNode.setSelected(true);
          this.parcelGrid.api.ensureIndexVisible(index, 'top');
        }
      });
    }
}
