import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import * as L from 'leaflet';
import { LeafletHelperService } from 'src/app/shared/services/leaflet-helper.service';
import { environment } from 'src/environments/environment';
import GestureHandling from 'leaflet-gesture-handling';
import { WellContactSimpleDto } from 'src/app/shared/generated/model/well-contact-simple-dto';
import { WellContactTypeEnum } from 'src/app/shared/generated/enum/well-contact-type-enum';
import { FuelTypeEnum } from 'src/app/shared/generated/enum/fuel-type-enum';
import { FileResourceSimpleDto } from 'src/app/shared/generated/model/file-resource-simple-dto';
import { WellDetailDto } from 'src/app/shared/generated/model/well-detail-dto';

@Component({
  selector: 'well-detail',
  templateUrl: './well-detail.component.html',
  styleUrls: ['./well-detail.component.scss']
})
export class WellDetailComponent implements OnInit, AfterViewInit {
  
  private currentUser: UserDto;

  public well: WellDetailDto;
  public isFuelTypeOther = false;

  public mapID = 'wellDetailMap';
  public mapHeight = '400px';

  public map: L.Map;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};

  public parcelLayer: L.LayerGroup;
  private wellIcon = this.leafletHelperService.selectedWellIcon;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private leafletHelperService: LeafletHelperService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
    
      const wellID = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (wellID) {
        this.wellService.wellsWellIDDetailGet(wellID).subscribe(well => {
          this.well = well;

          if (well.WellMetadatum?.FuelTypeID == FuelTypeEnum.Other) {
            this.isFuelTypeOther = true;
          }

          this.addWellMarker();
          this.addParcelLayers();
          this.leafletHelperService.fitMapToBoundingBox(this.map, well.BoundingBox);

          this.cdr.detectChanges();
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.tileLayers = this.leafletHelperService.tileLayers;

    const mapOptions = this.leafletHelperService.defaultMapOptions;
    this.map = L.map(this.mapID, mapOptions);

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    this.layerControl = L.control.layers(this.tileLayers, this.overlayLayers).addTo(this.map);

    this.map.on('overlayadd', e => {
      if (this.map.hasLayer(this.parcelLayer)) {
        this.parcelLayer.bringToFront();
      }
    });
  }

  ngOnDestroy() {
    this.cdr.detach();

    this.map.off();
    this.map.remove();  
    this.map = null;
  }

  public getEstimatedOrMeasured(isEstimated: boolean): string {
    return isEstimated ? 'estimated' : 'measured';
  }

  public openFileResourceLink(fileResource: FileResourceSimpleDto) {
    window.open(`${environment.mainAppApiUrl}/fileResources/${fileResource.FileResourceGUID}`, '_blank');
  }

  private addWellMarker() {
    const wellMarker = new L.marker(
      [ this.well.Latitude, this.well.Longitude ],
      { icon: this.wellIcon, zIndexOffset: 1000, interactive: false }
    );

    wellMarker.addTo(this.map);
  }

  private addParcelLayers() {
    const parcelGeoJson = JSON.parse(this.well.Parcel.GeoJson);
    this.parcelLayer = L.geoJSON(parcelGeoJson, { style: { color: "#FFFF85" }, interactive: false, zIndex: 1000 });

    this.layerControl.addOverlay(this.parcelLayer, 'Parcel');
    this.parcelLayer.addTo(this.map);
  
    var irrigatedParcelLayer = new L.LayerGroup();

    this.well.IrrigatedParcels.forEach(x => {
      const geoJson = L.geoJSON(JSON.parse(x.GeoJson), { interactive: false, zIndex: 0 });
      geoJson.addTo(irrigatedParcelLayer);
    });

    this.layerControl.addOverlay(irrigatedParcelLayer, 'Irrigated Parcels');
    irrigatedParcelLayer.addTo(this.map);
  }

  getWellEditLink(){
    return "/well-registry/well/"+this.well.WellID+"/edit";
  }

  isUserAdmin(){
    return this.authenticationService.isCurrentUserAnAdministrator();
  }
}
