import { OnInit, Directive, Input, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: "[loadingSpinner]",
})
export class LoadingDirective implements OnInit {
    @Input() loadingSpinner: boolean;

    private loadingDiv: HTMLElement;

    constructor(
        private el: ElementRef, 
        private renderer: Renderer2) {
    }

    ngOnInit() : void {
        this.loadingDiv = this.renderer.createElement("div");
        this.renderer.addClass(this.loadingDiv, 'spinner-container')

        const spinnerIcon = this.renderer.createElement("i");
        this.renderer.addClass(spinnerIcon, 'fas');
        this.renderer.addClass(spinnerIcon, 'fa-spinner');
        this.renderer.addClass(spinnerIcon, 'loading-spinner');

        this.renderer.appendChild(this.loadingDiv, spinnerIcon);
        this.renderer.appendChild(this.el.nativeElement, this.loadingDiv);
    }

    ngOnChanges(changes : any) {
        if (!this.loadingDiv || changes.loadingSpinner.currentValue == changes.loadingSpinner.previousValue) return;

        if (changes.loadingSpinner.currentValue == false) {
            this.loadingDiv.classList.add('hidden');
        } else {
            this.loadingDiv.classList.remove('hidden');
        }
    } 
}
