<div class="list-view" *ngIf="currentUser$ | async as currentUser">
    <div class="page-header flex-between">
        <h2 class="page-title">Profile</h2>
        <fresca-button (onClick)="changePassword(currentUser)" class="mb-3" cssClass=" btn btn-secondary" iconClass=""><i class="fas fa-key"></i> Change Password</fresca-button>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>
        <ng-container *ngIf="user$ | async as user">
            <div class="grid-12">
                <div class="card g-col-6">
                    <div class="card-header flex-between">
                        <h3 class="card-title">Contact Information</h3>
                        <ng-container *ngIf="canUpdateUserProfile(currentUser, user) && !isEditingUserProfile">
                            <div class="card-actions">
                                <button type="button" class="btn btn-secondary" (click)="isEditingUserProfile = !isEditingUserProfile"><span class="fas fa-edit"></span> Edit</button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-body">
                        <ng-container *ngIf="isEditingUserProfile; else userProfileDetails">
                            <user-profile-edit [user]="user" (onEditClose)="editClosed($event)"></user-profile-edit>
                        </ng-container>
                        <ng-template #userProfileDetails>
                            <user-profile [user]="user"> </user-profile>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
