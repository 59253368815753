<div class="list-view">
    <div class="page-header">
        <div class="claim-parcels flex-between mb-5">
            <div class="info">
                <div class="headline pb-2">
                    <h3>Need to claim more parcels?</h3>
                </div>

                <div class="copy copy-2">
                    <p>Have another verification key to enter? Head over to our onboarding workflow to input it.</p>
                </div>
            </div>

            <div class="cta">
                <a routerLink="/onboard/verification-keys" class="btn btn-secondary-outline btn-rounded btn-small">Claim Parcels</a>
            </div>
        </div>

        <div class="flex-between">
            <h2 class="page-title">Wells</h2>

            <a routerLink="/well-registry/new" class="btn btn-primary btn-small btn-rounded mb-3">
                + Register a Well
            </a>
        </div>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div [id]="mapID" class="location-card"></div>

        <div class="flex-between mt-5 mb-2">
            <h2 class="module-title">Well Registrations <span *ngIf="wells">({{ wells.length }})</span></h2>
            <div class="filter-btn-group">
                <div class="clear-btn">
                    <fairyshrimp-clear-grid-filters-button [grid]="wellGrid"></fairyshrimp-clear-grid-filters-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #wellGrid style="width: 100%; height: 300px;" class="ag-theme-balham" [getRowNodeId]="getRowId"  (gridReady)="onGridReady($event)"
                [rowData]="wells" [columnDefs]="wellColumnDefs" [defaultColDef]="defaultColDef"
                rowSelection="single" (selectionChanged)="onSelectionChanged()">
            </ag-grid-angular>
        </div>

        <hr class="section-spacer" />

        <div class="flex-between mt-5 mb-2">
            <h2 class="module-title">Claimed Parcels <span *ngIf="claimedParcels">({{ claimedParcels.length }})</span></h2>
            <div class="filter-btn-group">
                <div class="clear-btn">
                    <fairyshrimp-clear-grid-filters-button [grid]="claimedParcelGrid"></fairyshrimp-clear-grid-filters-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #claimedParcelGrid style="width: 100%; height: 300px;" class="ag-theme-balham" 
                [rowData]="claimedParcels" [columnDefs]="claimedParcelColumnDefs" [defaultColDef]="defaultColDef">
            </ag-grid-angular>
        </div>
    </div>
</div>