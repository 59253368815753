import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';
import { ModalService } from '../services/modal/modal.service';
import { MissingRequiredFieldsService } from 'src/app/services/missing-required-fields.service';

export interface IDeactivateComponent {
    canExit: () => string[];
}

@Injectable({
  providedIn: 'root'
})
export class MissingRequiredFieldsOnboardingGuard implements CanDeactivate<IDeactivateComponent> {
  
  constructor(private missingRequiredFieldsService: MissingRequiredFieldsService) {}

  public canDeactivate(
      component: IDeactivateComponent
    ): Observable<boolean> | Promise<boolean> | boolean {
      var missingFields = component.canExit()
      if (missingFields.length > 0) {
        var message = `You are missing the following required fields:
          <ul>`;
        missingFields.forEach(missingField => {
          message+=`<li>${missingField}</li>`;
        });
        message +=`</ul>`

        return this.missingRequiredFieldsService.error({title: 'There are missing required fields.', message: message, buttonText:"Return"});
      }

  }
  
}
