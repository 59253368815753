<div class="page-header">
    <div class="flex-between mb-2">
        <h1 class="page-title">Custom Pages</h1>

        <a *ngIf="canCreate" class="btn btn-primary" routerLink="create">+ New Custom Page</a>
    </div>

    <div class="copy copy-2">
        <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
    </div>
</div>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <div class="filter-btn-group align-right mb-3">
        <fairyshrimp-clear-grid-filters-button [grid]="pageGrid">
        </fairyshrimp-clear-grid-filters-button>
    </div>

    <div class="table-responsive">
        <ag-grid-angular #pageGrid style="width: 100%; height: 720px;" class="ag-theme-balham" [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [suppressMenuHide]="true" [paginationPageSize]="100" (firstDataRendered)="onFirstDataRendered($event)">
        </ag-grid-angular>
    </div>
</div>