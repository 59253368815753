import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ParcelDisplayDto } from 'src/app/shared/generated/model/parcel-display-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'workflow-introduction',
  templateUrl: './workflow-introduction.component.html',
  styleUrls: ['./workflow-introduction.component.scss']
})
export class WorkflowIntroductionComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;

  public wellID: number;
  public selectedParcel: ParcelDisplayDto;
  public selectedParcelIDOnLoad: number;
  public parcels$: Observable<ParcelDisplayDto[]>;
  public parcelInputs$ = new Subject<string>();
  public searchLoading = false;

  public richTextTypeID = CustomRichTextTypeEnum.WellRegistryIntro;
  public isLoadingSubmit = false;
  
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private parcelService: ParcelService,
    private wellService: WellService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const wellID = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (wellID) {
        this.wellID = wellID;
        this.wellService.wellsWellIDParcelGet(wellID).subscribe(parcel => {
          this.selectedParcel = parcel;
          this.selectedParcelIDOnLoad = parcel.ParcelID;
        });
      } else {
        const parcelID = parseInt(this.route.snapshot.queryParamMap.get('parcelID'));
        if (parcelID) {
          this.parcelService.parcelsParcelIDGet(parcelID).subscribe(parcel => {
            this.selectedParcel = parcel;
          });
        }
      }

      this.parcels$ = this.parcelInputs$.pipe(
        filter(searchTerm => searchTerm != null),
        distinctUntilChanged(),
        tap(() => this.searchLoading = true),
        debounceTime(800),
        switchMap(searchTerm => this.parcelService.parcelsSearchSearchStringGet(searchTerm).pipe(
          catchError(() => of([])),
          tap(() => this.searchLoading = false)
        ))
      );

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public continue() {
    if (!this.wellID) {
      this.createWell();
    } else if (this.selectedParcel?.ParcelID != this.selectedParcelIDOnLoad) {
      this.updateWellParcel();
    } else {
      this.goToLocationStep();
    }
  }

  private createWell() {
    this.isLoadingSubmit = true;
    const parcelID = this.selectedParcel ? this.selectedParcel.ParcelID : -1;
    
    this.wellService.wellsPost(parcelID).subscribe(well => {
      this.isLoadingSubmit = false;
      this.wellID = well.WellID;
      this.goToLocationStep();
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  private updateWellParcel() {
    this.isLoadingSubmit = true;

    if (!this.selectedParcel) {
      this.removeWellParcel();
      return;
    }

    this.wellService.wellsWellIDParcelParcelIDPut(this.wellID, this.selectedParcel?.ParcelID).subscribe(x => {
      this.isLoadingSubmit = false;
      this.goToLocationStep();
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  private removeWellParcel() {
    this.wellService.wellsWellIDParcelDelete(this.wellID).subscribe(() => {
      this.isLoadingSubmit = false;
      this.goToLocationStep();
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  private goToLocationStep() {
    this.router.navigateByUrl(`/well-registry/well/${this.wellID}/edit/location`);
  }
}
