import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectionStrategy, ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef } from '@angular/core';
import { Feature, FeatureCollection, Polygon } from "geojson";
import { environment } from "src/environments/environment";
import * as L from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import '../../../../../node_modules/leaflet-loading/src/Control.Loading.js';
import { CustomCompileService } from '../../services/custom-compile.service';
import { BoundingBoxDto } from '../../generated/model/bounding-box-dto';
import { ParcelService } from '../../generated/api/parcel.service';
import { WfsService } from '../../services/wfs.service';
import { LeafletHelperService } from '../../services/leaflet-helper.service';


@Component({
    selector: 'parcel-map',
    templateUrl: './parcel-map.component.html',
    styleUrls: ['./parcel-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelMapComponent implements OnInit, AfterViewInit {
    @Input()
    public showOverlayLayers: boolean = true;
    @Input()
    public showBaseParcelLayer: boolean = false;
    @Input()
    public mapID: string = '';

    @Input()
    public visibleParcelStyle: string = 'parcel';

    @Input()
    public selectedParcelStyle: string = 'parcel_blue';

    private _selectedParcelIDs: Array<number> = [];

    @Input() set selectedParcelIDs(value: Array<number>) {
        this._selectedParcelIDs = value;
        if (this.map) {
            this.updateSelectedParcelsOverlayLayer(this.selectedParcelIDs);
        }
     }
     
    get selectedParcelIDs(): Array<number> {     
        return this._selectedParcelIDs;    
    }

    @Input()
    public highlightedParcelStyle: string = 'parcel_yellow';

    private _highlightedParcelID: number = null;

    @Input() set highlightedParcelID(value: number) {
        if (this.highlightedParcelID != value) {
            this._highlightedParcelID = value;
            this.highlightParcel();
        }
     }
     
    get highlightedParcelID(): number {     
        return this._highlightedParcelID;    
    }

    @Output()
    public highlightedParcelIDChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    public onEachFeatureCallback?: (feature, layer) => void;

    @Input()
    public zoomMapToDefaultExtent: boolean = true;

    @Input()
    public disableDefaultClick: boolean = false;

    @Input()
    public highlightParcelOnClick: boolean = false;

    @Input()
    public displayparcelsLayersOnLoad: boolean = true;

    @Input()
    public mapHeight: string = '300px';

    @Input()
    public defaultFitBoundsOptions?: L.FitBoundsOptions = null;

    @Input() 
    selectedWellLatLng?: L.latlng;

    @Output()
    public afterSetControl: EventEmitter<L.Control.Layers> = new EventEmitter();

    @Output()
    public afterLoadMap: EventEmitter<L.LeafletEvent> = new EventEmitter();

    @Output()
    public onMapMoveEnd: EventEmitter<L.LeafletEvent> = new EventEmitter();

    @Output()
    public onParcelSelect: EventEmitter<L.LeafletEvent> = new EventEmitter();

    
    public component: any;

    public map: L.Map;
    public featureLayer: any;
    public layerControl: L.Control.Layers;
    public tileLayers: { [key: string]: any } = {
        "Aerial": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Aerial', maxZoom: 22, maxNativeZoom: 18
        }),
        "Street": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Aerial', maxZoom: 22, maxNativeZoom: 18
        }),
        "Terrain": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Terrain', maxZoom: 22, maxNativeZoom: 18
        }),
    };
    public overlayLayers: { [key: string]: any } = {};
    boundingBox: BoundingBoxDto;
    private selectedParcelLayer: any;
    private highlightedParcelLayer: any;
    private baseParcelWMSOptions: L.WMSOptions = ({
        layers: "Fairyshrimp:Parcels",
        transparent: true,
        format: "image/png",
        tiled: true,
        maxZoom: 20
    } as L.WMSOptions);

    constructor(
        private wfsService: WfsService,
        private parcelService: ParcelService,
        private appRef: ApplicationRef,
        private compileService: CustomCompileService,
        private leafletHelperService: LeafletHelperService
    ) {
    }

    public ngOnInit(): void {
        this.compileService.configure(this.appRef);
    }

    private configureOverlayLayers(): void {
        // base parcels, helpful on the associated parcels workflow step
        if(this.showBaseParcelLayer) {
            let parcelLayersOptions = {...this.baseParcelWMSOptions};
            parcelLayersOptions.layer_name = `Parcels`
            this.overlayLayers = {...this.overlayLayers, [parcelLayersOptions.layer_name]: L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", parcelLayersOptions)}
        }

        // need three layers with cql filters
        if(this.showOverlayLayers) {
            let servedByGroundwaterOptions = {...this.baseParcelWMSOptions};
            servedByGroundwaterOptions.cql_filter = 'ServedByGroundwater = 1'
            servedByGroundwaterOptions.styles = 'parcel_groundwater_true'
            servedByGroundwaterOptions.layer_name = '<span class="icon-groundwater-true"></span> Served by Groundwater'

            let notServedByGroundwaterOptions = {...this.baseParcelWMSOptions};
            notServedByGroundwaterOptions.cql_filter = 'ServedByGroundwater = 0'
            notServedByGroundwaterOptions.styles = 'parcel_groundwater_false'
            notServedByGroundwaterOptions.layer_name = '<span class="icon-groundwater-false"></span> Not Served by Groundwater'

            let nullGroundwaterOptions = {...this.baseParcelWMSOptions};
            nullGroundwaterOptions.cql_filter = 'ServedByGroundwater is null'
            nullGroundwaterOptions.styles = 'parcel_groundwater_null'
            nullGroundwaterOptions.layer_name = '<span class="icon-groundwater-null"></span> No Response'
            
            this.overlayLayers = {
                ...this.overlayLayers, 
                [servedByGroundwaterOptions.layer_name]: L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", servedByGroundwaterOptions),
                [notServedByGroundwaterOptions.layer_name]: L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", notServedByGroundwaterOptions),
                [nullGroundwaterOptions.layer_name]: L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", nullGroundwaterOptions)
            }
        }
    }

    public updateSelectedParcelsOverlayLayer(parcelIDs: Array<number>) {
        if (this.selectedParcelLayer) {
            this.layerControl.removeLayer(this.selectedParcelLayer);
            this.map.removeLayer(this.selectedParcelLayer);
        }

        var wmsParameters = Object.assign({ styles: this.selectedParcelStyle, cql_filter: this.createParcelMapFilter(parcelIDs) }, this.baseParcelWMSOptions);
        this.selectedParcelLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", wmsParameters);

        this.selectedParcelLayer.addTo(this.map).bringToFront();
        if (this.highlightedParcelLayer) {
            this.highlightedParcelLayer.bringToFront();
        }
    }



    private fitBoundsToSelectedParcels(parcelIDs: Array<number>) {
        this.parcelService.parcelsBoundingBoxPost(parcelIDs).subscribe(boundingBox => {
            this.boundingBox = boundingBox;
            const bounds = L.latLngBounds([this.boundingBox.Bottom, this.boundingBox.Left], [this.boundingBox.Top, this.boundingBox.Right]);
        
            if (this.selectedWellLatLng && !bounds.contains(this.selectedWellLatLng)) {
                bounds.extend(this.selectedWellLatLng);
            }

            this.map.fitBounds([bounds], this.defaultFitBoundsOptions);
        });
    }

    private createParcelMapFilter(parcelIDs: Array<number>): any {
        return "ParcelID in (" + parcelIDs.join(',') + ")";
    }

    public ngAfterViewInit(): void {

        // Default bounding box
        this.boundingBox = new BoundingBoxDto();
        this.boundingBox.Left = environment.parcelBoundingBoxLeft;
        this.boundingBox.Bottom = environment.parcelBoundingBoxBottom;
        this.boundingBox.Right = environment.parcelBoundingBoxRight;
        this.boundingBox.Top = environment.parcelBoundingBoxTop;


        this.configureOverlayLayers();

        // create map options
        const mapOptions: L.MapOptions = {
            minZoom: 6,
            maxZoom: 20,
            layers: [
                this.tileLayers["Aerial"],
            ],
            fullscreenControl: true,
            gestureHandling: true
        } as L.MapOptions;

        // init the map
        this.map = L.map(this.mapID, mapOptions);

        // setup map events
        L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
        this.map.on('load', (event: L.LeafletEvent) => {
            this.afterLoadMap.emit(event);
        });
        this.map.on("moveend", (event: L.LeafletEvent) => {
            this.onMapMoveEnd.emit(event);
        });


        // fit bounds
        this.map.fitBounds([[this.boundingBox.Bottom, this.boundingBox.Left], [this.boundingBox.Top, this.boundingBox.Right]], this.defaultFitBoundsOptions);

        // add controls
        this.addControls();

        // filter by selected parcels
        if (this.selectedParcelIDs.length > 0) {
            this.updateSelectedParcelsOverlayLayer(this.selectedParcelIDs);
            this.fitBoundsToSelectedParcels(this.selectedParcelIDs);
        }

        // add marker if selected well
        if (this.selectedWellLatLng) {
            const wellMarker = new L.marker(
                this.selectedWellLatLng,
                { icon: this.leafletHelperService.selectedWellIcon, zIndexOffset: 1000, interactive: false }
            ).addTo(this.map);

            if (this.selectedParcelIDs.length == 0) {
                this.leafletHelperService.zoomToMarker(this.map, wellMarker);
            }
        }

        // enable click events if not disabled
        if (!this.disableDefaultClick) {
            const wfsService = this.wfsService;
            const self = this;
            this.map.on("click", (event: L.LeafletMouseEvent): void => {
                wfsService.getParcelByCoordinate(event.latlng.lng, event.latlng.lat)
                    .subscribe((parcelFeatureCollection: FeatureCollection) => {
                        parcelFeatureCollection.features
                            .forEach((feature: Feature) => {
                                // Flip the coordinates
                                switch (feature.geometry.type) {
                                    case "Polygon":
                                        const polygon: Polygon = feature.geometry as Polygon;
                                        polygon.coordinates = polygon.coordinates
                                            .map(coordinate => coordinate.map(point => [point[1], point[0]]));
                                        break;
                                }
                            });
                    });
            });
        }

        // enable wfs service
        const wfsService = this.wfsService;
        this.map.on("click", (event: L.LeafletMouseEvent): void => {
            this.map.fireEvent("dataloading");
            wfsService.getParcelByCoordinate(event.latlng.lng, event.latlng.lat).subscribe((parcelFeatureCollection: FeatureCollection) => {
                this.map.fireEvent("dataload");

                if (!parcelFeatureCollection.features || parcelFeatureCollection.features.length == 0) {
                    return;
                }

                let parcelID = parcelFeatureCollection.features[0].properties.ParcelID;

                if (this.highlightParcelOnClick) {
                    if (this.highlightedParcelID != parcelID) {
                        this.highlightedParcelID = parcelID;
                        this.highlightedParcelIDChange.emit(this.highlightedParcelID);
                    }
                } 
                
                this.onParcelSelect.emit(parcelFeatureCollection.features[0].properties);
            });
        });

        if(this.showOverlayLayers){
            // we have to async load the wells layer so just to be sure we can run that last
            this.wfsService.getWellsLayer().subscribe(response => {
                let layer = L.geoJson(response, {
                    pointToLayer: function (feature, latlng) {
                        return L.circleMarker(latlng, {});
                    },
                    style: function (feature) {
                        return {
                            stroke: true,
                            fillColor: '#7F3C8D',
                            color: "#fff",
                            radius: 5,
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 0.8
                        }
                    },
                    onEachFeature: (feature, layer) => {
                        var popupContent = this.popupStringForWell(feature.properties);
                        layer.bindPopup(popupContent);
                        
                    }
                }).addTo(this.map);

                this.layerControl.addOverlay(layer, '<span class="icon-wells"></span> Wells')
            });
        }
        
    }

    public highlightParcel() {
        if (this.highlightedParcelLayer) {
            this.map.removeLayer(this.highlightedParcelLayer);
            this.highlightedParcelLayer = null;
        }
        if (this.highlightedParcelID) {
            let wmsParameters = Object.assign({ styles: this.highlightedParcelStyle, cql_filter: `ParcelID = ${this.highlightedParcelID}` }, this.baseParcelWMSOptions);
            this.highlightedParcelLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", wmsParameters);
            this.highlightedParcelLayer.addTo(this.map).bringToFront();
            this.fitBoundsToSelectedParcels([this.highlightedParcelID]);
        }
    }

    public addControls(): void {
        // add loading control
        var loadingControl = L.Control.loading({
            separate: true,
            position: 'bottomleft'
        });
        this.map.addControl(loadingControl);

        // add layer control
        this.layerControl = new L.Control.Layers(this.tileLayers, this.overlayLayers)
            .addTo(this.map);

        if (this.displayparcelsLayersOnLoad) {  
            Object.keys(this.overlayLayers).forEach(layerName => {
                this.overlayLayers[layerName].addTo(this.map);
            });
        }

        this.afterSetControl.emit(this.layerControl);
    }

    public popupStringForWell(properties: any) {
        return `
        <ul>
            <li>
                Well Name: 
                <a href="/manage/wells/${properties.WellID.toString()}">
                    ${properties.WellName}
                </a>
            </li>
            <li>
                Well Registration ID: ${properties.WellID.toString()} 
            </li>
            <li>
                User:
                <a href="/manage/users/${properties.UserID}">
                    ${properties.FirstName} ${properties.LastName}
                </a>
            </li>
            ${properties.LocatedOnParcel ? `
                <li>
                    Located on: ${properties.LocatedOnParcel} 
                </li>` 
            : ''}
            ${properties.IrrigatedParcels ? `
                <li>
                    Irrigates: ${properties.IrrigatedParcels} 
                </li>` 
            : ''}
        </ul>
        `;
    }
}

