import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin, Observable } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ImpersonationService } from 'src/app/shared/generated/api/impersonation.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { FlagEnum } from 'src/app/shared/generated/enum/flag-enum';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { ParcelDisplayDto } from 'src/app/shared/generated/model/parcel-display-dto';
import { routeParams } from 'src/app/app-routing.module';
import { tap } from 'rxjs/operators';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'template-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent implements OnInit, OnDestroy {

    public user$: Observable<UserDto>;
    public userParcels$: Observable<ParcelDisplayDto[]>;
    public currentUser$ : Observable<UserDto>;
    public isEditingUserProfile: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private impersonationService: ImpersonationService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
        private confirmService: ConfirmService
    ) {
        // force route reload whenever params change;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.setupObservables();
    }

    setupObservables() : void {
        this.currentUser$ = this.authenticationService.getCurrentUser();
        const id = parseInt(this.route.snapshot.paramMap.get(routeParams.userID));
        this.user$ = this.userService.usersUserIDGet(id);
        this.userParcels$ = this.userService.usersUserIDParcelsGet(id);
    }

    impersonateUser(userToImpersonate: UserDto) {
        this.impersonationService.impersonateUserIDPost(userToImpersonate.UserID).subscribe(response => {
            this.authenticationService.refreshUserInfo(response);
            this.cdr.detectChanges();
            this.router.navigateByUrl("/").then(x => {
                this.alertService.pushAlert(new Alert(`Successfully impersonating user: ${response.FullName}`, AlertContext.Success));
            });
        })
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    canUpdateUser(userToUpdate: UserDto, currentUser: UserDto): boolean {
        return userToUpdate.UserID == currentUser.UserID || this.authenticationService.hasPermission(currentUser, PermissionEnum.UserRights, RightsEnum.Update);
    }

    canImpersonate(userToImpersonate: UserDto, currentUser: UserDto): boolean {
        return !environment.production && userToImpersonate.UserID !=  currentUser.UserID && this.authenticationService.hasFlag(currentUser, FlagEnum.CanImpersonateUsers);
    }

    editClosed(event: any) {
        this.isEditingUserProfile = false;
        this.setupObservables();
    }

    resetPassword(user: UserDto) : void {

        this.confirmService.confirm({title: "Password Reset Email", message: `Are you sure you want to send a password reset email to "${user.Email}"?`, buttonTextYes: "Yes", buttonClassYes: "btn-primary", buttonTextNo: "Cancel"}).then(confirmed => {
            if(confirmed){
                this.userService.usersUserIDResetPasswordPost(user.UserID).subscribe(response => {
                    this.alertService.pushAlert(new Alert(`Successfully sent email to "${user.Email}"`, AlertContext.Success, true))
                })
            }
        })

        
    }
}
