import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FuelTypeService } from 'src/app/shared/generated/api/fuel-type.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { FuelTypeEnum } from 'src/app/shared/generated/enum/fuel-type-enum';
import { FuelTypeDto } from 'src/app/shared/generated/model/fuel-type-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellSupportingInfoDto } from 'src/app/shared/generated/model/well-supporting-info-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';
import { ProgressService } from 'src/app/shared/services/progress.service';

@Component({
  selector: 'supporting-well-info',
  templateUrl: './supporting-well-info.component.html',
  styleUrls: ['./supporting-well-info.component.scss']
})
export class SupportingWellInfoComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;
  public wellID: number;
  public model: WellSupportingInfoDto;
  public fuelTypes: FuelTypeDto[];
  public selectedIndex: number;
  public selectedMaxEstimated: boolean;

  public customRichTextTypeID = CustomRichTextTypeEnum.WellRegistrySupportingInfo;
  public requiredFieldsCustomRichTextTypeID = CustomRichTextTypeEnum.WellSupportingInfoRequiredFields;
  public isLoadingSubmit: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private progressService: ProgressService,
    private fuelTypeService: FuelTypeService,
    private formValidationService: FormValidationService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      
      const id = this.route.snapshot.paramMap.get(routeParams.wellID);
      if (id) {
        this.wellID = parseInt(id);
        forkJoin ({
          wellSupportingInfo: this.wellService.wellsWellIDSupportingInfoGet(this.wellID),
          fuelTypes: this.fuelTypeService.fuelTypesGet()
        }).subscribe(({wellSupportingInfo, fuelTypes}) => {
          this.model = wellSupportingInfo;
          this.model.PumpTestDatePerformed = this.getDate(this.model.PumpTestDatePerformed);
          this.formValidationService.createValidationModel(this.model);

          this.fuelTypes = fuelTypes;
          this.selectedIndex = this.model.FuelTypeID-1;
        });
      }
      this.cdr.detectChanges();
    });
  }

  checkFuelTypeOther(fuelTypeID: number){
    return this.model.FuelTypeID == FuelTypeEnum.Other && fuelTypeID == FuelTypeEnum.Other;
  }

  isValid(field: string): boolean {
    return this.formValidationService.isFieldValid(field);
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.formValidationService.clearValidationModel();
  }

  getDate(date: string){
    if (date != null && date != "") {
      return date.substring(0, 10);
    }
    return null;
  }

  public updateWellSupportingInfo(continueToNextStep?: boolean) {
    this.isLoadingSubmit = true;
    this.formValidationService.clearErrors();

    this.wellService.wellsWellIDSupportingInfoPost(this.wellID, this.model).subscribe(isSupportingInfoStepComplete => {
      this.isLoadingSubmit = false;
      this.progressService.pushSupportingInfoStepProgress(isSupportingInfoStepComplete);
      
      if (continueToNextStep) {
        this.router.navigateByUrl(`well-registry/well/${this.wellID}/edit/attachments`);
      } else {
        this.alertService.pushAlert(new Alert("Supporting information successfully updated.", AlertContext.Success));
        window.scroll(0, 0);
      }

    }, error => {
      this.isLoadingSubmit = false;
      this.formValidationService.pushErrorsFromHttpResponse(error);
    });
  }
}
