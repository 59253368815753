//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  PlatformOverview = 1,
  Disclaimer = 2,
  Homepage = 3,
  Help = 4,
  LabelsAndDefinitionsList = 5,
  ManageUserParcels = 6,
  Training = 7,
  CustomPages = 8,
  MailMergeReport = 9,
  ParcelList = 10,
  OnboardAccount = 11,
  OnboardVerificationKeys = 12,
  OnboardClaimParcels = 13,
  WellRegistryIntro = 14,
  WellName = 15,
  StateWellNumber = 16,
  StateWCRNumber = 17,
  CountyWellPermit = 18,
  DateDrilled = 19,
  AgriculturalWaterUse = 20,
  StockWatering = 21,
  DomesticWaterUse = 22,
  PublicMunicipalWaterUse = 23,
  PrivateMunicipalWaterUse = 24,
  TopOfPerforations = 25,
  BottomOfPerforations = 26,
  MaximumFlow = 27,
  TypicalPumpFlow = 28,
  WellRegistryLocation = 29,
  WellRegistryContacts = 30,
  WellRegistryBasics = 31,
  WellRegistrySupportingInfo = 32,
  WellRegistryAttachments = 33,
  WellRegistrySubmit = 34,
  SelectWellAPN = 35,
  Footer = 36,
  ConfirmWellLocation = 37,
  SelectIrrigatedParcels = 38,
  CannotFindWell = 39,
  WellContactsRequiredFields = 40,
  WellBasicssRequiredFields = 41,
  WellSupportingInfoRequiredFields = 42,
  CannotSubmitWellRegistry = 43
}
