import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { FlagEnum } from 'src/app/shared/generated/enum/flag-enum';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { WellStatusEnum } from 'src/app/shared/generated/enum/well-status-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellProgressDto } from 'src/app/shared/generated/model/well-progress-dto';
import { WellStatusDto } from 'src/app/shared/generated/model/well-status-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProgressService } from 'src/app/shared/services/progress.service';

@Component({
  selector: 'well-registry-workflow',
  templateUrl: './well-registry-workflow.component.html',
  styleUrls: ['./well-registry-workflow.component.scss']
})
export class WellRegistryWorkflowComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;

  public wellID: number;
  public wellProgress: WellProgressDto;
  public progressSubscription: Subscription;

  public isWorkflowComplete: boolean = false;
  public isCreating: boolean = true;
  public isReviewing: boolean = false;
  public isLoadingSubmit: boolean = false;
  public isApproved: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private wellService: WellService,
    public progressService: ProgressService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      const canReview = this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanReviewWells);

      const id = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (id) {
        this.wellID = id;
        this.isCreating = false;
  
        this.wellService.wellsWellIDStatusGet(this.wellID).subscribe(wellStatus => {
          this.isReviewing = wellStatus.WellStatusID == WellStatusEnum.Submitted;
          this.isApproved = wellStatus.WellStatusID == WellStatusEnum.Approved;
  
          if (this.isReviewing && !canReview) {
            this.router.navigateByUrl(this.authenticationService.getDashboardRedirectUrl());
          }
          else if (this.isApproved && !this.isCurrentUserAdmin()){
            this.router.navigateByUrl(this.authenticationService.getDashboardRedirectUrl());
          }
        });

        this.progressSubscription = this.progressService.requestWellProgress(this.wellID).subscribe(wellProgress => {
          this.wellProgress = wellProgress;
          this.isWorkflowComplete = wellProgress.IsLocationStepComplete && wellProgress.IsContactsStepComplete && 
            wellProgress.IsBasicsStepComplete && wellProgress.IsSupportingInfoStepComplete;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.progressSubscription?.unsubscribe();
  }

  public approveWell() {
    this.updateWellStatus(WellStatusEnum.Approved);
  }

  public returnWell(){
    this.updateWellStatus(WellStatusEnum.Returned);
  }

  private updateWellStatus(wellStatusID: WellStatusEnum) {
    this.isLoadingSubmit = true;

    const wellStatus = new WellStatusDto({
      WellStatusID: wellStatusID
    });

    this.wellService.wellsWellIDStatusPut(this.wellID, wellStatus).subscribe(() => {
      this.isLoadingSubmit = false;
      this.router.navigateByUrl("/manage").then(() => {
        this.alertService.pushAlert(new Alert(`Well successfully ${(wellStatusID == WellStatusEnum.Approved ? 'approved' : 'returned')}`, AlertContext.Success));
      });
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  private isCurrentUserAdmin(){
    return this.currentUser.Role.RoleID == RoleEnum.Admin;
  }
}
