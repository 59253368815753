import { Component, OnInit } from '@angular/core';
import { MissingRequiredFieldsOptions } from './missing-required-fields-options';
import { MissingRequiredFieldsState } from './missing-required-fields-state';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'missing-required-fields-modal',
  templateUrl: './missing-required-fields-modal.component.html',
  styleUrls: ['./missing-required-fields-modal.component.scss']
})
export class MissingRequiredFieldsModalComponent{

  options: MissingRequiredFieldsOptions;

    constructor(private state: MissingRequiredFieldsState, public sanitizer: DomSanitizer, private modalService: ModalService) {
        this.options = state.options;
    }
    
    no() {
        this.modalService.close(this.state.modal, false)
    }

}
