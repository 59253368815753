import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormValidationService } from '../../services/form-validation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'field-validation-area',
  templateUrl: './field-validation-area.component.html',
  styleUrls: ['./field-validation-area.component.scss']
})
export class FieldValidationAreaComponent implements OnInit, OnDestroy {
  @Input() field: string;

  private validationModelSubscription: Subscription;
  public errors: string[] = [];

  constructor(
    private formValidationService: FormValidationService
  ) { }
  
  ngOnInit(): void {
    this.validationModelSubscription = this.formValidationService.getValidationModel().subscribe(validationModel => {
      if (!validationModel) return;

      this.errors = validationModel[this.field];
    });
  }

  ngOnDestroy(): void {
    this.validationModelSubscription.unsubscribe();
  }
}
