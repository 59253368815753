import { Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridApi } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ConfirmService } from "src/app/services/confirm/confirm.service";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { WellService } from "src/app/shared/generated/api/well.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { WellSimpleDto } from "src/app/shared/generated/model/well-simple-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
    selector: "fairyshrimp-dashboard-manage",
    templateUrl: "./dashboard-manage.component.html",
    styleUrls: ["./dashboard-manage.component.scss"],
})
export class DashboardManageComponent implements OnInit {
    @ViewChild("submittedWellsGrid") submittedWellsGrid: AgGridAngular;

    private currentUser: UserDto;

    public submittedWells: WellSimpleDto[];
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;

    constructor(
      private authenticationService: AuthenticationService,
      private wellService: WellService,
      private utilityFunctionsService: UtilityFunctionsService,
      private alertService: AlertService,
      private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
      this.authenticationService.getCurrentUser().subscribe((currentUser) => {
          this.currentUser = currentUser;
          this.wellService.wellsSubmittedGet().subscribe(submittedWells => {
              this.submittedWells = submittedWells;
          });

          this.createColumnDefs();
      });
    }

    public isCurrentUserBeingImpersonated(): boolean {
      return this.authenticationService.isCurrentUserBeingImpersonated(
          this.currentUser
      );
    }

    public getUserName() {
      return this.currentUser ? this.currentUser.LoginName : null;
    }

    public createColumnDefs() {
      this.defaultColDef = { resizable: true, sortable: true, filter: true };
  
      this.columnDefs = [
        {
          valueGetter: (params: any) => {
            return { LinkValue: `${params.data.WellID}/edit`, LinkDisplay: "Review" };
          }, 
          cellRendererFramework: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/well-registry/well/', cssClasses: "btn btn-primary btn-sm" },
          width: 100, sortable: false, filter: false
        },
        {
          valueGetter: (params: any) => {
            return { LinkAction: () => this.deleteRegistration(params.data.WellID), LinkDisplay: "Delete" };
          }, cellRendererFramework: LinkRendererComponent,
          cellRendererParams: { cssClasses: "btn btn-danger-outline btn-sm" },
          width: 100, sortable: false, filter: false, pinned:'right'
        },
        { headerName: 'Well Name', field: "WellName", width: 200 },
        { headerName: 'APN', field: "ParcelNumber", width: 120 },
        this.utilityFunctionsService.createDateColumnDef("Date Submitted", "SubmitDate", "short", 140),
        { 
          headerName: "Created By",
          valueGetter: params => {
            return { LinkValue: params.data.CreateUser?.UserID, LinkDisplay: params.data.CreateUser?.FullName };
          },
          cellRendererFramework: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/manage/users/' },
          filterValueGetter: params => params.data.CreateUser?.FullName
        }
      ];
    }

    onGridReady(event: any) {
      this.gridApi = event.api;
    }
    private gridApi : GridApi;
  
    public getRowId = params => {
      return params.WellID.toString();
    }

    public deleteRegistration(wellID: number) {
      let wellGridIndex = this.submittedWells.findIndex(x => x.WellID == wellID);
      let well = this.submittedWells[wellGridIndex];

      this.confirmService.confirm({title: "Delete Well", message: `Are you sure you want to delete the well "${well.WellName}"?`, buttonTextYes: "Delete", buttonClassYes: "btn-danger", buttonTextNo: "Cancel"}).then(confirmed => {
        if (confirmed) {
          this.wellService.wellsWellIDDelete(wellID).subscribe(response => {
            // remove the deleted well
            this.alertService.pushAlert(new Alert(`Successfully deleted well "${well.WellName}".`, AlertContext.Success, true))
            this.gridApi.applyTransaction({remove: [{WellID:wellID}]})
          });
        }
      });
    }
}
