import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import {  Observable, of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, filter } from 'rxjs/operators';
import { ParcelService } from '../../generated/api/parcel.service';
import { ParcelDisplayDto } from '../../generated/model/parcel-display-dto';
import { latlng, LeafletEvent } from 'leaflet';

@Component({
  selector: 'associated-parcels-edit',
  templateUrl: './associated-parcels-edit.component.html',
  styleUrls: ['./associated-parcels-edit.component.scss']
})
export class AssociatedParcelsEditComponent implements OnInit {
  @Input() parcels: ParcelDisplayDto[];
  @Input() parcelTypeDisplayName = "Associated Parcels";
  @Input() allowMapSelection = false;
  @Input() selectedWellLatLng?: latlng;
  @Input() showOverlayLayers: boolean = true;

  public selectedParcels: ParcelDisplayDto[] = [];
  public isAssociatedParcel: {[ParcelID: number]: boolean};
  public recentlyAddedCount = 0;

  // typeahead variables
  public parcelSearchModel: ParcelDisplayDto[];
  public parcels$: Observable<ParcelDisplayDto[]>;
  public parcelInputs$ = new Subject<string>();
  public searchLoading = false;

  // map variables
  public highlightedParcelID: number;
  public associatedParcelIDs: number[] = [];
  public isLoadingMap = true;

  constructor(
    private parcelService: ParcelService
  ) { }

  ngOnInit(): void {
    this.isAssociatedParcel = {};
    this.parcels.forEach(parcel => {
      this.isAssociatedParcel[parcel.ParcelID] = true;

      if (this.allowMapSelection) {
        this.associatedParcelIDs.push(parcel.ParcelID);
      }
    });
    
    this.isLoadingMap = false;

    this.parcels$ = this.parcelInputs$.pipe(
      filter(searchTerm => searchTerm != null),
      distinctUntilChanged(),
      tap(() => this.searchLoading = true),
      debounceTime(800),
      switchMap(searchTerm => this.parcelService.parcelsSearchSearchStringGet(searchTerm).pipe(
        catchError(() => of([])),
        tap(() => this.searchLoading = false)
      ))
    );
  }

  public onSelected(selectedParcel: ParcelDisplayDto) {
    // if parcel has already been selected, bump it to the top of the list
    const index = this.selectedParcels.findIndex(x => x.ParcelID == selectedParcel.ParcelID);
    if (index > -1) {
      this.selectedParcels.splice(index, 1);
    }

    this.selectedParcels.splice(0, 0, selectedParcel);

    // prevent default select behavior, since typeahead is serving as a search bar
    this.parcelSearchModel = [];
  }

  public addAssociatedParcel(parcel: ParcelDisplayDto, selectedParcelIndex: number) {
    if (selectedParcelIndex > -1) {
      this.selectedParcels.splice(selectedParcelIndex, 1);
    }

    this.parcels.splice(0, 0, parcel);
    this.isAssociatedParcel[parcel.ParcelID] = true;

    if (this.allowMapSelection) {
      this.associatedParcelIDs.push(parcel.ParcelID);
      this.updateSelectedParcels();
    }

    this.recentlyAddedCount++;
  }

  public removeAssociatedParcel(parcel: ParcelDisplayDto, associatedParcelIndex: number, associatedParcelIDsIndex?: number) {
    this.parcels.splice(associatedParcelIndex, 1);
    this.selectedParcels.splice(0, 0, parcel);
    this.isAssociatedParcel[parcel.ParcelID] = false;

    if (associatedParcelIndex < this.recentlyAddedCount) {
      this.recentlyAddedCount--;
    }

    if (associatedParcelIDsIndex == null) {
      associatedParcelIDsIndex = this.associatedParcelIDs.findIndex(x => x == parcel.ParcelID)
    }
    
    if (this.allowMapSelection) {
      this.associatedParcelIDs.splice(associatedParcelIDsIndex, 1);
      this.updateSelectedParcels();
    }
  }

  public onParcelMapSelectionChanged(e: LeafletEvent) {
    if (!this.allowMapSelection) return;
    
    const associatedParcelIDsIndex = this.associatedParcelIDs.findIndex(x => x == e.ParcelID)
    const parcel = new ParcelDisplayDto({ParcelID: e.ParcelID, ParcelNumber: e.ParcelNumber});

    if (associatedParcelIDsIndex > -1) {
      const associatedParcelIndex = this.parcels.findIndex(x => x.ParcelID == e.ParcelID);
      this.removeAssociatedParcel(parcel, associatedParcelIndex, associatedParcelIDsIndex);
    } else {
      const selectedParcelIndex = this.selectedParcels.findIndex(x => x.ParcelID == e.ParcelID);
      this.addAssociatedParcel(parcel, selectedParcelIndex);
    }
  }

  public highlightParcel(parcelID: number) {
    if (this.allowMapSelection) return;

    this.highlightedParcelID = parcelID;
  }

  public updateSelectedParcels() {
    this.associatedParcelIDs = [...this.associatedParcelIDs];
  }
}
