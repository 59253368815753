<div class="page-header">
    <h1 class="page-title">Claim Parcels</h1>
</div>

<div class="page-body grid-12">
    <div class="g-col-8">
        <ng-container *ngIf="!isAuthenticated() else signedInUser">
            <fresca-button (onClick)="clickedLogin($event)"><i class="fa fa-user"></i> Login or Sign Up</fresca-button>
        </ng-container>
        <ng-template #signedInUser>
            <div class="verify-parcels-list-item" *ngFor="let userParcel of userParcels;  let i = index">
                <div class="module-header pb-3">
                    <h2 class="module-title underline">Parcel {{i + 1}} (of {{userParcels.length}})</h2>
                </div>

                <div class="parcel-detail">
                    <div class="map-container">
                        <parcel-minimap class="mr-4 mb-4" [geoJson]="userParcel.Parcel.GeoJson" height="100%" width="100%"></parcel-minimap>
                    </div>

                    <table class="parcel-metadata-table copy copy-3">
                        <tbody>
                            <tr>
                                <td><strong>APN</strong></td>
                                <td>{{userParcel.Parcel.ParcelNumber}}</td>
                            </tr>
                            <tr>
                                <td><strong>Acres</strong></td>
                                <td>{{userParcel.Parcel.ParcelAreaInAcres}}</td>
                            </tr>
                            <tr>
                                <td><strong>Assessment Description</strong></td>
                                <td>{{userParcel.Parcel.AssessmentDescription}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="claim-parcel-bar flex-between mb-3">
                    <div class="info">
                        <div class="copy copy-2">
                            <p>Should this parcel be asssociated with your user account?</p>
                        </div>
                        
                        <p class="tip">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            You should associate this parcel if you are the owner or authorized to act on their behalf.
                        </p>
                    </div>

                    <div class="btn-group">
                        <button class="btn btn-rounded mr-2" [class.btn-success-bordered]="!userParcel.IsClaimed" [class.btn-success]="userParcel.IsClaimed" (click)="updateIsClaimed(userParcel, true)">
                            <i class="fa fa-thumbs-up"></i> <span>YES</span>
                        </button>
                        <button class="btn btn-rounded" [class.btn-danger-bordered]="userParcel.IsClaimed || userParcel.IsClaimed == null" [class.btn-danger]="userParcel.IsClaimed != null && userParcel.IsClaimed === false" (click)="updateIsClaimed(userParcel, false)">
                            <i class="fa fa-thumbs-down"></i> <span>NO</span>
                        </button>
                    </div>
                </div>
                
                <ng-container *ngIf="userParcel.IsClaimed">
                    <div class="secondary-question flex-between">
                        <div class="copy-3 question">
                            <p>Does this parcel have a well?</p>
                        </div>

                        <div class="btn-group binary copy-3">
                            <button class="btn" [ngClass]="userParcel.Parcel.HasWell ? 'btn-primary' : 'btn-primary-outline'" (click)="updateHasWell(userParcel, true)">
                                Yes
                            </button>
                            <button class="btn" [ngClass]="userParcel.Parcel.HasWell || userParcel.Parcel.HasWell == null ? 'btn-primary-outline' : 'btn-primary'" (click)="updateHasWell(userParcel, false )">
                                No
                            </button>
                        </div>
                    </div>

                    <div class="secondary-question flex-between">
                        <div class="copy-3 question">
                            <p>What water sources does this parcel have access to?</p>
                        </div>

                        <div class="btn-group copy-3 multi">
                            <button *ngFor="let parcelWaterSourceType of parcelWaterSourceTypes" class="btn" (click)="updateParcelWaterSourceType(userParcel, parcelWaterSourceType)"
                                    [ngClass]="userParcel.Parcel.ParcelWaterSourceTypeID == parcelWaterSourceType.ParcelWaterSourceTypeID ? 'btn-primary' : 'btn-primary-outline'">
                                {{ parcelWaterSourceType.ParcelWaterSourceTypeDisplayName }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer">
    <fresca-button class="ml-auto" [routerLink]="['/dashboard']" [disabled]="!isAuthenticated()">Continue</fresca-button>
</div>