import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BoundingBoxDto } from '../generated/model/bounding-box-dto';
import * as L from 'leaflet';
import GestureHandling from 'leaflet-gesture-handling';

@Injectable({
  providedIn: 'root'
})
export class LeafletHelperService {

  constructor() { }

  public readonly tileLayers = {
    "Aerial": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Aerial', maxZoom: 22, maxNativeZoom: 18
    }),
    "Street": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Street', maxZoom: 22, maxNativeZoom: 18
    }),
    "Terrain": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Terrain', maxZoom: 22, maxNativeZoom: 18
    })
  }

  public readonly defaultMapOptions: L.MapOptions = {
    minZoom: 6,
    maxZoom: 20,
    layers: [
      this.tileLayers["Aerial"],
    ],
    gestureHandling: true
  } as L.MapOptions;

  public readonly defaultParcelWMSOptions: L.WMSOptions =  {
    layers: "Fairyshrimp:Parcels",
    styles: "parcel",
    transparent: true,
    format: "image/png",
    tiled: true,
    maxZoom: 20
  }

  public readonly defaultBoundingBox = new BoundingBoxDto({
    Left: environment.parcelBoundingBoxLeft,
    Bottom: environment.parcelBoundingBoxBottom,
    Right: environment.parcelBoundingBoxRight,
    Top: environment.parcelBoundingBoxTop
  });

  public readonly wellIcon = L.icon({
    iconUrl: '/assets/main/map-icons/blue-pin.png',
    shadowUrl: '/assets/main/map-icons/shadow-skew.png',
    iconSize: [22, 35], iconAnchor: [12, 34], shadowAnchor: [4, 26], popupAnchor: [1, -34], tooltipAnchor: [16, -28], shadowSize: [28, 28]
  });

  public readonly selectedWellIcon = L.icon({
    iconUrl: '/assets/main/map-icons/yellow-pin.png',
    shadowUrl: '/assets/main/map-icons/shadow-skew.png',
    iconSize: [28, 45], iconAnchor: [15, 45], shadowAnchor: [5, 34], popupAnchor: [1, -45], tooltipAnchor: [16, -28], shadowSize: [35, 35]
  });

  public fitMapToDefaultBoundingBox(map: L.map) {
    const defaultBoundingBox = this.defaultBoundingBox;
    this.fitMapToBoundingBox(map, defaultBoundingBox);
  }

  public fitMapToBoundingBox(map: L.map, boundingBox: BoundingBoxDto) {
    map.fitBounds([[boundingBox.Bottom, boundingBox.Left], [boundingBox.Top, boundingBox.Right]]);
  }

  public zoomToMarker(map: L.Map, marker: L.Marker, zoomLevel?: number) {
    map.setView(marker.getLatLng(), zoomLevel ?? 18);
  }
}
