import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';
import { UserListComponent } from './pages/user-list/user-list.component';
import { HomeIndexComponent } from './pages/home/home-index/home-index.component';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { HelpComponent } from './pages/help/help.component';
import { CreateUserCallbackComponent } from './pages/create-user-callback/create-user-callback.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { FieldDefinitionListComponent } from './pages/field-definition-list/field-definition-list.component';
import { FieldDefinitionEditComponent } from './pages/field-definition-edit/field-definition-edit.component';
import { CustomPageListComponent } from './pages/custom-page-list/custom-page-list.component';
import { CustomPageDetailComponent } from './pages/custom-page-detail/custom-page-detail.component';
import { CustomPageEditPropertiesComponent } from './pages/custom-page-edit-properties/custom-page-edit-properties.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes-guard';
import { DashboardManageComponent } from './pages/dashboards/dashboard-manage/dashboard-manage.component';
import { ParcelListComponent } from './pages/parcel-list/parcel-list.component';
import { VerificationKeyListComponent } from './pages/verification-key-list/verification-key-list.component';
import { DashboardOnboardComponent } from './pages/dashboards/dashboard-onboard/dashboard-onboard.component';
import { OnboardAccountComponent } from './pages/dashboards/dashboard-onboard/onboard-account/onboard-account.component';
import { OnboardVerificationKeysComponent } from './pages/dashboards/dashboard-onboard/onboard-verification-keys/onboard-verification-keys.component';
import { OnboardParcelsComponent } from './pages/dashboards/dashboard-onboard/onboard-parcels/onboard-parcels.component';
import { UserParcelsEditComponent } from './pages/user-parcels-edit/user-parcels-edit.component';
import { WorkflowIntroductionComponent } from './pages/well-registry-workflow/workflow-introduction/workflow-introduction.component';
import { WellLocationComponent } from './pages/well-registry-workflow/well-location/well-location.component';
import { BasicWellInfoComponent } from './pages/well-registry-workflow/basic-well-info/basic-well-info.component';
import { SupportingWellInfoComponent } from './pages/well-registry-workflow/supporting-well-info/supporting-well-info.component';
import { WellAttachmentsComponent } from './pages/well-registry-workflow/well-attachments/well-attachments.component';
import { SubmitComponent } from './pages/well-registry-workflow/submit/submit.component';
import { WellContactsComponent } from './pages/well-registry-workflow/well-contacts/well-contacts.component';
import { WellRegistryWorkflowComponent } from './pages/well-registry-workflow/well-registry-workflow.component';
import { WellListComponent } from './pages/well-list/well-list.component';
import { DashboardLandownerComponent } from './pages/dashboards/dashboard-landowner/dashboard-landowner.component';
import { ProfileComponent } from './pages/dashboards/dashboard-landowner/profile/profile.component';
import { WellsComponent } from './pages/dashboards/dashboard-landowner/wells/wells.component';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { IrrigatedParcelsComponent } from './pages/well-registry-workflow/well-location/irrigated-parcels/irrigated-parcels.component';
import { ConfirmLocationComponent } from './pages/well-registry-workflow/well-location/confirm-location/confirm-location.component';
import { ReferenceWellsComponent } from './pages/well-registry-workflow/well-location/reference-wells/reference-wells.component';
import { WellDetailComponent } from './pages/dashboards/dashboard-landowner/well-detail/well-detail.component';
import { CanManageGuard } from './shared/guards/manage-guard';
import { MissingRequiredFieldsOnboardingGuard } from './shared/guards/missing-required-fields-onboarding.guard';

export const routeParams = {
  userID: 'userID',
  fieldDefinitionID: 'fieldDefinitionID',
  parcelID: 'parcelID',
  wellID:'wellID',
  customPageVanityUrl: 'vanity-url',
}

const routes: Routes = [
 
  { path: `custom-pages/:${routeParams.customPageVanityUrl}`, component: CustomPageDetailComponent, canDeactivate: [UnsavedChangesGuard] },

  { path: "manage", component: DashboardManageComponent, canActivate: [MsalGuard, CanManageGuard], children: [
    { path: "parcels", component: ParcelListComponent },
    { path: "wells", component: WellListComponent },
    { path: `wells/:${routeParams.wellID}`, component: WellDetailComponent },
    { path: "users", component: UserListComponent },
    { path: `users/:${routeParams.userID}`, component: UserDetailComponent },
    { path: `users/:${routeParams.userID}/manage-parcels`, component: UserParcelsEditComponent},
    { path: "custom-pages", component: CustomPageListComponent },
    { path: "custom-pages/create", component: CustomPageEditPropertiesComponent, data: { create: true }, canDeactivate: [UnsavedChangesGuard] },
    { path: `custom-pages/edit-properties/:${routeParams.customPageVanityUrl}`, component: CustomPageEditPropertiesComponent, data: { create: false }, canDeactivate: [UnsavedChangesGuard] },
    { path: `labels-and-definitions/:${routeParams.fieldDefinitionID}`, component: FieldDefinitionEditComponent, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions", component: FieldDefinitionListComponent },
    { path: "verification-keys", component: VerificationKeyListComponent },
  ] },

  { path: "dashboard", component: DashboardLandownerComponent, children: [
    { path: "", redirectTo: "wells", pathMatch: 'full'  },
    { path: "wells", component: WellsComponent },
    { path: `wells/:${routeParams.wellID}`, component: WellDetailComponent },
    { path: "profile", component: ProfileComponent }
  ] },

  { path: "onboard", component: DashboardOnboardComponent, children: [
    { path: "", redirectTo: "account", pathMatch: 'full'  },
    { path: "account", component: OnboardAccountComponent, canDeactivate: [MissingRequiredFieldsOnboardingGuard]},
    { path: "verification-keys", component: OnboardVerificationKeysComponent, canActivate: [MsalGuard] },
    { path: "parcels", component: OnboardParcelsComponent, canActivate: [MsalGuard] },
  ] },

  { path: "well-registry", canActivate: [MsalGuard], children: [
    { path: "", redirectTo: "new", pathMatch: "full" },
    { path: "new", component: WellRegistryWorkflowComponent, children: [
      { path: "", redirectTo: "introduction", pathMatch: 'full'  },
      { path: "introduction", component: WorkflowIntroductionComponent },
    ]},
    { path: `well/:${routeParams.wellID}/edit`, component: WellRegistryWorkflowComponent, children: [
      { path: "", redirectTo: "introduction", pathMatch: 'full'  },
      { path: "introduction", component: WorkflowIntroductionComponent },
      { path: "location", children: [
        { path: "", component: ReferenceWellsComponent },
        { path: "confirm", component: ConfirmLocationComponent },
        { path: "plot", component: WellLocationComponent },
        { path: "irrigated-parcels", component: IrrigatedParcelsComponent },
      ] },
      { path: "contacts", component: WellContactsComponent },
      { path: "basic-information", component: BasicWellInfoComponent },
      { path: "supporting-information", component: SupportingWellInfoComponent },
      { path: "attachments", component: WellAttachmentsComponent },
      { path: "submit", component: SubmitComponent },
    ]}
  ]},

  { path: "style-guide", component: StyleGuideComponent },
  { path: "help", component: HelpComponent },
  { path: "create-user-callback", component: CreateUserCallbackComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: 'subscription-insufficient', component: SubscriptionInsufficientComponent },
  { path: 'unauthenticated', component: UnauthenticatedComponent },
  { path: "signin-oidc", component: LoginCallbackComponent },
  // { path: "disclaimer", component: DisclaimerComponent },
  // { path: "disclaimer/:forced", component: DisclaimerComponent },
  { path: "", component: HomeIndexComponent },
  { path: '**', component: NotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
