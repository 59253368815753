import { Component, OnInit, HostListener, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { CustomPageDto } from '../../generated/model/custom-page-dto';
import { CustomPageService } from '../../generated/api/custom-page.service';
import { UserDto } from '../../generated/model/user-dto';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RoleEnum } from '../../generated/enum/role-enum';

@Component({
    selector: 'header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss']
})

export class HeaderNavComponent implements OnInit, OnDestroy {
    @ViewChild('mobileMenuButton') mobileMenuButton: ElementRef;
    
    private watchUserChangeSubscription: any;
    currentUser: UserDto;

    public windowWidth: number;
    public learnMorePages: CustomPageDto[] = [];

    @HostListener('window:resize', ['$event'])
    resize() {
        this.windowWidth = window.innerWidth;
    }

    constructor(
        private authenticationService: AuthenticationService,
        private customPageService: CustomPageService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.watchUserChangeSubscription = this.authenticationService.currentUserSetObservable.subscribe(currentUser => {
            this.currentUser = currentUser;
            // if the user status changes we need to retrieve new pages
            this.getCustomPages();
        });

        this.getCustomPages();
    }

    public getCustomPages() {
        this.customPageService.customPagesWithRolesGet().subscribe(customPagesWithRoles => {
            this.learnMorePages = customPagesWithRoles.filter(x => x.MenuItem.MenuItemName == "LearnMore");
        });
    }

    ngOnDestroy() {
        this.watchUserChangeSubscription.unsubscribe();
        
        this.cdr.detach();
    }

    public isAuthenticated(): boolean {
        return this.authenticationService.isAuthenticated();
    }

    public isUnassignedOrDisabled(): boolean {
        return this.authenticationService.isUserUnassigned(this.currentUser)  
            || this.authenticationService.isUserRoleDisabled(this.currentUser);
    }

    public getUserName() {
        return this.currentUser ? this.currentUser.FullName : null;
    }

    public getLogoRouterLink(): string[] {
        return [this.authenticationService.getDashboardRedirectUrl()];

    }

    public login(): void {
        this.authenticationService.login();
    }

    public logout(): void {
        this.authenticationService.logout();

        setTimeout(() => {
            this.cdr.detectChanges();
        });
    }

    public showTestingWarning(): boolean {
        return environment.staging || environment.dev;
    }

    public testingWarningText(): string {
        return environment.staging ? "QA Site: Testing Environment!" : "Development Site: Testing Environment!";
    }
    
    public editProfile(): void {
        this.authenticationService.editProfile();
    }
	
    public isCurrentUserBeingImpersonated(): boolean {
        return this.authenticationService.isCurrentUserBeingImpersonated(this.currentUser);
    }

    public canViewParcels(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.WatershedRights, RightsEnum.Read);
    }

    public hasManageMenu(): boolean {
        // logic should be updated to utilize new permissions model
        return this.authenticationService.isCurrentUserAnAdministrator();
    }

    public hasDashboardLink(): boolean {
        // logic should be updated to utilize new permissions model
        return this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.Normal]);
    }

    public canViewUsers(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.UserRights, RightsEnum.Read);
    }

    public canViewFieldDefinitions(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Read);
    }

    public canViewCustomPages(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Read) || this.currentUser.Role.RoleID == RoleEnum.NoAccess;
    }

    public toggleMobileMenu() {
        this.mobileMenuButton?.nativeElement.click();
    }
}
