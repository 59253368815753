import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParcelWaterSourceTypeService } from 'src/app/shared/generated/api/parcel-water-source-type.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { UserParcelService } from 'src/app/shared/generated/api/user-parcel.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ParcelDto, ParcelWaterSourceTypeDto, UserDto, UserParcelSimpleDto } from 'src/app/shared/generated/model/models';

@Component({
  selector: 'onboard-parcels',
  templateUrl: './onboard-parcels.component.html',
  styleUrls: ['./onboard-parcels.component.scss']
})
export class OnboardParcelsComponent implements OnInit {

  public currentUser: UserDto;
  public customRichTextID: CustomRichTextTypeEnum = CustomRichTextTypeEnum.OnboardClaimParcels;
  public userParcels: UserParcelSimpleDto[];
  public parcelWaterSourceTypes: ParcelWaterSourceTypeDto[];
  
  constructor(
    private authenticationService: AuthenticationService,
    private parcelsService: ParcelService,
    private userParcelService: UserParcelService,
    private parcelWaterSourceTypeService: ParcelWaterSourceTypeService
    ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(user => {
      this.currentUser = user;

      forkJoin({
        userParcels: this.parcelsService.parcelsUserUserIDGet(user.UserID),
        parcelWaterSourceTypes: this.parcelWaterSourceTypeService.parcelWaterSourceTypesGet()
      }).subscribe(({userParcels, parcelWaterSourceTypes}) => {
        this.userParcels = userParcels;
        this.parcelWaterSourceTypes = parcelWaterSourceTypes;
      });
    });
  }

  ngOnDestroy(): void { }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public updateIsClaimed(userParcel: UserParcelSimpleDto, isClaimed: boolean) {
    userParcel.IsClaimed = isClaimed;
    this.updateUserParcel(userParcel);
  }

  public updateHasWell(userParcel: UserParcelSimpleDto, hasWell: boolean) {
    userParcel.Parcel.HasWell = hasWell;
    this.updateUserParcel(userParcel);
  }

  public updateParcelWaterSourceType(userParcel: UserParcelSimpleDto, parcelWaterSourceType: ParcelWaterSourceTypeDto) {
    userParcel.Parcel.ParcelWaterSourceTypeID = parcelWaterSourceType.ParcelWaterSourceTypeID;
    this.updateUserParcel(userParcel);
  }

  private canSubmitClaimedParcel(userParcel: UserParcelSimpleDto): boolean {
    return userParcel.Parcel.HasWell != null && userParcel.Parcel.ParcelWaterSourceTypeID != null;
  }

  updateUserParcel(userParcel: UserParcelSimpleDto) {
    if (userParcel.IsClaimed && !this.canSubmitClaimedParcel(userParcel)) return;

    this.userParcelService.userParcelUserParcelIDPut(userParcel.UserParcelID, userParcel).subscribe();
  }
}
