import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, CsvExportParams } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class UtilityFunctionsService {

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) { }

  public stringToKebabCase(string: string): string {
    return string.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
  }

  public booleanValueGetter(value: boolean, allowNullValues: boolean = true) {
    if (allowNullValues && value == null) return null;

    return value ? "Yes" : "No";
  }

  public getAddressFromParts(streetAddress: string, city: string, state: string, zip: string) {
    return (streetAddress ? streetAddress + ', ' : '') +
      (city ? city + ', ' : '') +
      (state ? state + ' ' : '') + 
      (zip ? zip : '')
  }

  public linkRendererComparator(id1: any, id2: any) {
    if (id1.LinkDisplay == id2.LinkDisplay) {
      return 0;
    }
    return id1.LinkDisplay > id2.LinkDisplay ? 1 : -1;
  }

  public multiLinkRendererComparator(id1: any, id2: any) {
    if (id1.downloadDisplay == id2.downloadDisplay) {
      return 0;
    }
    return id1.downloadDisplay > id2.downloadDisplay ? 1 : -1;
  }

  private nestedFieldValueGetter(params: any, fieldName): number {
    const fieldNames = fieldName.split('.');
    if (fieldNames.length == 1) {
      return params.data[fieldName];
    }

    // checks that each part of a nested field is not null
    var fieldValue = params.data;
    fieldNames.forEach(x => {
      fieldValue = fieldValue[x];
    });

    return fieldValue;
  }

  public createDecimalColumnDef(headerName: string, fieldName: string, width?: number, decimalPlacesToDisplay?: number) {
    const _decimalPipe = this.decimalPipe;
    const decimalFormatString = decimalPlacesToDisplay != null ? 
      '1.' + decimalPlacesToDisplay + '-' + decimalPlacesToDisplay : '1.2-2';
  
    var decimalColDef: ColDef = {
      headerName: headerName, filter: 'agNumberColumnFilter', cellStyle: { textAlign: 'right' },
      valueGetter: params => this.nestedFieldValueGetter(params, fieldName),
      valueFormatter: params => _decimalPipe.transform(params.value, decimalFormatString),
      filterValueGetter: params => parseFloat(_decimalPipe.transform(this.nestedFieldValueGetter(params, fieldName), decimalFormatString))
    }
    if (width) {
      decimalColDef.width = width
    }
  
    return decimalColDef;
  }

  private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
    const filterDate = Date.parse(filterLocalDateAtMidnight);
    const cellDate = Date.parse(cellValue);

    if (cellDate == filterDate) {
      return 0;
    }
    return (cellDate < filterDate) ? -1 : 1;
  }

  private dateSortComparator (id1: any, id2: any) {
    const date1 = id1 ? Date.parse(id1) : Date.parse("1/1/1900");
    const date2 = id2 ? Date.parse(id2) : Date.parse("1/1/1900");
    if (date1 < date2) {
      return -1;
    }
    return (date1 > date2)  ?  1 : 0;
  }

  private dateValueGetter(params, fieldName: string, dateFormat: string) {
    const _datePipe = this.datePipe;

    const value = this.nestedFieldValueGetter(params, fieldName);
    return _datePipe.transform(value, dateFormat);

  }

  public createDateColumnDef(headerName: string, fieldName: string, dateFormat: string, width?: number): ColDef {
    var dateColDef: ColDef = {
      headerName: headerName, 
      valueGetter: params => this.dateValueGetter(params, fieldName, dateFormat),
      comparator: this.dateSortComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        comparator: this.dateFilterComparator
      }, 
      width: 110,
      resizable: true,
      sortable: true
    };
    if (width) {
      dateColDef.width = width;
    }

    return dateColDef;
  }

  public exportGridToCsv(grid: AgGridAngular, fileName: string, columnKeys: Array<string>) {
    var params =
      {
        skipHeader: false,
        columnGroups: false,
        skipFooters: true,
        skipGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: true,
        onlySelected: false,
        suppressQuotes: false,
        fileName: fileName,
        processCellCallback: function (p) {
          if (p.column.getColDef().cellRendererFramework) {
            if (p.value.downloadDisplay) {
              return p.value.downloadDisplay;
            } else {
              return p.value.LinkDisplay;
            }
          }
          else {
            return p.value;
          }
        }
      } as CsvExportParams
    if (columnKeys) {
      params.columnKeys = columnKeys;
    }
    grid.api.exportDataAsCsv(params);
  }
}
