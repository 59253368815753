import { Injectable, ComponentRef } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { MissingRequiredFieldsOptions } from "./missing-required-fields-options";

@Injectable({
    providedIn: "root",
})
export class MissingRequiredFieldsState {
    /**
     * The last options passed ConfirmService.confirm()
     */
    options: MissingRequiredFieldsOptions;

    /**
     * The last opened confirmation modal
     */
    modal: ComponentRef<ModalComponent>;
}