import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VerificationKeyService } from 'src/app/shared/generated/api/verification-key.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ClaimVerificationKeyCodeDto } from 'src/app/shared/generated/model/claim-verification-key-code-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { VerificationKeySimpleDto } from 'src/app/shared/generated/model/verification-key-simple-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';

@Component({
  selector: 'onboard-verification-keys',
  templateUrl: './onboard-verification-keys.component.html',
  styleUrls: ['./onboard-verification-keys.component.scss']
})
export class OnboardVerificationKeysComponent implements OnInit {

  private currentUserSubscription : Subscription;
  public currentUser: UserDto;
  public customRichTextID: CustomRichTextTypeEnum = CustomRichTextTypeEnum.OnboardVerificationKeys;
  public claimedVerificationKeys: VerificationKeySimpleDto[];
  public model: ClaimVerificationKeyCodeDto = new ClaimVerificationKeyCodeDto();
  
  constructor(
    private authenticationService: AuthenticationService,
    private verificationKeyService: VerificationKeyService,
    private alertService: AlertService,
    private formValidationService: FormValidationService
  ) { }

  ngOnInit(): void {
    this.currentUserSubscription = this.authenticationService.currentUserSetObservable.subscribe(user => {
      this.currentUser = user;
      this.verificationKeyService.userVerificationKeysUserIdGet(user.UserID).subscribe(response => {
        this.claimedVerificationKeys = response;
      });

      this.formValidationService.createValidationModelFromFieldList(['VerificationKey']);
    });
    
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
    this.formValidationService.clearValidationModel();
  }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public hasClaimedVerificationKeys(): boolean {
    return this.claimedVerificationKeys?.length > 0;
  }

  onSubmit(form:NgForm): void {
    this.verificationKeyService.claimVerificationKeyCodePost(this.model).subscribe(response => {
      if(response){
        this.claimedVerificationKeys = response;
        this.alertService.clearAlerts();
        this.alertService.pushAlert(new Alert("Verification key successfully saved.", AlertContext.Success, true))
      }
      form.reset()
    }, error => {
      this.formValidationService.pushErrorsFromHttpResponse(error);
    })
  }

  deleteVerificationKey(verificationKey:VerificationKeySimpleDto): void{
    this.verificationKeyService.deleteUserVerificationKeyVerificationKeyIdDelete(verificationKey.VerificationKeyID).subscribe(response =>{
      this.claimedVerificationKeys = response;
      this.alertService.clearAlerts();
      this.alertService.pushAlert(new Alert("Removed verification key.", AlertContext.Success, true))
    })
  }
}
