import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Prism } from 'prismjs';

@Component({
  selector: 'style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    Prism.highlightAll();
  }

}
