<div class="dashboard">
    <aside class="sidebar">
        <div class="sidebar-header">
            <h5 class="sidebar-title">
                <a routerLink="/manage">
                    <img src="assets/main/logos/MIUGSA-logo.png" alt="MIUGSA Logo" />
                    Water Manager Dashboard
                </a>
            </h5>
        </div>

        <ul class="sidebar-nav">
            <li class="sidebar-item"><a [routerLink]="['/manage']" routerLinkActive="active" class="sidebar-link"><i class="fas fa-bell"></i>Dashboard</a></li>
            <li class="sidebar-item"><a [routerLink]="['wells']" routerLinkActive="active" class="sidebar-link"><i class="fas fa-tint"></i>Wells</a></li>
            <li class="sidebar-item"><a [routerLink]="['parcels']" routerLinkActive="active" class="sidebar-link"><i class="fas fa-map"></i>Parcels</a></li>
            <li class="sidebar-item"><a [routerLink]="['verification-keys']" routerLinkActive="active" class="sidebar-link"><i class="fas fa-key"></i>Verification Keys</a></li>
            <li class="sidebar-item"><a [routerLink]="['users']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-user"></i>Users</a></li>
            <li class="sidebar-item"><a [routerLink]="['labels-and-definitions']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-list-ul"></i>Labels & Definitions</a></li>
            <li class="sidebar-item"><a [routerLink]="['custom-pages']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-file-alt"></i>Custom Pages</a></li>
        </ul>
    </aside>

    <main class="main">
        <div [hidden]="!manageOutlet.isActivated">
            <router-outlet #manageOutlet="outlet"></router-outlet>
        </div>
        <div [hidden]="manageOutlet.isActivated">
            <div class="page-header">
                <h1 class="page-title">Water Manager Dashboard</h1>
            </div>

            <div class="page-body grid-12">
                <div class="g-col-9">
                    <app-alert-display></app-alert-display>

                    <div class="copy copy-2">
                        <p>{{isCurrentUserBeingImpersonated() ? 'Impersonating' : 'Welcome'}} {{getUserName()}}</p>
                    </div>

                    <div class="section-header mt-4">
                        <div class="flex-between mb-2">
                            <h3 class="module-title">Review Submitted Wells</h3>

                            <div class="filter-btn-group">
                                <div class="clear-btn mr-2">
                                    <fairyshrimp-clear-grid-filters-button [grid]="submittedWellsGrid"></fairyshrimp-clear-grid-filters-button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="table-responsive">
                        <ag-grid-angular #submittedWellsGrid style="width: 100%; height: 400px;" class="ag-theme-balham" [getRowNodeId]="getRowId"  (gridReady)="onGridReady($event)"
                        [rowData]="submittedWells" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef">
                        </ag-grid-angular>
                    </div>
                </div>

                <div class="sidebar-help g-col-3">
                    <div class="headline">
                        <h3><i class="fa fa-info-circle"></i> Quick Links</h3>
                    </div>

                    <div class="copy copy-3 pt-2">
                        <ul>
                            <li><a routerLink="/well-registry/new">Register a Well</a></li>
                            <li><a routerLink="/onboard/verification-keys">Claim Parcels</a></li>
                            <li><a routerLink="/">Homepage</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>