<div class="page-header">
    <h4 class="sidebar-title mb-3">Well Registry Workflow / Step 5</h4>
    <h1 class="page-title">Supporting Information</h1>
</div>

<div class="page-body grid-12">
    <form class="form mb-3 g-col-8" *ngIf="model">
        <app-alert-display></app-alert-display>

        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="requiredFieldsCustomRichTextTypeID"></custom-rich-text>
        </div>

        <div class="field grid-9">
            <div class="module-header">
                <h2 class="module-title underline">Well Details</h2>
            </div>

            <div class="field g-col-half">
                <label class="field-label required">Well Depth (FT.)</label>
                <input type="text" class="form-control" name="WellDepth" required [(ngModel)]="model.WellDepth" mask="0000000000"/>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Casing Diameter (IN.)</label>
                <input type="text" class="form-control" name="CasingDiameter" [(ngModel)]="model.CasingDiameter" mask="0000000000" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Top of Perforations</label>
                <input type="text" class="form-control" name="TopOfPerforations" [(ngModel)]="model.TopOfPerforations" mask="0000000000" />
                <field-definition fieldDefinitionType="TopOfPerforations"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Bottom of Perforations</label>
                <input type="text" class="form-control" name="BottomOfPerforations" [(ngModel)]="model.BottomOfPerforations" mask="0000000000" />
                <field-definition fieldDefinitionType="BottomOfPerforations"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class=" field-label">Serial No. of Water Meter</label>
                <input type="text" class="form-control" name="SerialNumberOfWaterMeter" [(ngModel)]="model.SerialNumberOfWaterMeter" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Manufacturer of Water Meter</label>
                <input type="text" class="form-control" name="ManufacturerOfWaterMeter" [(ngModel)]="model.ManufacturerOfWaterMeter" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Electric Meter Number</label>
                <input type="text" class="form-control" name="ElectricMeterNumber" [(ngModel)]="model.ElectricMeterNumber" />
            </div>


            <div class="module-header pt-5">
                <h2 class="module-title underline">Pump Details</h2>
            </div>
            <div class="field g-col-half">
                <label class="field-label required">Pump Discharge Diameter (in.)</label>
                <input type="number" class="form-control" name="PumpDischargeDiameter" required [(ngModel)]="model.PumpDischargeDiameter"/>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Motor/Engine (HP)</label>
                <input type="number" class="form-control" name="MotorHorsePower" [(ngModel)]="model.MotorHorsePower" />
            </div>
            <div class="field">
                <label class="field-label required">Fuel/Power</label>
                <div *ngFor="let f of fuelTypes let index = index" class="fuelTypeDisplay">
                    <input type="radio" class="form-control" [id]="f.FuelTypeID" [name]="f.FuelTypeID" [(ngModel)]="model.FuelTypeID" [value]="f.FuelTypeID" />
                    <label class="checkbox-label" [for]="f.FuelTypeID">{{f.FuelTypeDisplayName}}</label>
                    <div class="hideContainer mt-2" *ngIf="checkFuelTypeOther(f.FuelTypeID)">
                        <input type="text" class="form-control" name="FuelOther" [(ngModel)]="this.model.FuelOther" [class.input-invalid]="!isValid('FuelOther')" />
                        <field-validation-area field="FuelOther"></field-validation-area>
                    </div>
                </div>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Maximum Flow</label>
                <input type="text" class="form-control" name="MaximumFlow" [(ngModel)]="model.MaximumFlow" mask="0000000000" />
                <field-definition fieldDefinitionType="MaximumFlow"></field-definition>
            </div>
            <div class="field g-col-half estimated">
                <input type="radio" class="form-control" name="isEstimatedMax" id="maxMeasured" [(ngModel)]="model.isEstimatedMax" [value]="false" />
                <label class="checkbox-label checkbox-measured" for="maxMeasured">Measured</label>
                <input type="radio" class="form-control" name="isEstimatedMax" id="maxEstimated" [(ngModel)]="model.isEstimatedMax" [value]="true" />
                <label class="checkbox-label" for="maxEstimated">Estimated</label>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Typical Pump Flow</label>
                <input type="text" class="form-control" name="TypicalPumpFlow" [(ngModel)]="model.TypicalPumpFlow" mask="0000000000" />
                <field-definition fieldDefinitionType="TypicalPumpFlow"></field-definition>
            </div>
            <div class="field g-col-half estimated">
                <input type="radio" class="form-control" name="isEstimatedTypical" id="typicalMeasured" [(ngModel)]="model.isEstimatedTypical" [value]="false" />
                <label class="checkbox-label checkbox-measured" for="typicalMeasured">Measured</label>

                <input type="radio" class="form-control" name="isEstimatedTypical" id="typicalEstimated" [(ngModel)]="model.isEstimatedTypical" [value]="true" />
                <label class="checkbox-label" for="typicalEstimated">Estimated</label>
            </div>


            <div class="module-header pt-5">
                <h2 class="module-title underline">Pump Test (Most Recent)</h2>
            </div>
            <div class="field g-col-half">
                <label class="field-label">Conducted By</label>
                <input type="text" class="form-control" name="PumpTestBy" [(ngModel)]="model.PumpTestBy" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Performed Test Date</label>
                <input type="date" class="form-control" name="PumpTestDatePerformed" [(ngModel)]="model.PumpTestDatePerformed" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Pump Manufacturer</label>
                <input type="text" class="form-control" name="PumpManufacturer" [(ngModel)]="model.PumpManufacturer" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Yield (GPM)</label>
                <input type="text" class="form-control" name="PumpYield" [(ngModel)]="model.PumpYield" mask="0000000000" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Static Level (Ft.)</label>
                <input type="text" class="form-control" name="PumpStaticLevel" [(ngModel)]="model.PumpStaticLevel" mask="0000000000" />
            </div>
            <div class="field g-col-half">
                <label class="field-label">Pumping Level (Ft.)</label>
                <input type="text" class="form-control" name="PumpingLevel" [(ngModel)]="model.PumpingLevel" mask="0000000000" />
            </div>
        </div>
    </form>
    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer flex-end">
    <fresca-button class="mr-2" (onClick)="updateWellSupportingInfo()" [disabled]="isLoadingSubmit" cssClass="btn btn-primary-outline" iconClass="">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Save
    </fresca-button>

    <fresca-button (onClick)="updateWellSupportingInfo(true)" [disabled]="isLoadingSubmit">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Save & Continue
    </fresca-button>
</div>