<div class="contact-card">
    <div class="contact-card__info">
        <h4 class="contact-card__name">{{ user?.FirstName }} {{ user?.LastName }}</h4>

        <div class="contact-card__business" *ngIf="user.BusinessName">
            <p>{{ user?.BusinessName }}</p>
        </div>

        <div class="contact-card__address" *ngIf="user.StreetAddress || user.City || user.State || user.ZipCode">
            <p>
                <span class="contact-card__street" *ngIf="user.StreetAddress">{{ user?.StreetAddress }}</span>
                <span class="contact-card__city" *ngIf="user.City">{{ user?.City }}</span> <span class="contact-card__state" *ngIf="user.State">{{ user?.State?.StatePostalCode }}</span> <span class="contact-card__zip" *ngIf="user.ZipCode">{{ user?.ZipCode | mask: "00000-0000" }}</span>
            </p>
        </div>

        <div class="contact-card__phone" *ngIf="user.Phone">
            <p>{{ user?.Phone | mask: "(000) 000-0000" }}</p>
        </div>

        <div class="contact-card__email" *ngIf="user.Email">
            <p>{{ user?.Email }}</p>
        </div>
    </div>

    <ng-container *ngIf="currentUser$ | async as currentUser">
        <ng-container *ngIf="canSeeAdminDetails(currentUser)">
            <div class="contact-card__admin">
                <div class="contact-card__role">
                    <p><strong>Role: </strong>{{ user?.Role?.RoleDisplayName }}</p>
                </div>

                <div class="contact-card__system-comms" *ngIf="user.Role.RoleID === 1">
                    <p><strong>Receives System Communications:</strong> {{ user?.ReceiveSupportEmails == true ? "Yes" : "No" }}</p>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
