import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'dashboard-onboard',
  templateUrl: './dashboard-onboard.component.html',
  styleUrls: ['./dashboard-onboard.component.scss']
})
export class DashboardOnboardComponent implements OnInit {

  private currentUserSubscription : Subscription;
  public currentUser: UserDto;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUserSubscription = this.authenticationService.currentUserSetObservable.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() : void {
    this.currentUserSubscription.unsubscribe();
  }

}
