import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BoundingBoxDto } from '../../generated/model/bounding-box-dto';
import { LeafletHelperService } from '../../services/leaflet-helper.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { GestureHandling } from "leaflet-gesture-handling";
import { WellService } from '../../generated/api/well.service';
import { ReferenceWellDto } from '../../generated/model/reference-well-dto';
import { environment } from 'src/environments/environment';
import { WellLocationDto } from '../../generated/model/well-location-dto';
import { Router } from '@angular/router';
import { Alert } from '../../models/alert';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { AlertService } from '../../services/alert.service';
import { ProgressService } from '../../services/progress.service';

@Component({
  selector: 'well-location-map',
  templateUrl: './well-location-map.component.html',
  styleUrls: ['./well-location-map.component.scss']
})
export class WellLocationMapComponent implements OnInit, OnDestroy {
  @Input() mapID: string;
  @Input() wellID: number;
  @Input() customRichTextTypeID: number;
  @Input() isConfirmingLocation: boolean = false;

  public well: WellLocationDto;
  public referenceWells: ReferenceWellDto[];

  public mapHeight: string = '400px';
  public selectedParcelStyle: string = 'parcel_yellow';
  public wellMarker: L.Layer;

  public map: L.Map;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  public boundingBox: BoundingBoxDto;

  private wellIcon = this.leafletHelperService.wellIcon;
  private selectedWellIcon = this.leafletHelperService.selectedWellIcon;

  public isLoadingMap = true;
  public isLoadingSubmit = false;

  constructor(
    private router: Router,
    private wellService: WellService,
    private progressService: ProgressService,
    private leafletHelperService: LeafletHelperService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.wellService.wellsWellIDLocationGet(this.wellID).subscribe(well => {
      this.well = well;

      if (this.well.Latitude && this.well.Longitude) {
        const wellLatLng = new L.latLng(this.well.Latitude, this.well.Longitude);
        this.placeWellMarker(wellLatLng);
  
        if (!this.well.Parcel) {
          this.map.setView(wellLatLng, 16);
        }
      }
  
      if (this.well.Parcel) {
        this.addParcelLayer();

        if (!this.isConfirmingLocation) {
          this.updateMapBoundingBox(this.well.BoundingBox);
        }
      }
      
      this.isLoadingMap = false;
    });

    if (!this.isConfirmingLocation) {
      this.wellService.wellsReferenceGet().subscribe(referenceWells => {
        this.referenceWells = referenceWells;
        this.addWellInventoryLayer();
      });
    }
  }

  ngAfterViewInit(): void {
    this.tileLayers = this.leafletHelperService.tileLayers;

    var parcelsWMSOptions = this.leafletHelperService.defaultParcelWMSOptions;
    parcelsWMSOptions.styles = "parcel_labeled";

    this.overlayLayers = Object.assign({
        "Parcels": L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", parcelsWMSOptions)
    }, this.overlayLayers);

    const mapOptions = this.leafletHelperService.defaultMapOptions;
    this.map = L.map(this.mapID, mapOptions);
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this.updateMapBoundingBox();
    this.setControl();

    if (!this.isConfirmingLocation) {
      // register click events
      this.map.on("click", (event: L.LeafletEvent) => this.placeWellMarker(event.latlng));
    }
  }

  ngOnDestroy(): void {
    this.map.off();
    this.map.remove();  
    this.map = null;
  }

  private addWellInventoryLayer() {
    const clusteredWellInventoryLayer = L.markerClusterGroup();
  
    this.referenceWells.forEach(x => {
      new L.marker([x.Latitude, x.Longitude], {icon: this.wellIcon}).addTo(clusteredWellInventoryLayer);
    });

    this.layerControl.addOverlay(clusteredWellInventoryLayer, "Well Inventory");
  }

  private setControl(): void {
    this.layerControl = new L.Control.Layers(this.tileLayers, this.overlayLayers).addTo(this.map);
  }

  private placeWellMarker(latlng: L.latLng) {
    if (this.wellMarker) {
      this.map.removeLayer(this.wellMarker);
    }

    this.wellMarker = new L.marker(
      latlng,
      { icon: this.selectedWellIcon, zIndexOffset: 1000, interactive: this.isConfirmingLocation, draggable: this.isConfirmingLocation }
    );

    if (this.isConfirmingLocation) {
      this.wellMarker.on('dragend', event => {
        const latlng = this.wellMarker.getLatLng();
        this.wellMarker.Latitude = latlng.lat;
        this.wellMarker.Longitude = latlng.lng;
      });
    }

    this.wellMarker.addTo(this.map);
    this.wellMarker.Latitude = latlng.lat;
    this.wellMarker.Longitude = latlng.lng;

    if (this.isConfirmingLocation) {
      this.leafletHelperService.zoomToMarker(this.map, this.wellMarker);
    }
  }

  private updateMapBoundingBox(boundingBox?: BoundingBoxDto) {

    if (!boundingBox) {
      this.leafletHelperService.fitMapToDefaultBoundingBox(this.map);
    } else {
      this.leafletHelperService.fitMapToBoundingBox(this.map, boundingBox);
    }
  }

  private addParcelLayer() {
    const parcelGeoJson = JSON.parse(this.well.Parcel.GeoJson);
    L.geoJSON(parcelGeoJson, { style: { color: "#FFFF85" }, interactive: false }).addTo(this.map);    
  }

  public updateWellLocation(continueToNextStep?: boolean) {
    if (!this.wellMarker) {
      if (continueToNextStep) this.continueToNextStep();
      return;
    }

    this.isLoadingSubmit = true;
    this.alertService.clearAlerts();

    this.well.Latitude = this.wellMarker.Latitude;
    this.well.Longitude = this.wellMarker.Longitude;

    this.wellService.wellsWellIDLocationPut(this.well.WellID, this.well).subscribe(isLocationStepComplete => {
      this.isLoadingSubmit = false;
      this.progressService.pushLocationStepProgress(isLocationStepComplete);

      if (continueToNextStep) {
        this.continueToNextStep();
        return;
      } else {
        this.alertService.pushAlert(new Alert("Well successfully updated", AlertContext.Success));
      }
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  public continueToNextStep() {
    this.router.navigateByUrl(`well-registry/well/${this.well.WellID}/edit/location/irrigated-parcels`);
  }
}
