import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CustomPageService } from './api/custom-page.service';
import { CustomRichTextService } from './api/custom-rich-text.service';
import { FileResourceService } from './api/file-resource.service';
import { FuelTypeService } from './api/fuel-type.service';
import { ImpersonationService } from './api/impersonation.service';
import { MenuItemService } from './api/menu-item.service';
import { ParcelService } from './api/parcel.service';
import { ParcelWaterSourceTypeService } from './api/parcel-water-source-type.service';
import { RoleService } from './api/role.service';
import { StateService } from './api/state.service';
import { SystemInfoService } from './api/system-info.service';
import { UserService } from './api/user.service';
import { UserClaimsService } from './api/user-claims.service';
import { UserParcelService } from './api/user-parcel.service';
import { VerificationKeyService } from './api/verification-key.service';
import { WatershedService } from './api/watershed.service';
import { WellService } from './api/well.service';
import { WellFileResourceService } from './api/well-file-resource.service';
import { WellWaterUseTypeService } from './api/well-water-use-type.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CustomPageService,
    CustomRichTextService,
    FileResourceService,
    FuelTypeService,
    ImpersonationService,
    MenuItemService,
    ParcelService,
    ParcelWaterSourceTypeService,
    RoleService,
    StateService,
    SystemInfoService,
    UserService,
    UserClaimsService,
    UserParcelService,
    VerificationKeyService,
    WatershedService,
    WellService,
    WellFileResourceService,
    WellWaterUseTypeService,
     ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
