import {Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    private validationModel: ValidationModel;
    private validationModelSubject: Subject<ValidationModel> = new Subject();

    constructor() { }

    public getValidationModel(): Observable<ValidationModel> {
        return this.validationModelSubject.asObservable();
    }

    private pushValidationModelSubject() {
        this.validationModelSubject.next(this.validationModel);
    }

    public createValidationModel(model: {}) {
        this.createValidationModelFromFieldList(Object.keys(model));
    }

    public createValidationModelFromFieldList(fields: string[]) {
        this.validationModel = new ValidationModel();
        fields.forEach(field => this.validationModel[field] = new Array<string>());
        this.pushValidationModelSubject();
    }

    public clearValidationModel() {
        this.validationModel = null;
        this.pushValidationModelSubject();
    }

    public pushError(field: string, errorMessage: string) {
        this.validationModel[field]?.push(errorMessage);
        this.pushValidationModelSubject();
    }

    public pushErrorsFromHttpResponse(httpErrorResponse: HttpErrorResponse) {
        var error = httpErrorResponse.error?.errors ?? httpErrorResponse.error;
        if (!error || !(error instanceof Object)) return;

        Object.keys(error).forEach(field => {
            if (this.validationModel[field]) {
                this.validationModel[field].push(error[field]);
            } else {
                console.warn('Error not caught by form validation:', field, error[field])
            }
        });

        this.pushValidationModelSubject();
    }

    public clearErrors() {
        Object.keys(this.validationModel).forEach(field => this.validationModel[field] = new Array<string>());
        this.pushValidationModelSubject();
    }

    public getValidationModelSubject(field: string) {
        if (!this.validationModel) return;

        return this.validationModel[field];
    }

    public isFieldValid(field: string): boolean {
        if (!this.validationModel) return;

        return this.validationModel[field]?.length == 0;
    }
}

export class ValidationModel {
    [key: string]: string[];
}


