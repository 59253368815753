<div class="page-header pb-3">
    <h1 class="page-title">Edit Custom Page Properties</h1>

    <div class="copy copy-2">
        <p>
            This page allows you to edit custom page properties.
            Select a menu category for the custom page to reside in and which roles will be able to view the page.
            Administrators have viewable rights on all custom pages by default.w
        </p>
    </div>

</div>

<div class="page-body" *ngIf="model">
    <app-alert-display></app-alert-display>

    <form class="form grid-12" (ngSubmit)="onSubmit(updateCustomPagePropertiesForm)" #updateCustomPagePropertiesForm="ngForm">
        <div class="field g-col-8">
            <label class="required field-label">Menu</label>
            <select name="MenuItem" class="form-control" [(ngModel)]="model.MenuItemID" [class.input-invalid]="!isValid('MenuItemID')" required>
                <option *ngFor="let menuItem of menuItems" [value]="menuItem.MenuItemID">
                    {{menuItem.MenuItemDisplayName}}
                </option>
            </select>
            <field-validation-area field="MenuItemID"></field-validation-area>
        </div>
        <div class="field g-col-8">
            <label class="required field-label">Page Name</label>
            <input type="text" class="form-control" name="CustomPageDisplayName" placeholder="Page Name" required [(ngModel)]="model.CustomPageDisplayName" (ngModelChange)="slugifyPageName($event)" [class.input-invalid]="!isValid('CustomPageDisplayName')"/>
            <field-validation-area field="CustomPageDisplayName"></field-validation-area>
        </div>

        <div class="field g-col-8">
            <label class="required field-label">Vanity URL</label>
            <input type="text" class="form-control" name="CustomPageVanityUrl" placeholder="Vanity URL" required [(ngModel)]="model.CustomPageVanityUrl" [class.input-invalid]="!isValid('CustomPageVanityUrl')"/>
            <field-validation-area field="CustomPageVanityUrl"></field-validation-area>
        </div>

        <div class="field g-col-8">
            <label class="field-label">Viewable By</label>
            <div class="field-checkbox-single" *ngFor="let role of roles">
                <input type="checkbox" (change)="onViewableRolesChange(role.RoleID)" [checked]="model.ViewableRoleIDs?.includes(role.RoleID)" name="ViewableRoleIDs{{role.RoleID}}" [value]="role.RoleID" #viewableRolesCheckBox{{role.RoleID}} id="viewableRolesCheckBox{{role.RoleID}}" />
                <label class="checkbox-label" for="viewableRolesCheckBox{{role.RoleID}}">
                    {{ role.RoleDisplayName }}
                </label>
            </div>
        </div>

        <div class="field-actions g-col-8">
            <button type="submit" class="btn btn-secondary" [disabled]="isLoadingSubmit">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save
            </button>
            <a class="btn btn-secondary-outline ml-2" routerLink="/manage/custom-pages">Cancel</a>
        </div>
    </form>
</div>