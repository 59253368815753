<div class="copy copy-3 instructions">
    <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
</div>

<div [id]="mapID" class="location-card" [loadingSpinner]="isLoadingMap" [class.crosshair]="!isConfirmingLocation" [style.height]="mapHeight"></div>

<div class="well-info" *ngIf="well">
    <div class="copy copy-3">
        <p>
            <span *ngIf="well.StateWellCompletionNumber" class="state-wcr"><strong>State WCR No:</strong> {{ well.StateWellCompletionNumber }}</span>
            <span *ngIf="well.CountyWellPermit" class="well-no"><strong>County Permit No:</strong> {{ well.CountyWellPermit }}</span>
        </p>

        <p>
            <span class="lat"><strong>Latitude:</strong> <span *ngIf="wellMarker?.Latitude; else notSelected"> {{ wellMarker?.Latitude | number:'0.5-5' }}</span></span>
            <span class="lon"><strong>Longitude:</strong> <span *ngIf="wellMarker?.Longitude; else notSelected"> {{ wellMarker?.Longitude | number:'0.5-5' }}</span></span>
        </p>

        <ng-template #notSelected>
            <em> Not selected</em>
        </ng-template>
    </div>
</div>

<div class="page-footer">
    <fresca-button class="mr-2" [disabled]="isLoadingSubmit" (onClick)="updateWellLocation()" cssClass="btn btn-primary-outline" iconClass="">
        Save
    </fresca-button>

    <fresca-button class="ml-auto" (onClick)="updateWellLocation(true)" [disabled]="isLoadingSubmit">
        Next Step: Select Irrigated Parcels
    </fresca-button>
</div>

