<div *ngIf="user">

    <div class="page-header">
        <h2 class="page-title">Manage Parcels for {{ user.FullName}} </h2>

        <div class="copy copy-2">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>

    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div *ngIf="userParcels">
            <associated-parcels-edit  [parcels]="userParcels" parcelTypeDisplayName="User Parcels"></associated-parcels-edit>
        </div>
    </div>



    <div class="page-footer">
        <button class="btn btn-secondary" [disabled]="isLoadingSubmit" (click)="updateUserParcels()">
            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
            Save Changes
        </button>
        <a class="btn btn-secondary-outline ml-2" routerLink="..">Cancel</a>
    </div>
</div>