import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { ReferenceWellDto } from 'src/app/shared/generated/model/reference-well-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { GestureHandling } from "leaflet-gesture-handling";
import { environment } from 'src/environments/environment';
import { LeafletHelperService } from 'src/app/shared/services/leaflet-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';
import { WellLocationDto } from 'src/app/shared/generated/model/well-location-dto';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';

@Component({
  selector: 'reference-wells',
  templateUrl: './reference-wells.component.html',
  styleUrls: ['./reference-wells.component.scss']
})
export class ReferenceWellsComponent implements OnInit, AfterViewInit, OnDestroy {

  private currentUser: UserDto;

  private well: WellLocationDto;

  private referenceWells: ReferenceWellDto[];
  private referenceWellLayer: L.MarkerClusterGroup;
  public selectedReferenceWell: ReferenceWellDto;
  private selectedReferenceWellMarker: L.Marker;

  public map: L.Map;
  public mapID = "referenceWellMap";
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  private wellIcon = this.leafletHelperService.wellIcon;
  private selectedWellIcon = this.leafletHelperService.selectedWellIcon;

  public customRichTextTypeID = CustomRichTextTypeEnum.CannotFindWell;
  public isLoadingMap = true;
  public isLoadingSubmit = false;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private leafletHelperService: LeafletHelperService,
    private confirmService: ConfirmService,
    private progressService: ProgressService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const wellID = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (wellID) {
        forkJoin({
          well: this.wellService.wellsWellIDLocationGet(wellID),
          referenceWells: this.wellService.wellsReferenceGet()
        }).subscribe(({well, referenceWells}) => {
          this.well = well;
          this.leafletHelperService.fitMapToBoundingBox(this.map, well.BoundingBox);

          this.referenceWells = referenceWells;
          this.updateReferenceWellLayer();

          if (well.ReferenceWellID) {
            this.updateSelectedWell(well.ReferenceWellID);
          }
          if (well.Parcel) {
            this.addParcelLayer();
          }

          this.isLoadingMap = false;
          this.cdr.detectChanges();
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.tileLayers = this.leafletHelperService.tileLayers;

    const parcelsWMSOptions = this.leafletHelperService.defaultParcelWMSOptions;
    this.overlayLayers = Object.assign({
      "Parcels": L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", parcelsWMSOptions)
    }, this.overlayLayers);

    const mapOptions = this.leafletHelperService.defaultMapOptions;
    this.map = L.map(this.mapID, mapOptions);
    this.leafletHelperService.fitMapToDefaultBoundingBox(this.map);
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this.layerControl = new L.Control.Layers(this.tileLayers, this.overlayLayers).addTo(this.map);
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.map.off();
    this.map.remove();  
    this.map = null;
  }

  private updateReferenceWellLayer() {
    if (this.referenceWellLayer) {
      this.map.removeLayer(this.referenceWellLayer);
      this.referenceWellLayer = null;
    }
    this.referenceWellLayer = L.markerClusterGroup();
  
    this.referenceWells.forEach(x => {
      if (x.ReferenceWellID == this.selectedReferenceWell?.ReferenceWellID) return;

      new L.marker([x.Latitude, x.Longitude], { referenceWellID: x.ReferenceWellID, icon: this.wellIcon })
      .on('click', event => this.updateSelectedWell(event.target.options.referenceWellID))
      .addTo(this.referenceWellLayer);
    });

    this.referenceWellLayer.addTo(this.map);  
    
  }

  private addParcelLayer() {
    const parcelGeoJson = JSON.parse(this.well.Parcel.GeoJson);
    L.geoJSON(parcelGeoJson, { style: { color: "#FFFF85" }, interactive: false }).addTo(this.map);  
  }

  private updateSelectedWell(referenceWellID: number) {
    if (this.selectedReferenceWellMarker) {
      this.map.removeLayer(this.selectedReferenceWellMarker);

      this.selectedReferenceWellMarker = null;
      this.selectedReferenceWell = null;
    }

    this.selectedReferenceWell = this.referenceWells.find(x => x.ReferenceWellID == referenceWellID);

    this.selectedReferenceWellMarker = new L.Marker([this.selectedReferenceWell.Latitude, this.selectedReferenceWell.Longitude], 
        { icon: this.selectedWellIcon, zIndexOffset: 100 })
      .bindPopup(
        `<b>State WCR No</b>: ${this.selectedReferenceWell.WCR ?? '<em>Not Available</em>'} <br />` +
        `<b>Permit No</b>: ${this.selectedReferenceWell.PermitNumber ?? '<em>Not Available</em>'} <br />` +
        `<b>Site Location</b>: ${this.selectedReferenceWell.SiteLocation ?? '<em>Not Available</em>'} <br />` +
        '<hr />' +
        `<b>Latitude</b>: ${this.selectedReferenceWell.Latitude} <br />` +
        `<b>Longitude</b>: ${this.selectedReferenceWell.Longitude} <br />`
      )
      .addTo(this.map);

    this.selectedReferenceWellMarker.openPopup();
    this.updateReferenceWellLayer();
  }

  public onSubmit(continueToNextStep?: boolean) {
    if (this.well.ReferenceWellID == this.selectedReferenceWell.ReferenceWellID && continueToNextStep) {
      if (continueToNextStep) this.continueToNextStep();

    } else if (this.selectedReferenceWell.AssociatedWellCount > 0) {
      this.confirmSelectedWell(continueToNextStep);

    } else {
      this.updateReferenceWell(continueToNextStep);
    }
  }

  public confirmSelectedWell(continueToNextStep) {
    const message =  `There ${ this.selectedReferenceWell.AssociatedWellCount > 1 ? 'are' : 'is'} ${this.selectedReferenceWell.AssociatedWellCount} ` +
        `Well Registration${ this.selectedReferenceWell.AssociatedWellCount > 1 ? 's' : ''} already associated with this Reference Well. <br />` +
        'Are you sure you with to proceed?'; 

      this.confirmService.confirm({title: '', message: message, buttonClassYes: 'btn-primary', buttonTextYes: 'Continue', buttonTextNo: 'Cancel'}).then(confirmed => {
        if (confirmed) this.updateReferenceWell(continueToNextStep);
      });
  }

  public updateReferenceWell(continueToNextStep?: boolean) {
    this.isLoadingSubmit = true;
    this.alertService.clearAlerts();

    this.wellService.wellsWellIDReferenceWellReferenceWellIDPut(this.well.WellID, this.selectedReferenceWell.ReferenceWellID).subscribe(isLocationStepComplete => {
      this.isLoadingSubmit = false;
      this.progressService.pushLocationStepProgress(isLocationStepComplete);

      if (continueToNextStep) {
        this.continueToNextStep();
      } else {
        this.alertService.pushAlert(new Alert("Reference well successfully updated.", AlertContext.Success));
      }
      
    }, error => {
      this.isLoadingSubmit = false;
    });
  }

  private continueToNextStep() {
    this.router.navigateByUrl(`/well-registry/well/${this.well.WellID}/edit/location/confirm`);
  }
}
