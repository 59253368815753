<app-alert-display></app-alert-display>

<div class="home-splash">
    <div class="introduction">
        <div class="photo">
            <img src="../assets/main/home-well-image.png" alt="Water Well" />
        </div>

        <div class="info">
            <h1 class="page-title">{{ platformLongName() }}</h1>
            <div class="copy copy-2">
                <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>

    <div class="create-account option">
        <div class="home-card">
            <div class="home-card__header">
                <h3 class="section-title"><em>Need to signup?</em><br />Create an Account</h3>
            </div>

            <div class="home-card__body">
                <a routerLink="/onboard" class="screenshot-link">
                    <img src="../assets/main/mailer.jpeg" alt="Mailer" />
                </a>

                <div class="copy copy-3">
                    <p>If you received a card in the mailer with details on signing up for the Well Registry, you can begin the process by creating your account.</p>
                    <p><strong>Didn't get a mailer?</strong><br />That's ok, you can create your account and begin registering your wells anyway.</p>
                </div>
            </div>

            <div class="home-card__footer">
                <button (click)="createAccount()" class="btn btn-secondary">Create Account</button>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>

    <div class="login option">
        <div class="home-card">
            <div class="home-card__header">
                <h3 class="section-title">
                    <em>Already have an account?</em><br />
                    Login
                </h3>
            </div>

            <div class="home-card__body">
                <a (click)="login()" class="screenshot-link">
                    <img src="../assets/main/wells-dashboard.png" alt="Dashboard" />
                </a>

                <div class="copy copy-3">
                    <p>Login to return to your dashboard where you can start a new well registration, continue an in-progress registration, or make amendments to a registration as requested by your GSA Water Manager.</p>
                </div>
            </div>

            <div class="home-card__footer">
                <button (click)="login()" class="btn btn-secondary-outline">Login</button>
                <p>
                    <a href="javascript:void(0);" (click)="resetPassword()"><small>Forgot password?</small></a>
                </p>
            </div>
        </div>
    </div>
</div>
