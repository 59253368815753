import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CookieService } from "ngx-cookie-service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { RouterModule } from "@angular/router";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { WatershedDetailComponent } from "./pages/watershed-detail/watershed-detail.component";
import { AgGridModule } from "ag-grid-angular";
import { ParcelListComponent } from "./pages/parcel-list/parcel-list.component";
import { DecimalPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { LinkRendererComponent } from "./shared/components/ag-grid/link-renderer/link-renderer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeIconLinkRendererComponent } from "./shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { HelpComponent } from "./pages/help/help.component";
import { MultiLinkRendererComponent } from "./shared/components/ag-grid/multi-link-renderer/multi-link-renderer.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { DisclaimerComponent } from "./pages/disclaimer/disclaimer.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { HttpErrorInterceptor } from "./shared/interceptors/httpErrorInterceptor";
import { environment } from "src/environments/environment";
import { GlobalErrorHandlerService } from "./shared/services/global-error-handler.service";
import { CustomPageListComponent } from "./pages/custom-page-list/custom-page-list.component";
import { CustomPageDetailComponent } from "./pages/custom-page-detail/custom-page-detail.component";
import { CustomPageEditPropertiesComponent } from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { SharedModule } from "./shared/shared.module";
import { ApiModule } from "./shared/generated/api.module";
import { Configuration } from "./shared/generated/configuration";
import { NgxMaskModule } from 'ngx-mask'
import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
} from "@azure/msal-browser";
import {
    MsalGuard,
    MsalBroadcastService,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalGuardConfiguration,
    MsalRedirectComponent,
    MsalInterceptorConfiguration,
    MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { AuthInterceptor } from "./shared/interceptors/auth-interceptor";
import { msalConfig } from "./auth.config";
import { DashboardManageComponent } from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import { VerificationKeyListComponent } from "./pages/verification-key-list/verification-key-list.component";
import { DashboardOnboardComponent } from './pages/dashboards/dashboard-onboard/dashboard-onboard.component';
import { OnboardAccountComponent } from './pages/dashboards/dashboard-onboard/onboard-account/onboard-account.component';
import { OnboardVerificationKeysComponent } from './pages/dashboards/dashboard-onboard/onboard-verification-keys/onboard-verification-keys.component';
import { OnboardParcelsComponent } from './pages/dashboards/dashboard-onboard/onboard-parcels/onboard-parcels.component';
import { UserParcelsEditComponent } from "./pages/user-parcels-edit/user-parcels-edit.component";
import { WorkflowIntroductionComponent } from './pages/well-registry-workflow/workflow-introduction/workflow-introduction.component';
import { WellLocationComponent } from './pages/well-registry-workflow/well-location/well-location.component';
import { BasicWellInfoComponent } from './pages/well-registry-workflow/basic-well-info/basic-well-info.component';
import { SupportingWellInfoComponent } from './pages/well-registry-workflow/supporting-well-info/supporting-well-info.component';
import { WellAttachmentsComponent } from './pages/well-registry-workflow/well-attachments/well-attachments.component';
import { SubmitComponent } from './pages/well-registry-workflow/submit/submit.component';
import { WellRegistryWorkflowComponent } from "./pages/well-registry-workflow/well-registry-workflow.component";
import { WellContactsComponent } from './pages/well-registry-workflow/well-contacts/well-contacts.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { WellListComponent } from "./pages/well-list/well-list.component";
import { DashboardLandownerComponent } from './pages/dashboards/dashboard-landowner/dashboard-landowner.component';
import { ProfileComponent } from './pages/dashboards/dashboard-landowner/profile/profile.component';
import { WellsComponent } from './pages/dashboards/dashboard-landowner/wells/wells.component';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { ConfirmLocationComponent } from './pages/well-registry-workflow/well-location/confirm-location/confirm-location.component';
import { ReferenceWellsComponent } from './pages/well-registry-workflow/well-location/reference-wells/reference-wells.component';
import { IrrigatedParcelsComponent } from './pages/well-registry-workflow/well-location/irrigated-parcels/irrigated-parcels.component';
import { WellDetailComponent } from './pages/dashboards/dashboard-landowner/well-detail/well-detail.component';
import { DatadogService } from "./shared/services/datadog.service";

export function init_app(datadogService: DatadogService) {
    return () => {
        if (environment.datadogClientToken) {
            datadogService.init();
        }
    };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: null,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeIndexComponent,
        UserListComponent,
        UserDetailComponent,
        UserParcelsEditComponent,
        WatershedDetailComponent,
        ParcelListComponent,
        LoginCallbackComponent,
        HelpComponent,
        CreateUserCallbackComponent,
        DisclaimerComponent,
        FieldDefinitionListComponent,
        FieldDefinitionEditComponent,
        CustomPageListComponent,
        CustomPageDetailComponent,
        CustomPageEditPropertiesComponent,
        DashboardManageComponent,
        VerificationKeyListComponent,
        DashboardOnboardComponent,
        OnboardAccountComponent,
        OnboardVerificationKeysComponent,
        OnboardParcelsComponent,
        WellRegistryWorkflowComponent,
        WorkflowIntroductionComponent,
        WellLocationComponent,
        BasicWellInfoComponent,
        SupportingWellInfoComponent,
        WellAttachmentsComponent,
        SubmitComponent,
        WellContactsComponent,
        WellListComponent,
        DashboardLandownerComponent,
        ProfileComponent,
        WellsComponent,
        StyleGuideComponent,
        ConfirmLocationComponent,
        ReferenceWellsComponent,
        IrrigatedParcelsComponent,
        WellDetailComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        SelectDropDownModule,
        NgSelectModule,
        ApiModule.forRoot(() => {
            return new Configuration({
                basePath: `${environment.mainAppApiUrl}`,
            });
        }),
        MsalModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [DatadogService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
