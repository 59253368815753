<div class="custom-page-view">
    <div class="page-header">
        <h1 class="page-title">{{customPageDisplayName}}</h1>

        <div *ngIf="isLoading" class="text-center">
            <span class="fa fa-spinner loading-spinner"></span>
        </div>
    </div>

    <div class="page-body">
        <div class="edit-panel pb-3" *ngIf="!isLoading">
            <app-alert-display></app-alert-display>
            <ng-container *ngIf="!isEditing">

                <div *ngIf="showEditButton()" class="customPageContent" style="position: relative; min-height: 60px;">
                    <div class="hoverEditButton">
                        <div style="margin-top: 10px">
                            <button type="button" class="btn btn-primary" (click)="enterEdit()">
                                Edit
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!emptyContent" class="copy copy-2 custom-content-copy" [innerHtml]="customPageContent"></div>

                    <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
                </div>
                <div *ngIf="!showEditButton()" [innerHtml]="customPageContent"></div>
            </ng-container>

            <div *ngIf="isEditing">
                <editor #tinyMceEditor
                [init]="tinyMceConfig"
                [(ngModel)]="editedContent"
                ></editor>  
                <div style="float:right">
                    <button type="button" class="btn btn-primary mt-1 mr-1" (click)="saveEdit()">Save</button>
                    <button type="button" class="btn btn-secondary mt-1" (click)="cancelEdit()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>