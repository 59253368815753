<div class="page-header">
    <h1 class="page-title">Enter Verification Key</h1>
</div>

<div class="page-body grid-12">
    <div class="g-col-8">
        <ng-container *ngIf="!isAuthenticated() else signedInUser">
            <fresca-button (onClick)="clickedLogin($event)"><i class="fa fa-user"></i> Login or Sign Up</fresca-button>
        </ng-container>

        <ng-template #signedInUser>
            <form class="form grid-12 mb-3" (ngSubmit)="onSubmit(verificationKeyCodeForm)" #verificationKeyCodeForm="ngForm">
                <div class="field g-col-12">
                    <label class="required field-label">Verification Key</label>
                    <div class="verification-key-input-wrapper">
                        <input type="text" class="form-control" name="VerificationKeyCode" placeholder="(e.g. VGA-123)" required [(ngModel)]="model.VerificationKeyCode" />
                        <fresca-button class="ml-auto" type="submit">Submit Key</fresca-button>
                    </div>
                    <field-validation-area field="VerificationKey"></field-validation-area>
                </div>
            </form>

            <app-alert-display></app-alert-display>

            <div class="module verification-keys" *ngIf="hasClaimedVerificationKeys()">
                <div class="module-header">
                    <h3 class="module-title underline">Verified Keys</h3>
                </div>

                <ul class="verification-key-list">
                    <li class="verification-key-list-item" *ngFor="let verificationKey of claimedVerificationKeys">
                        <i class="fa fa-check-circle" aria-hidden="true" style="color: green;"></i> <span class="vertification-key-text">{{verificationKey.VerificationKeyCode}}</span>
                        <!-- <button class="remove-verification-key" (click)="deleteVerificationKey(verificationKey)"><i class="fa fa-times-circle-o"></i></button> -->
                    </li>
                </ul>
            </div>


        </ng-template>
    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer">
    <fresca-button class="ml-auto" [routerLink]="['../parcels']" [disabled]="!isAuthenticated() || !hasClaimedVerificationKeys()">Continue</fresca-button>
</div>