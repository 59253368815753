import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AgGridAngular } from "ag-grid-angular";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { ColDef } from "ag-grid-community";
import { VerificationKeyService } from "src/app/shared/generated/api/verification-key.service";
import { VerificationKeyDto } from "src/app/shared/generated/model/verification-key-dto";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { DatePipe } from "@angular/common";

@Component({
    selector: "fairyshrimp-verification-key-list",
    templateUrl: "./verification-key-list.component.html",
    styleUrls: ["./verification-key-list.component.scss"],
})
export class VerificationKeyListComponent implements OnInit, OnDestroy {
    @ViewChild("verificationKeyGrid") verificationKeyGrid: AgGridAngular;

    private currentUser: UserDto;

    public verificationKeys: VerificationKeyDto[];
    public columnDefs: ColDef[];
    public defaultColDef: ColDef
    public richTextTypeID: number = CustomRichTextTypeEnum.MailMergeReport;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private utilityFunctionsService: UtilityFunctionsService,
        private verificationKeyService: VerificationKeyService,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;

            this.verificationKeyService.verificationKeysGet().subscribe(verificationKeys => {
                this.verificationKeys = verificationKeys;
                this.cdr.detectChanges();
            });

            this.createColumnDefs();
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    private createColumnDefs() {
        this.columnDefs = [
            { headerName: 'Verification Key', field: 'VerificationKeyCode' },
            { 
                headerName: 'Claimed?', 
                valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.IsClaimed, false) ,
                filterFramework: CustomDropdownFilterComponent,
                filterParams: { field: "IsClaimed" },
                width: 110
            },
            { headerName: 'Associated APNs', field: 'ParcelNumbers' },
            this.utilityFunctionsService.createDecimalColumnDef('Total Parcel Area (ac)', 'ParcelArea', 190, 2),
            { headerName: 'Parcel Use Code(s)', field: 'ParcelUseCodes' },
            { headerName: 'Land Owner', field: 'OwnerName' },
            { headerName: 'Street Address', field: 'StreetAddress' },
            { headerName: 'City', field: 'City' },
            { headerName: 'State', field: 'State' },
            { headerName: 'Zip Code', field: 'Zip' },
            { 
                headerName: 'Creation Date', 
                filter: 'agDateColumnFilter',
                valueGetter: (params) => {
                    return new Date(params.data.CreationDate); 
                },
                valueFormatter: (params) => {
                    return this.datePipe.transform(params.data.CreationDate); 
                },
                
            },
        ];

        this.defaultColDef = { sortable: true, filter: true, resizable: true }
    }
}
