<div class="page-header">
    <div class="flex-between">
        <h1 class="page-title">
            {{ well?.WellMetadatum?.WellName }}
        </h1>
        <a  *ngIf="isUserAdmin() && this.well" [routerLink]="getWellEditLink()" class="btn btn-primary btn-small btn-rounded mb-3">
            Update Well
        </a>
    </div>

</div>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <!--LOCATION-->
    <div class="grid-12 location">
        <ng-container *ngIf="well">

            <div class="card g-col-4">
                <div class="card-header">
                    <h3 class="card-title">Location</h3>
                </div>
                <div class="card-body">
                    <dl class="kv-pair">
                        <dt class="key">Latitude</dt>
                        <dd class="value">{{ well.Latitude }}</dd>

                        <dt class="key">Longitude</dt>
                        <dd class="value">{{ well.Longitude }}</dd>

                        <ng-container *ngIf="well.Parcel">
                            <dt class="key">Parcel APN</dt>
                            <dd class="value">{{ well.Parcel.ParcelNumber }}</dd>
                        </ng-container>

                        <ng-container *ngIf="well.IrrigatedParcels?.length > 0">
                            <dt class="key">Irrigated Parcel APNs</dt>
                            <dd class="value">
                                <div *ngFor="let parcel of well.IrrigatedParcels">{{ parcel.ParcelNumber }}</div>
                            </dd>
                        </ng-container>
                    </dl>
                </div>

            </div>

        </ng-container>

        <div class="g-col-8 map">
            <div [id]="mapID" class="location-card" [style.height]="mapHeight"></div>
        </div>
    </div>

    <hr>

    <ng-container *ngIf="well">

        <!-- CONTACTS -->
        <div class="section-header pb-3">
            <h2 class="section-title">Contacts</h2>
        </div>

        <div class="grid-12">
            <div class="card g-col-6">
                <div class="card-header">
                    <h3 class="card-title">Landowner</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well.Landowner">
                        <dt class="key">Contact Name</dt>
                        <dd class="value">{{ well.Landowner.ContactName }}</dd>

                        <dt class="key">Business Name</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.Landowner.BusinessName">
                                {{ well.Landowner.BusinessName }}
                            </ng-container>
                        </dd>

                        <dt class="key">Street Address</dt>
                        <dd class="value">{{ well.Landowner.StreetAddress }}</dd>

                        <dt class="key">City</dt>
                        <dd class="value">{{ well.Landowner.City }}</dd>

                        <dt class="key">State</dt>
                        <dd class="value">{{ well.Landowner.State?.StateName }}</dd>

                        <dt class="key">Zip Code</dt>
                        <dd class="value">{{ well.Landowner.ZipCode }}</dd>

                        <dt class="key">Phone</dt>
                        <dd class="value">{{ well.Landowner.Phone }}</dd>

                        <dt class="key">Email</dt>
                        <dd class="value">{{ well.Landowner.Email }}</dd>
                    </dl>
                </div>
            </div>

            <div class="card g-col-6">
                <div class="card-header">
                    <h3 class="card-title">Owner Operator</h3>
                </div>

                <div class="card-body card-body__operator">
                    <dl class="kv-pair" *ngIf="well.OwnerOperator else noOperatorInfo">
                        <dt class="key">Contact Name</dt>
                        <dd class="value">{{ well.OwnerOperator.ContactName }}</dd>

                        <dt class="key">Business Name</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.OwnerOperator.BusinessName">
                                {{ well.OwnerOperator.BusinessName }}
                            </ng-container>
                        </dd>

                        <dt class="key">Street Address</dt>
                        <dd class="value">{{ well.OwnerOperator.StreetAddress }}</dd>

                        <dt class="key">City</dt>
                        <dd class="value">{{ well.OwnerOperator.City }}</dd>

                        <dt class="key">State</dt>
                        <dd class="value">{{ well.OwnerOperator.State?.StateName }}</dd>

                        <dt class="key">Zip Code</dt>
                        <dd class="value">{{ well.OwnerOperator.ZipCode }}</dd>

                        <dt class="key">Phone</dt>
                        <dd class="value">{{ well.OwnerOperator.Phone }}</dd>

                        <dt class="key">Email</dt>
                        <dd class="value">{{ well.OwnerOperator.Email }}</dd>
                    </dl>

                    <ng-template #noOperatorInfo>

                        <div class="no-operator-info copy copy-3">
                            <p>Landowner indicated they are the operator.</p>
                        </div>

                    </ng-template>
                </div>
            </div>
        </div>

        <hr>

        <!-- WELL DETAILS -->
        <div class="section-header pb-3">
            <h2 class="section-title">Well Details</h2>
        </div>

        <div class="grid-12 well-details">
            <div class="card g-col-4 basic-info">
                <div class="card-header">
                    <h3 class="card-title">Basic Info</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well?.WellMetadatum">
                        <dt class="key">Owner's Well Name/No.</dt>
                        <dd class="value">{{ well.WellMetadatum.WellName }}</dd>

                        <dt class="key">State Well No.</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.StateWellNumber">
                                {{ well.WellMetadatum.StateWellNumber }}
                            </ng-container>
                        </dd>

                        <dt class="key">State WCR No.</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.StateWellCompletionNumber">
                                {{ well.WellMetadatum.StateWellCompletionNumber }}
                            </ng-container>
                        </dd>

                        <dt class="key">County Well Permit</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.StateWellCompletionNumber">
                                {{ well.WellMetadatum.CountyWellPermit }}
                            </ng-container>
                        </dd>

                        <dt class="key">Date Drilled</dt>
                        <dd class="value">{{ well.WellMetadatum.DateDrilled | date:"shortDate" : "utc" }}</dd>
                    </dl>
                </div>
            </div>

            <div class="card g-col-4 technical-info">
                <div class="card-header">
                    <h3 class="card-title">Technical Info</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well?.WellMetadatum">
                        <dt class="key">Well Depth (ft.)</dt>
                        <dd class="value">{{ well.WellMetadatum.WellDepth }}</dd>

                        <dt class="key">Casing Diameter (in.)</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.CasingDiameter">
                                {{ well.WellMetadatum.CasingDiameter }}
                            </ng-container>
                        </dd>

                        <dt class="key">Top of Perforations</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.TopOfPerforations">
                                {{ well.WellMetadatum.TopOfPerforations }}
                            </ng-container>
                        </dd>

                        <dt class="key">Bottom of Perforations</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.BottomOfPerforations">
                                {{ well.WellMetadatum.BottomOfPerforations }}
                            </ng-container>
                        </dd>

                        <dt class="key">Serial No. of Water Meter</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.SerialNumberOfWaterMeter">
                                {{ well.WellMetadatum.SerialNumberOfWaterMeter }}
                            </ng-container>
                        </dd>

                        <dt class="key">Manufacturer of Water Meter</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.ManufacturerOfWaterMeter">
                                {{ well.WellMetadatum.ManufacturerOfWaterMeter }}
                            </ng-container>
                        </dd>

                        <dt class="key">Electric Meter Number</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.ElectricMeterNumber">
                                {{ well.WellMetadatum.ElectricMeterNumber }}
                            </ng-container>
                        </dd>
                    </dl>
                </div>
            </div>

            <div class="card g-col-4 water-uses">
                <div class="card-header">
                    <h3 class="card-title">Water Uses</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well?.WellWaterUse">
                        <ng-container *ngFor="let wellWaterUse of well.WellWaterUse">
                            <dt class="key">{{ wellWaterUse.WellWaterUseType?.WellWaterUseTypeDisplayName }}</dt>
                            <dd class="value">{{ wellWaterUse.WellWaterUseDescription }}</dd>
                        </ng-container>
                    </dl>
                </div>
            </div>
        </div>

        <hr />

        <!-- PUMP DETAILS -->
        <div class="section-header pb-3">
            <h2 class="section-title">Pump Details</h2>
        </div>

        <div class="grid-12">
            <div class="card g-col-6">
                <div class="card-header">
                    <h3 class="card-title">Basic Info</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well?.WellMetadatum">
                        <dt class="key">Pump Discharge Diameter (in.)</dt>
                        <dd class="value">{{ well.WellMetadatum.PumpDischargeDiameter }}</dd>

                        <dt class="key">Motor/Engine (hp)</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.MotorHorsePower">
                                {{ well.WellMetadatum.MotorHorsePower }}
                            </ng-container>
                        </dd>

                        <dt class="key">Fuel Type</dt>
                        <dd class="value">
                            {{ well.WellMetadatum.FuelType?.FuelTypeDisplayName }}
                        </dd>

                        <dt class="key">Fuel Type Description</dt>
                        <dd class="value">
                            <ng-container *ngIf="isFuelTypeOther">
                                {{ well.WellMetadatum.FuelOther }}
                            </ng-container>
                        </dd>

                        <dt class="key">Maximum Flow</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.MaximumFlow">
                                {{ well.WellMetadatum.MaximumFlow }}
                                <span *ngIf="well.WellMetadatum.isEstimatedMax as isEstimatedMax"> ({{ getEstimatedOrMeasured(isEstimatedMax) }})</span>
                            </ng-container>
                        </dd>

                        <dt class="key">Maximum Flow</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.TypicalPumpFlow">

                                {{ well.WellMetadatum.MaximumFlow }}
                                <span *ngIf="well.WellMetadatum.isEstimatedTypical"> ({{ getEstimatedOrMeasured(isEstimatedTypical) }})</span>
                            </ng-container>
                        </dd>
                    </dl>
                </div>
            </div>

            <div class="card g-col-6">
                <div class="card-header">
                    <h3 class="card-title">Pump Test (Most Recent)</h3>
                </div>

                <div class="card-body">
                    <dl class="kv-pair" *ngIf="well?.WellMetadatum">
                        <dt class="key">Conducted By</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpTestBy">
                                {{ well.WellMetadatum.PumpTestBy }}
                            </ng-container>
                        </dd>

                        <dt class="key">Performed Test Date</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpTestDatePerformed">
                                {{ well.WellMetadatum.PumpTestDatePerformed | date:"shortDate" :"utc" }}
                            </ng-container>
                        </dd>

                        <dt class="key">Pump Manufacturer</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpManufacturer">
                                {{ well.WellMetadatum.PumpManufacturer }}
                            </ng-container>
                        </dd>

                        <dt class="key">Yield (gpm)</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpYield">
                                {{ well.WellMetadatum.PumpYield }}
                            </ng-container>
                        </dd>

                        <dt class="key">Static Level (ft.)</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpStaticLevel">
                                {{ well.WellMetadatum.PumpStaticLevel }}
                            </ng-container>
                        </dd>

                        <dt class="key">Pumping Level (ft.)</dt>
                        <dd class="value">
                            <ng-container *ngIf="well.WellMetadatum.PumpingLevel">
                                {{ well.WellMetadatum.PumpingLevel }}
                            </ng-container>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>

        <hr>

        <!-- ATTACHMENTS -->
        <div class="section-header pb-3">
            <h2 class="section-title">Attachments</h2>
        </div>

        <div class="grid-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Uploaded Files</h3>
                </div>

                <div class="card-body card-body__attachments">
                    <ng-container *ngIf="well.WellFileResources?.length > 0 else noAttachments">
                        <div *ngFor="let wellFileResource of well.WellFileResources; let index = index; let last = last">
                            <div class="grid-12">
                                <div class="g-col-8 attachment-data">
                                    <div class="pb-2"><b><a style="cursor:pointer;" (click)="openFileResourceLink(wellFileResource.FileResource)">{{ wellFileResource.FileResource.OriginalBaseFilename }}</a></b></div>
                                    <div class="pb-2">{{ wellFileResource.FileDescription }}</div>
                                    <div><em>Uploaded {{ wellFileResource.FileResource.CreateDate | date:'short'}}</em></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #noAttachments>

                        <div class="no-attachments copy copy-3">
                            <p>No attachments uploaded.</p>
                        </div>

                    </ng-template>
                </div>
            </div>
        </div>

    </ng-container>
</div>