<div class="page-header">
    <h1 class="page-title">Labels & Definitions</h1>

    <div class="copy copy-2">
        <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
    </div>

    <app-alert-display></app-alert-display>
</div>

<div class="page-body">
    <fairyshrimp-clear-grid-filters-button class="m-2" [grid]="fieldDefinitionsGrid">
    </fairyshrimp-clear-grid-filters-button>

    <div class="table-responsive">
        <ag-grid-angular #fieldDefinitionsGrid style="width: 100%; height: 800px;" class="ag-theme-balham" [rowData]="fieldDefinitions" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" [suppressMenuHide]="true">
        </ag-grid-angular>
    </div>
</div>