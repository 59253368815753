import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellWaterUseTypeService } from 'src/app/shared/generated/api/well-water-use-type.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellBasicInfoDto } from 'src/app/shared/generated/model/well-basic-info-dto';
import { WellWaterUseBasicInfoSimpleDto } from 'src/app/shared/generated/model/well-water-use-basic-info-simple-dto';
import { WellWaterUseTypeDto } from 'src/app/shared/generated/model/well-water-use-type-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';
import { ProgressService } from 'src/app/shared/services/progress.service';

@Component({
  selector: 'basic-well-info',
  templateUrl: './basic-well-info.component.html',
  styleUrls: ['./basic-well-info.component.scss']
})
export class BasicWellInfoComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;
  public wellID: number;
  public model: WellBasicInfoDto;
  public wellWaterUseTypes: WellWaterUseTypeDto[];

  public customRichTextTypeID = CustomRichTextTypeEnum.WellRegistryBasics;
  public requiredFieldsCustomRichTextTypeID = CustomRichTextTypeEnum.WellBasicssRequiredFields;
  public isLoadingSubmit: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private wellWaterUseTypeService: WellWaterUseTypeService,
    private wellService: WellService,
    private progressService: ProgressService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const id = this.route.snapshot.paramMap.get(routeParams.wellID);
      if (id) {
        this.wellID = parseInt(id);
        forkJoin ({
          wellMetadatum: this.wellService.wellsWellIDBasicInfoGet(this.wellID),
          wellWaterUseTypes: this.wellWaterUseTypeService.wellWaterUseTypeGet(),
        }).subscribe(({wellMetadatum, wellWaterUseTypes}) => {
          this.model = wellMetadatum;
          this.model.DateDrilled = this.getDate(this.model.DateDrilled)

          this.wellWaterUseTypes = wellWaterUseTypes;
        });
      }

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  getSelected(w: WellWaterUseBasicInfoSimpleDto){
    return w.Selected;
  }

  changeSelected(w: WellWaterUseBasicInfoSimpleDto){
    w.Selected = !w.Selected;
  }

  getDate(date: string){
    if (date != null && date != "") {
      return date.substring(0, 10);
    }
    return null;
  }

  removeWhiteSpace (str:string){
    return str.replace(/\s/g, "");
  }

  getFieldDefinition(name: string) {
    switch(name) {
      case 'Agricultural':
      case 'Domestic':
      case 'Public Municipal':
      case 'Private Municipal':
        return this.removeWhiteSpace(name) + "WaterUse";
      case 'Stock Watering':
        return this.removeWhiteSpace(name);
      default:
        return false;
    }
  }

  public updateWellBasics(continueToNextStep?: boolean) {
    this.isLoadingSubmit = true;
    this.alertService.clearAlerts();

    this.wellService.wellsWellIDBasicInfoPost(this.wellID,this.model).subscribe(isBasicsStepComplete => {
      this.isLoadingSubmit = false;
      this.progressService.pushBasicsStepProgress(isBasicsStepComplete);
      
      if (continueToNextStep) {
        this.router.navigateByUrl(`well-registry/well/${this.wellID}/edit/supporting-information`);
      } else {
        this.alertService.pushAlert(new Alert("Basic information successfully updated.", AlertContext.Success));
        window.scroll(0,0);
      }

    }, error => {
      this.isLoadingSubmit = false;
    })
  }
}
