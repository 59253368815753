import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { WellWaterUseTypeService } from 'src/app/shared/generated/api/well-water-use-type.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { WellStatusEnum } from 'src/app/shared/generated/enum/well-status-enum';
import { ReferenceWellDto } from 'src/app/shared/generated/model/reference-well-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellSummaryDto } from 'src/app/shared/generated/model/well-summary-dto';
import { WellWaterUseTypeDto } from 'src/app/shared/generated/model/well-water-use-type-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'well-list',
  templateUrl: './well-list.component.html',
  styleUrls: ['./well-list.component.scss']
})
export class WellListComponent implements OnInit {
  @ViewChild("wellGrid") wellGrid: AgGridAngular;

  private currentUser: UserDto;

  public wells: WellSummaryDto[];
  public referenceWells: ReferenceWellDto[];
  public wellWaterUses: { [WellID: number]: { [WellWaterUseTypeName: string]: string }} = {};

  public wellColumnDefs: ColDef[];
  public referenceWellColumnDefs: ColDef[];
  public defaultColDef: ColDef;
  public csvDownloadColIDsToExclude = ['0'];

  public getRowId = params => {
    return params.WellID.toString();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private wellService: WellService,
    private confirmService: ConfirmService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        wells: this.wellService.wellsSummaryGet(),
        referenceWells: this.wellService.wellsReferenceGet(),
      }).subscribe(({wells, referenceWells}) => {
        this.wells = wells;
        this.referenceWells = referenceWells;

        wells.forEach(well => {
          this.wellWaterUses[well.WellID] = {};
          well.WellWaterUse?.forEach(x => this.wellWaterUses[x.WellID][x.WellWaterUseType.WellWaterUseTypeName] = x.WellWaterUseDescription)
        });

        this.cdr.detectChanges();
      });

      this.createColumnDefs();
    });
  }

  private wellWaterUseValueGetter(wellID: number, wellWaterUseTypeName: string): string {
    return this.wellWaterUses[wellID]?.hasOwnProperty(wellWaterUseTypeName) ? "Yes" : "No";
  }

  private wellWaterUseDescriptionValueGetter(wellID: number, wellWaterUseTypeName: string) {
    return this.wellWaterUses[wellID]?.[wellWaterUseTypeName];
  }

  private createColumnDefs() {
    this.defaultColDef = { resizable: true, sortable: true, filter: true };

    this.wellColumnDefs = [
      {
        valueGetter: params => {
          if (params.data.WellStatus.WellStatusID == WellStatusEnum.Approved) return {LinkValue: `${params.data.WellID}/edit`, LinkDisplay: "Edit"};

          return { LinkValue: `${params.data.WellID}/edit`, LinkDisplay: params.data.WellStatus.WellStatusID == WellStatusEnum.Submitted ? "Review" : "Edit" }
        },
        cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: '/well-registry/well/', cssClasses: "btn btn-primary btn-sm" },
        width: 100, sortable: false, filter: false
      },
      {
        valueGetter: (params: any) => {
          return { LinkAction: () => this.deleteRegistration(params.data.WellID), LinkDisplay: "Delete" };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { cssClasses: "btn btn-danger-outline btn-sm" },
        width: 100, sortable: false, filter: false, pinned: 'right'
      },
      { headerName: "Well Registration ID", field: "WellID", width:190 },
      {
        headerName: 'Well Name', 
        valueGetter: params => {
          if (params.data.WellStatus.WellStatusID == WellStatusEnum.Approved) {
            return { LinkValue: params.data.WellID, LinkDisplay: params.data.WellMetadatum.WellName };
          }

          return { LinkDisplay: params.data.WellMetadatum.WellName };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: ''},
        filterValueGetter: params => params.data.WellMetadatum.WellName,
        comparator: this.utilityFunctionsService.linkRendererComparator,
        width: 170
      },
      { headerName: 'APN', field: "ParcelNumber" },
      { headerName: 'Status', field: "WellStatus.WellStatusDisplayName", width: 120 },
      this.utilityFunctionsService.createDateColumnDef("Date Submitted", "SubmitDate", "shortDate", 120),
      this.utilityFunctionsService.createDateColumnDef("Date Approved", "ApprovalDate", "shortDate", 120),
      { 
        headerName: "Created By",
        valueGetter: params => {
          return { LinkValue: params.data.CreateUser?.UserID, LinkDisplay: params.data.CreateUser?.FullName };
        },
        cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: '/manage/users/' },
        filterValueGetter: params => params.data.CreateUser?.FullName
      },
      this.utilityFunctionsService.createDecimalColumnDef("Latitude", "Latitude", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Longitude", "Longitude", 120),
      { headerName: "Irrigated Parcels", field: "IrrigatedParcelsString" },
      { headerName: "Landowner Name", field: "Landowner.ContactName" },
      { headerName: "Landowner Business Name", field: "Landowner.BusinessName" },
      { headerName: "Landowner Street Address", field: "Landowner.StreetAddress" },
      { headerName: "Landowner City", field: "Landowner.City" },
      { headerName: "Landowner State", field: "Landowner.State.StatePostalCode" },
      { headerName: "Landowner Zip Code", field: "Landowner.ZipCode" },
      { headerName: "Landowner Phone", field: "Landowner.Phone" },
      { headerName: "Landowner Email", field: "Landowner.Email" },
      { headerName: "Owner Operator Name", field: "OwnerOperator.ContactName" },
      { headerName: "Owner Operator Business Name", field: "OwnerOperator.BusinessName" },
      { headerName: "Owner Operator Street Address", field: "OwnerOperator.StreetAddress" },
      { headerName: "Owner Operator City", field: "OwnerOperator.City" },
      { headerName: "Owner Operator State", field: "OwnerOperator.State.StatePostalCode" },
      { headerName: "Owner Operator Zip Code", field: "OwnerOperator.ZipCode" },
      { headerName: "Owner Operator Phone", field: "OwnerOperator.Phone" },
      { headerName: "Owner Operator Email", field: "OwnerOperator.Email" },
      { headerName: "State Well No.", field: "WellMetadatum.StateWellNumber" },
      { headerName: "State WCR No.", field: "WellMetadatum.StateWellCompletionNumber" },
      { headerName: "County Well Permit", field: "WellMetadatum.CountyWellPermit" },
      this.utilityFunctionsService.createDateColumnDef("Date Drilled", "WellMetadatum.DateDrilled", "shortDate", 120),
      { headerName: "Agricultural Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "Agricultural") },
      { headerName: "Agricultural Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "Agricultural") },
      { headerName: "Stock Watering Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "StockWatering") },
      { headerName: "Stock Watering Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "StockWatering") },
      { headerName: "Domestic Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "Domestic") },
      { headerName: "Domestic Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "Domestic") },
      { headerName: "Public Municipal Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "PublicMunicipal") },
      { headerName: "Public Municipal Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "PublicMunicipal") },
      { headerName: "Private Municipal Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "PrivateMunicipal") },
      { headerName: "Private Municipal Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "PrivateMunicipal") },
      { headerName: "Other Water Use", valueGetter: params => this.wellWaterUseValueGetter(params.data.WellID, "Other") },
      { headerName: "Other Water Use Description", valueGetter: params => this.wellWaterUseDescriptionValueGetter(params.data.WellID, "Other") },
      this.utilityFunctionsService.createDecimalColumnDef("Well Depth (ft)", "WellMetadatum.WellDepth", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Casing Diameter (in)", "WellMetadatum.CasingDiameter", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Top of Perforations", "WellMetadatum.TopOfPerforations", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Bottom of Perforations", "WellMetadatum.BottomOfPerforations", 120),
      { headerName: "Serial No. of Water Meter", field: "WellMetadatum.SerialNumberOfWaterMeter" },
      { headerName: "Manufacturer of Water Meter", field: "WellMetadatum.ManufacturerOfWaterMeter" },
      { headerName: "Electric Meter Number", field: "WellMetadatum.ElectricMeterNumber" },
      this.utilityFunctionsService.createDecimalColumnDef("Pump Discharge Diameter (in)", "WellMetadatum.PumpDischargeDiameter", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Motor/Engine (hp)", "WellMetadatum.MotorHorsePower", 120),
      { 
        headerName: "Fuel Type", field: "WellMetadatum.FuelType.FuelTypeDisplayName",
        filterFramework: CustomDropdownFilterComponent,
        filterParams: { field: "WellMetadatum.FuelType.FuelTypeDisplayName" }
      },
      { headerName: "Fuel Type Description", field: "WellMetadatum.FuelOther" },
      this.utilityFunctionsService.createDecimalColumnDef("Maximum Flow", "WellMetadatum.MaximumFlow", 120),

      { headerName: "Maximum Flow Estimated?", valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.WellMetadatum.isEstimatedMax, true) },
      this.utilityFunctionsService.createDecimalColumnDef("Typical Pump Flow", "WellMetadatum.TypicalPumpFlow", 120),
      { headerName: "Typical Pump Flow Estimated?", valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.WellMetadatum.isEstimatedTypical, true) },
      this.utilityFunctionsService.createDateColumnDef("Most Recent Pump Test Date", "WellMetadatum.PumpTestDatePerformed", "shortDate", 180),
      { headerName: "Conducted By", field: "WellMetadatum.PumpTestBy" },
      { headerName: "Pump Manufacturer", field: "WellMetadatum.PumpManufacturer" },
      this.utilityFunctionsService.createDecimalColumnDef("Yield (gpm)", "WellMetadatum.PumpYield", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Static Level (ft)", "WellMetadatum.PumpStaticLevel", 120),
      this.utilityFunctionsService.createDecimalColumnDef("Pumping Level (ft)", "WellMetadatum.PumpingLevel", 120),

    ]; 

    this.referenceWellColumnDefs = [
      { headerName: "Record ID", field: "RecordID" },
      { headerName: "Program El", field: "ProgramEl" },
      { headerName: "Intended Use", field: "IntendedUse" },
      { headerName: "APN", field: "APN" },
      { headerName: "Permit Number", field: "PermitNumber" },
      { headerName: "Permit App", field: "PermitApp" },
      { headerName: "Site Location", field: "SiteLocation" },
      { headerName: "Casing Diameter", field: "CasingDiameter" },
      { headerName: "Casing Material", field: "CasingMaterial" },
      { headerName: "Well Depth", field: "WellDepth" },
      { headerName: "Tr Depth", field: "TrDepth" },
      { headerName: "Status", field: "Status" },
      { headerName: "Township", field: "Township" },
      { headerName: "Range", field: "Range" },
      { headerName: "Section", field: "Section" },
      { headerName: "Categorization", field: "Categorization" },
      { headerName: "WCR Number", field: "WCR" },
      { headerName: "MTR", field: "MTR" },
      { headerName: "Source", field: "Source" },
      { headerName: "Method", field: "Method" },
      { headerName: "MIUGSA?", valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.MIUGSA) }
    ];
  }

  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  private gridApi : GridApi;

  public deleteRegistration(wellID: number) {
    let wellGridIndex = this.wells.findIndex(x => x.WellID == wellID);
    let well = this.wells[wellGridIndex];
    let message = well.WellMetadatum?.WellName ? `Are you sure you want to delete the well "${well.WellMetadatum.WellName}"?` : `Are you sure you want to delete this well?`;

    this.confirmService.confirm({title: "Delete Well", message: message, buttonTextYes: "Delete", buttonClassYes: "btn-danger", buttonTextNo: "Cancel"}).then(confirmed => {
      if (confirmed) {
        this.wellService.wellsWellIDDelete(wellID).subscribe(response => {
          // remove the deleted well
          this.alertService.pushAlert(new Alert(`Successfully deleted well.`, AlertContext.Success, true))
          this.gridApi.applyTransaction({remove: [{WellID:wellID}]})
        });
      }
    });
  }
}
