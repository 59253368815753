import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public user$ : Observable<UserDto>
  public currentUser$: Observable<UserDto>;
  public isEditingUserProfile: boolean = false;

  constructor(private authenticationService: AuthenticationService, private userService : UserService) { }

  ngOnInit(): void {
    this.setupObservables();
  }

  setupObservables(): void {
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
      tap(userDto => {
        this.user$ = this.userService.usersUserIDGet(userDto.UserID);
      })
    );
  }

  canUpdateUserProfile(currentUser: UserDto, userProfile: UserDto): boolean {
    return currentUser.UserID == userProfile.UserID || this.authenticationService.hasPermission(currentUser, PermissionEnum.UserRights, RightsEnum.Update);
  }

  editClosed(event: any) {
    this.isEditingUserProfile = false;
    this.setupObservables();
  }
  
  changePassword(user: UserDto) : void {
    this.authenticationService.resetPassword(user.Email)
  }

}
