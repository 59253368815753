<div class="dashboard">
    <aside class="sidebar">
        <div class="sidebar-header">
            <h5 class="sidebar-title">
                <img src="assets/main/logos/MIUGSA-logo.png" alt="MIUGSA Logo" />
                Well Registry Workflow
            </h5>
        </div>

        <ul class="sidebar-nav">
            <li class="sidebar-item">
                <a [routerLink]="['introduction']" routerLinkActive="active" class="sidebar-link">
                    <span class="svg-wrapper">
                        <progress-icon [isInformational]="true"></progress-icon>
                        <span class="svg-label">Intro</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['location']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isComplete]="wellProgress?.IsLocationStepComplete"></progress-icon>
                        <span class="svg-label">Map Your Well</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['contacts']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isComplete]="wellProgress?.IsContactsStepComplete"></progress-icon>
                        <span class="svg-label">Contacts</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['basic-information']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isComplete]="wellProgress?.IsBasicsStepComplete"></progress-icon>
                        <span class="svg-label">Basic Information</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['supporting-information']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isComplete]="wellProgress?.IsSupportingInfoStepComplete"></progress-icon>
                        <span class="svg-label">Supporting Information</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['attachments']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isInformational]="true"></progress-icon>
                        <span class="svg-label">Attachments</span>
                    </span>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['submit']" routerLinkActive="active" class="sidebar-link" [class.disabled]="isCreating">
                    <span class="svg-wrapper">
                        <progress-icon [isComplete]="isWorkflowComplete"></progress-icon>
                        <span class="svg-label">Submit</span>
                    </span>
                </a>
            </li>
        </ul>
    </aside>

    <main class="main">
        <router-outlet></router-outlet>
    </main>
</div>


<div class="sticky-footer flex-end reviewing" *ngIf="isReviewing">
    <fresca-button iconClass="fas fa-thumbs-up" class="mr-3" (onClick)="approveWell()" [disabled]="isLoadingSubmit">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Approve
    </fresca-button>
    <fresca-button iconClass="fas fa-thumbs-down" cssClass="btn btn-danger" class="mr-3" (onClick)="returnWell()" [disabled]="isLoadingSubmit">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Return
    </fresca-button>
</div>

<div class="sticky-footer edit-approved" *ngIf="isApproved">
    <div class="sticky-footer-text">
        <i class="fa fa-warning"></i>
        Caution, you are editing an approved well.
    </div>
</div>