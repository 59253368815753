<div class="page-header">
    <h4 class="sidebar-title mb-3">Well Registry Workflow / Step 1</h4>
    <h1 class="page-title">Intro</h1>
</div>

<div class="page-body grid-12">
    <div class="g-col-8">
        <app-alert-display></app-alert-display>

        <form class="form grid-12 mb-3" #wellParcelForm="ngForm">

            <div class="field g-col-12">
                <div class="field-label">APN where your well is located</div>
                <ng-select class="search" name="parcelNumber" [items]="parcels$ | async" bindLabel="ParcelNumber" 
                    [(ngModel)]="selectedParcel" [typeahead]="parcelInputs$" [minTermLength]="2" [loading]="searchLoading" 
                    placeholder="Search APNs" typeToSearchText="Begin typing to search">
                </ng-select>

                <div class="mt-2 copy copy-3">
                    <field-definition fieldDefinitionType="SelectWellAPN"></field-definition>
                </div>
            </div>
        </form>
    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer">
    <fresca-button class="ml-auto" (onClick)="continue()">Continue</fresca-button>
</div>