<div class="custom-page-view">
    <div class="page-header">
        <h1 class="page-title">Help</h1>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div class="copy copy-2 copy-55 pb-3">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>