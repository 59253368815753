<form #userProfileEditForm class="form mb-3 g-col-8" (ngSubmit)="onSubmit()">

    <div class="field grid-9">
        
        <div class="field g-col-half">
            <label class="required field-label">First Name</label>
            <input type="text" class="form-control" maxlength="100" name="FirstName" required [(ngModel)]="userModel.FirstName" />
        </div>
        <div class="field g-col-half">
            <label class="required field-label">Last Name</label>
            <input type="text" class="form-control" maxlength="100" name="LastName" required [(ngModel)]="userModel.LastName" />
        </div>

        <div class="field">
            <label class="field-label">Business Name</label>
            <input type="text" class="form-control" maxlength="250" name="BusinessName" [(ngModel)]="userModel.BusinessName" />
        </div>

        <div class="field g-col-3">
            <label class="field-label">Street Address</label>
            <input type="text" class="form-control" maxlength="250" name="StreetAddress" [(ngModel)]="userModel.StreetAddress" />
        </div>

        <div class="field g-col-3">
            <label class="field-label">City</label>
            <input type="text" class="form-control" maxlength="250" name="City" [(ngModel)]="userModel.City" />
        </div>

        <div class="field g-col-3">
            <label class="field-label">State</label>
            <select name="State" class="form-control" [(ngModel)]="userModel.State" *ngIf="states$ | async as states" [compareWith]="compareStates">
                <option selected disabled [ngValue]="null">Choose One</option>
                <option *ngFor="let state of states" [ngValue]="state">
                    {{state.StatePostalCode}}
                </option>
            </select>
        </div>

        <div class="field">
            <label class="field-label">Zip Code</label>
            <input type="text" mask="00000-0000" maxlength="15" class="form-control" name="ZipCode" [(ngModel)]="userModel.ZipCode" />
        </div>
         
        <div class="field g-col-half">
            <label class="required field-label">Phone</label>
            <input type="tel" mask="(000) 000-0000" class="form-control" name="Phone" [(ngModel)]="userModel.Phone" />
        </div>

        <div class="field g-col-half">
            <label class="field-label">Email</label>
            <input type="text" disabled class="form-control" name="Email" [(ngModel)]="userModel.Email" />
        </div>

        <ng-container *ngIf="currentUser$ | async as currentUser">
            <ng-container *ngIf="canEditRoles(currentUser)">
                <div class="field">
                    <label class="required field-label">Role</label>
                    <select name="RoleID" class="form-control" [(ngModel)]="userModel.Role.RoleID" (change)="checkReceiveSupportEmails()" required>
                        <option *ngFor="let role of roles$ | async" [value]="role.RoleID">
                            {{role.RoleDisplayName}}
                        </option>
                    </select>
                </div>
        
                <div class="field" *ngIf="userModel.Role.RoleID.toString() === '1'">
                    <label class="d-block required field-label">Should Receive System Communications?</label>
                    <label class="mr-3"><input type="radio" name="ReceiveSupportEmails" [(ngModel)]="userModel.ReceiveSupportEmails" [value]="true"> Yes</label>
                    <label><input type="radio" name="ReceiveSupportEmails" [(ngModel)]="userModel.ReceiveSupportEmails" [value]="false"> No</label>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="flex-end mt-4">
        <button *ngIf="!hideCancel" type="button" class="btn btn-secondary-outline" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>