import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColDef } from 'ag-grid-community';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { CustomPinnedRowRendererComponent } from 'src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';

declare var $:any;

@Component({
  selector: 'fairyshrimp-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild('usersGrid') usersGrid: AgGridAngular;
  
  private currentUser: UserDto;

  public users: UserDto[];
  public columnDefs: ColDef[];
  public columnDefsUnassigned: ColDef[];
  public defaultColDef: ColDef;
  frameworkComponents: any;

  constructor(private cdr: ChangeDetectorRef, private authenticationService: AuthenticationService, private utilityFunctionsService: UtilityFunctionsService, private userService: UserService, private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.createColumnDefs();

      this.userService.usersGet().subscribe(users => {
        this.users = users;
        this.usersGrid?.api.sizeColumnsToFit();
      });
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  private createColumnDefs() {
    this.defaultColDef = { 
      resizable : true, sortable: true, filter: true
    };

    this.columnDefs = [
      {
        valueGetter: (params: any) => {
          return { LinkValue: `${params.data.UserID}/manage-parcels`, LinkDisplay: "Manage Parcels" };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: '', cssClasses: "btn btn-primary btn-sm" },
        width: 160, sortable: false, filter: false
      },
      {
        headerName: 'Name', valueGetter: function (params: any) {
          return { LinkValue: params.data.UserID, LinkDisplay: params.data.FullName };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: "/manage/users/" },
        filterValueGetter: function (params: any) {
          return params.node.rowPinned ? null : params.data.FullName;
        },
        comparator: this.utilityFunctionsService.linkRendererComparator,
        width: 170
      },
      { headerName: 'Email', field: 'Email' },
      { 
        headerName: 'Role', field: 'Role.RoleDisplayName', width: 100,
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
          field: 'Role.RoleDisplayName'
        }
      },
      {
        headerName: 'Receives System Communications?', field: 'ReceiveSupportEmails', 
        valueGetter: params => params.data.ReceiveSupportEmails ? "Yes" : "No", 
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
          field: 'ReceiveSupportEmails'
        },
        width: 250
      },
      this.utilityFunctionsService.createDateColumnDef('Create Date', 'CreateDate', 'M/d/yyyy'),
    ];

    this.columnDefsUnassigned = this.columnDefs.slice(1);
  }
}