<div class="page-header">
    <h4 class="sidebar-title mb-3">Well Registry Workflow / Step 6</h4>
    <h1 class="page-title">Attachments</h1>
</div>

<div class="page-body grid-12">
    <div class="g-col-8">
        <app-alert-display></app-alert-display>

        <div class="module-header mb-4">
            <h3 class="module-title underline">File Upload</h3>
        </div>

        <form #attachmentsForm="ngForm" class="form grid-12 mb-3">
            <div class="field g-col-12">
                <label class="field-label">File</label>
                <div class="file-upload-wrapper pb-2">

                    <label for="file-upload" class="custom-file-upload">
                        <input type="file" class="form-control" name="file-upload" [id]="fileUploadElementID" (change)="onFileUploadChange($event)" required />
                        {{ fileUpload?.name ?? 'No file chosen...' }}

                        <fresca-button cssClass="btn btn-secondary btn-upload" iconClass="ml-2 fas fa-folder-open" (onClick)="onClickFileUpload()">Browse</fresca-button>
                    </label>
                </div>
                <em>Accepted extensions: PDF, PNG, JPG, DOCX, DOC, XLSX</em>
                <field-validation-area [field]="fileUploadField"></field-validation-area>
            </div>
            <div class="field g-col-12">
                <label class="field-label">Description</label>
                <textarea class="form-control" name="file-description" [(ngModel)]="fileDescription" placeholder="Describe the content of the file"></textarea>
            </div>

            <fresca-button type="submit" cssClass="btn btn-secondary" iconClass="fas fa-file-upload mr-2" (onClick)="createWellFileResource()" [disabled]="isLoadingSubmit">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner mr-2"></span>
                Upload File
            </fresca-button>
        </form>

        <div class="module-header mt-5 mb-4">
            <h3 class="module-title underline">List of Attachments</h3>
        </div>

        <div *ngFor="let wellFileResource of wellFileResources; let index = index; let last = last">
            <div class="grid-12 flex-space-between">
                <div class="g-col-8 attachment-data">
                    <div class="pb-2"><b><a stlye="cursor:pointer;" (click)="openFileResourceLink(wellFileResource.FileResource)">{{ wellFileResource.FileResource.OriginalBaseFilename }}</a></b></div>
                    <div class="pb-2">{{ wellFileResource.FileDescription }}</div>
                    <div><em>Uploaded {{ wellFileResource.FileResource.CreateDate | date:'short'}}</em></div>
                </div>
                <div class="g-col-4 attachment-actions">
                    <button class="btn btn-rounded btn-primary mr-2" (click)="open(updateWellFileResourceModal, wellFileResource)">Edit</button>
                    <button class="btn btn-danger-bordered btn-rounded" (click)="deleteWellFileResource(wellFileResource, index)">Delete</button>
                </div>
            </div>
            <hr *ngIf="!last">
        </div>

        <div *ngIf="wellFileResources?.length == 0">
            <em>No attachments have been uploaded yet.</em>
        </div>
    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer">
    <fresca-button (onClick)="continue()">Continue</fresca-button>
</div>

<ng-template #updateWellFileResourceModal>
    <div class="modal-header">
        <h2>Edit File Description</h2>
        <button type="button" class="close" (click)="close()"><i class="fa fa-close"></i></button>
    </div>
    <div class="modal-body">
        <form #attachmentsUpdateForm="ngForm" class="form grid-12 mb-3">
            <div class="field g-col-12">
                <label class="field-label">File</label>
                <div class="pl-2"><b>{{ wellFileResourceToUpdate.FileResource.OriginalBaseFilename }}</b></div>
            </div>
            <div class="field g-col-12">
                <label class="field-label">Description</label>
                <textarea class="form-control" name="file-description" [(ngModel)]="model.FileDescription" placeholder="Describe the content of the file"></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="updateWellFileResource()">Save</button>
        <button class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
</ng-template>