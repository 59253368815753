<div class="dashboard">
    <aside class="sidebar">
        <div class="sidebar-header">
            <h5 class="sidebar-title">
                <a routerLink="/onboard">
                    <img src="assets/main/logos/MIUGSA-logo.png" alt="MIUGSA Logo" />
                    Landowner Dashboard
                </a>
            </h5>
        </div>

        <ul class="sidebar-nav">
            <li class="sidebar-item">
                <a [routerLink]="['wells']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-tint"></i>Well Registrations</a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['profile']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-user"></i>Profile</a>
            </li>
        </ul>
    </aside>

    <main class="main">
        <router-outlet></router-outlet>
    </main>
</div>
