import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { ColDef } from 'ag-grid-community';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { CustomRichTextService } from 'src/app/shared/generated/api/custom-rich-text.service';
import { CustomRichTextDto } from 'src/app/shared/generated/model/custom-rich-text-dto';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';

@Component({
  selector: 'fairyshrimp-field-definition-list',
  templateUrl: './field-definition-list.component.html',
  styleUrls: ['./field-definition-list.component.scss']
})
export class FieldDefinitionListComponent implements OnInit {

  @ViewChild("fieldDefinitionsGrid") fieldDefinitionsGrid: AgGridAngular;
  
  private currentUser: UserDto;

  public fieldDefinitions: CustomRichTextDto[];
  public richTextTypeID : number = CustomRichTextTypeEnum.LabelsAndDefinitionsList;

  public columnDefs: ColDef[];
  public defaultColDef: ColDef;

  constructor(
    private customRichTextService: CustomRichTextService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService
  ) { }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.fieldDefinitionsGrid?.api.showLoadingOverlay();

      this.customRichTextService.customRichTextFieldDefinitionsGet().subscribe(fieldDefinitions => {
        this.fieldDefinitions = fieldDefinitions;

        this.fieldDefinitionsGrid.api.hideOverlay();
        this.cdr.detectChanges();
      });

      this.columnDefs = [
        {
          headerName: 'Label', valueGetter: function (params: any) {
            return { LinkValue: params.data.CustomRichTextType.CustomRichTextTypeID, LinkDisplay: params.data.CustomRichTextType.CustomRichTextTypeDisplayName };
          }, 
          cellRendererFramework: LinkRendererComponent,
          cellRendererParams: { inRouterLink: "./" },
          filterValueGetter: params => params.data.FieldDefinitionType.FieldDefinitionTypeDisplayName,
          comparator: this.utilityFunctionsService.linkRendererComparator,
          hide: !this.canEdit()
        },
        {
          headerName: 'Label',
          field: 'CustomRichTextType.CustomRichTextTypeDisplayName',
          autoHeight: true, cellStyle: {'white-space': 'normal'},
          hide: this.canEdit()
        },
        { 
          headerName: 'Definition', field: 'CustomRichTextContent',  
          autoHeight: true, width: 900, cellStyle: {'white-space': 'normal'}
        }
      ];

      this.defaultColDef = { sortable: true, filter: true, resizable: true, width: 200 }
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  private canEdit() {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Update);
  }
}
