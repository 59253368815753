import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BoundingBoxDto } from 'src/app/shared/generated/model/bounding-box-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi } from 'ag-grid-community';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { ParcelLocationDto } from 'src/app/shared/generated/model/parcel-location-dto';
import { WellStatusEnum } from 'src/app/shared/generated/enum/well-status-enum';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import GestureHandling from 'leaflet-gesture-handling';
import { WellSimpleDto } from 'src/app/shared/generated/model/well-simple-dto';
import { ParcelDto } from 'src/app/shared/generated/model/parcel-dto';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LeafletHelperService } from 'src/app/shared/services/leaflet-helper.service';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ConfirmService } from 'src/app/services/confirm/confirm.service';

@Component({
  selector: 'wells',
  templateUrl: './wells.component.html',
  styleUrls: ['./wells.component.scss']
})
export class WellsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("wellGrid") wellGrid: AgGridAngular;
  @ViewChild("claimedParcelGrid") claimedParcelGrid: AgGridAngular;

  private currentUser: UserDto;

  public wells: WellSimpleDto[];
  public selectedWell: WellSimpleDto;
  public wellParcels: ParcelLocationDto[];
  public claimedParcels: ParcelDto[];
  public boundingBox: BoundingBoxDto;

  public map: L.Map;
  public mapID: string = 'wellMap';
  public wellLayer: L.LayerGroup;
  public selectedWellMarker: L.Marker;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};

  private wellIcon = this.leafletHelperService.wellIcon;
  private selectedWellIcon = this.leafletHelperService.selectedWellIcon;

  public wellColumnDefs: ColDef[];
  public claimedParcelColumnDefs: ColDef[];
  public defaultColDef: ColDef;

  constructor(
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private utilityFunctionsService: UtilityFunctionsService,
    private leafletHelperService: LeafletHelperService,
    private wellService: WellService,
    private alertService: AlertService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      this.userService.usersUserIDLandownerDashboardSummaryGet(currentUser.UserID).subscribe(landownerDashboardSummary => {
        this.wells = landownerDashboardSummary.Wells;
        this.wellParcels = landownerDashboardSummary.WellParcels;
        this.claimedParcels = landownerDashboardSummary.ClaimedParcels;
        this.boundingBox = landownerDashboardSummary.BoundingBox;

        this.updateWellLayer();
        this.addParcelLayer();
        this.map.fitBounds([[this.boundingBox.Bottom, this.boundingBox.Left], [this.boundingBox.Top, this.boundingBox.Right]], {});

        this.wellGrid?.api.sizeColumnsToFit();
        this.claimedParcelGrid?.api.sizeColumnsToFit();
        this.cdr.detectChanges();
      });

      this.createColumnDefs();
    });
  }

  ngAfterViewInit(): void {
    this.tileLayers = this.leafletHelperService.tileLayers;
    const mapOptions = this.leafletHelperService.defaultMapOptions;

    this.map = L.map(this.mapID, mapOptions);
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this.layerControl = L.control.layers(this.tileLayers, {}).addTo(this.map);
  }

  ngOnDestroy(): void {
    this.cdr.detach();

    this.map.off();
    this.map.remove();  
    this.map = null;
  }

  private updateSelectedWellLayer(wellID: number) {
    if (this.selectedWellMarker) {
      this.selectedWellMarker.removeFrom(this.map);
      this.map.removeLayer(this.selectedWellMarker);
      this.selectedWellMarker = null;
    }

    this.selectedWell = this.wells.find(x => x.WellID == wellID);
    if (!this.selectedWell || this.selectedWell.Latitude == null || this.selectedWell.Longitude == null) return;

    this.selectedWellMarker = L.marker([this.selectedWell.Latitude, this.selectedWell.Longitude], { icon: this.selectedWellIcon, zIndexOffset: 20 })
    .bindPopup(
      `<b>${this.selectedWell.WellName ?? "New Well"}</b>` +
      '<hr />' +
      `<b>State Well No</b>: ${this.selectedWell.StateWellNumber ?? '<em>Not Available</em>'} <br />` +
      `<b>County Well Permit</b>: ${this.selectedWell.CountyWellPermit ?? '<em>Not Available</em>'} <br />` +
      `<b>Latitude</b>: ${this.selectedWell.Latitude} <br />` +
      `<b>Longitude</b>: ${this.selectedWell.Longitude} <br />`
    );
      
    this.selectedWellMarker.addTo(this.map);
    this.selectedWellMarker.openPopup();

    this.updateWellLayer();
  }

  private updateWellLayer() {
    if (this.wellLayer) {
      this.layerControl.removeLayer(this.wellLayer)
      this.map.removeLayer(this.wellLayer);
      this.wellLayer = null;
    }

    this.wellLayer = new L.LayerGroup();
    this.wells.forEach(x => {
      if (x.WellID == this.selectedWell?.WellID) return;
      if (!x.Latitude || !x.Longitude) return;

      new L.marker([x.Latitude, x.Longitude], { wellID: x.WellID, icon: this.wellIcon, zIndexOffset: 10 })
      .on('click', event => {
        const wellID = event.target.options.wellID
        this.updateSelectedWellLayer(wellID);
        this.updateGridSelection(wellID);
      })
      .addTo(this.wellLayer);
    });

    this.layerControl.addOverlay(this.wellLayer, 'Registered Wells');
    this.wellLayer.addTo(this.map);
  }

  private addParcelLayer() {
    const parcelLayerGroup = new L.LayerGroup();

    this.wellParcels.map(x => {
      const geoJson = L.geoJSON(JSON.parse(x.GeoJson), { style: { color: "#3452DF" }, interactive: false });
      geoJson.addTo(parcelLayerGroup);
    });
    this.claimedParcels.map(x => {
      const geoJson = L.geoJSON(JSON.parse(x.GeoJson), { style: { color: "#3452DF" }, interactive: false });
      geoJson.addTo(parcelLayerGroup);
    });

    parcelLayerGroup.addTo(this.map);
    this.layerControl.addOverlay(parcelLayerGroup, 'Parcels');
  }

  private createColumnDefs() {
    this.defaultColDef = { resizable: true, sortable: true, filter: true };

    this.wellColumnDefs = [
      {
        headerName: 'Well Name', 
        valueGetter: params => {
          if (params.data.WellStatusID == WellStatusEnum.Approved) {
            return { LinkValue: params.data.WellID, LinkDisplay: params.data.WellName };
          }

          return { LinkDisplay: params.data.WellName };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: ''},
        filterValueGetter: params => params.data.WellName,
        comparator: this.utilityFunctionsService.linkRendererComparator,
        width: 170
      },
      { headerName: 'APN', field: "ParcelNumber" },
      { headerName: 'Registration ID', field: "WellID" },
      { headerName: 'Status', field: "WellStatus.WellStatusDisplayName", width: 120 },
      {
        valueGetter: (params: any) => {
          if ([WellStatusEnum.Submitted, WellStatusEnum.Approved].includes(params.data.WellStatusID)) {
            return {};
          }
          return { LinkValue: `${params.data.WellID}/edit`, LinkDisplay: "Continue" };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: '/well-registry/well/', cssClasses: "btn btn-primary btn-sm" },
        width: 100, sortable: false, filter: false
      },
      {
        valueGetter: (params: any) => {
          if (![WellStatusEnum.Draft].includes(params.data.WellStatusID)) {
            return {};
          }
          return { LinkAction: () => this.deleteRegistration(params.data.WellID), LinkDisplay: "Delete" };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { cssClasses: "btn btn-danger-outline btn-sm" },
        width: 100, sortable: false, filter: false, pinned:'right'
      },
    ];

    this.claimedParcelColumnDefs = [
      {
        valueGetter: (params: any) => {
          if (params.data.HasWell == false) return { LinkDisplay: "No Well on this Parcel"};
          return { LinkValue: "new", LinkDisplay: "Register a Well", queryParams: { parcelID: params.data.ParcelID } };
        }, 
        cellRendererFramework: LinkRendererComponent,
        cellRendererParams: params => {
          return { inRouterLink: '/well-registry/', cssClasses: params.data.HasWell ? "btn btn-primary btn-sm" : "" } // add CSS classes to empty string to style "No Well on this Parcel" message
        },
        width: 220, sortable: false, filter: false
      },
      { headerName: 'APN', field: 'ParcelNumber' },
      this.utilityFunctionsService.createDecimalColumnDef('Area (Acres)', 'ParcelAreaInAcres', 120, 2),
      {
        headerName: 'Well on Parcel', 
        valueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.HasWell),
        filterFramework: CustomDropdownFilterComponent, filterParams: {}
      },
      { 
        headerName: 'Water Sources', field: 'ParcelWaterSourceType.ParcelWaterSourceTypeDisplayName', 
        filterFramework: CustomDropdownFilterComponent, 
        filterParams: { field: 'ParcelWaterSourceType.ParcelWaterSourceTypeDisplayName' }
      },
      { headerName: 'Assessment Description', field: 'AssessmentDescription' },
    ]
  }

  onGridReady(event: any) {
    this.gridApi = event.api;
  }
  private gridApi : GridApi;

  public getRowId = params => {
    return params.WellID.toString();
  }

  public deleteRegistration(wellID: number) {
    this.confirmService.confirm({title: "Delete Well", message: `Are you sure you want to delete this well?`, buttonTextYes: "Delete", buttonClassYes: "btn-danger", buttonTextNo: "Cancel"}).then(confirmed => {
      if (confirmed) {
        this.wellService.wellsWellIDDelete(wellID).subscribe(response => {
          // remove the deleted well
          this.alertService.pushAlert(new Alert("Successfully deleted well.", AlertContext.Success, true))
          this.gridApi.applyTransaction({remove: [{WellID:wellID}]})
        });
      }
    });
  }

  public onSelectionChanged() {
    const selectedWell = this.wellGrid.api.getSelectedNodes()[0];
    if (!selectedWell) return;

    this.updateSelectedWellLayer(selectedWell.data.WellID);
  }

  private updateGridSelection(wellID: number) {
    this.wellGrid.api.forEachNode(node => {
      if (node.data.WellID == wellID) {
        node.setSelected(true, true);
      }
    });
  }
}
