import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionEnum } from '../../generated/enum/permission-enum';
import { UserDto } from '../../generated/model/user-dto';
import { RightsEnum } from '../../models/enums/rights.enum';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() user : UserDto;
  public currentUser$: Observable<UserDto>

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUser$ = this.authenticationService.getCurrentUser();
  }

  canSeeAdminDetails(currentUser : UserDto) {
    return this.authenticationService.hasPermission(currentUser, PermissionEnum.UserRights, RightsEnum.Update)
  }

}
