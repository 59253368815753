import { Injectable, ViewContainerRef } from '@angular/core';
import { MissingRequiredFieldsState } from '../shared/components/missing-required-fields-modal/missing-required-fields-state';
import { MissingRequiredFieldsOptions } from '../shared/components/missing-required-fields-modal/missing-required-fields-options';
import { MissingRequiredFieldsModalComponent } from '../shared/components/missing-required-fields-modal/missing-required-fields-modal.component';
import { ModalService } from '../shared/services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class MissingRequiredFieldsService {
  constructor(private modalService: ModalService, private state: MissingRequiredFieldsState) {}
  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<boolean>} a promise that is fulfilled when the user chooses to confirm
   * or closes the modal
   */
   error(options: MissingRequiredFieldsOptions, viewContainerRef: ViewContainerRef = null): Promise<boolean> {
     this.state.options = options;
     this.state.modal = this.modalService.open(MissingRequiredFieldsModalComponent, viewContainerRef, options);
     return this.state.modal.instance.result
   }
}
