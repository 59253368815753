import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomPageService } from '../generated/api/custom-page.service';
import { CustomRichTextTypeEnum } from '../generated/enum/custom-rich-text-type-enum';
import { CustomPageWithRolesDto } from '../generated/model/custom-page-with-roles-dto';
import { UserDto } from '../generated/model/user-dto';

@Component({
  selector: 'footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();
  public customRichTextID: number = CustomRichTextTypeEnum.Footer;
  public currentUser: UserDto;
  public data: CustomPageWithRolesDto[];
  public urlsForFooter: NameUrlDto[];

  constructor(
    public authenticationService: AuthenticationService,
    public customPageService: CustomPageService
    ) { }

    ngOnInit(): void {
      this.authenticationService.getCurrentUser().subscribe((currentUser) => {
        this.currentUser = currentUser;
        this.customPageService
              .customPagesWithRolesGet()
              .subscribe((customPagesWithRoles) => {
                  this.data = customPagesWithRoles;
                  this.urlsForFooter = this.getUrlsForFooter()
              });
      });
    }

    getUrlsForFooter() {
      var results = [];
      this.data.forEach(page => {
        if (this.canUserViewPage(page.ViewableRoles)){
          var dto = new NameUrlDto();
          dto.Name = page.CustomPageDisplayName;
          dto.Url = "/custom-pages/" + page.CustomPageVanityUrl
          results.push(dto);
        }
      });
      return results;
    }
  
    canUserViewPage(permissions){
      var roles = [];
      permissions.forEach(role => {
        roles+=role.RoleID;
      });
      if (roles.includes(this.currentUser.Role.RoleID)){
        return true;
      }
      return false;
    }
}

class NameUrlDto {
  public Name: string;
  public Url: string;
  public NameUrlDto (name: string, url:string){
    this.Name = name;
    this.Url = url;
  }
}
