<div class="edit-definition" *ngIf="fieldDefinition">

    <div class="page-header pb-2">
        <h1 class="page-title">Edit Definition for Label: {{fieldDefinition.CustomRichTextType.CustomRichTextTypeDisplayName}} </h1>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>
        <editor #tinyMceEditor
        [init]="tinyMceConfig"
        [(ngModel)]="fieldDefinition.CustomRichTextContent"
        ></editor>
    </div>


    <div class="page-footer flex-end pt-2">
        <button type="button" class="btn btn-secondary mr-2" (click)="saveDefinition()" [disabled]="isLoadingSubmit">Save</button>
        <a routerLink="../" class="btn btn-secondary-outline">Cancel</a>
    </div>
</div>