/**
 * Fairyshrimp.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ParcelDisplayDto } from '../model/parcel-display-dto';
import { ReferenceWellDto } from '../model/reference-well-dto';
import { WellBasicInfoDto } from '../model/well-basic-info-dto';
import { WellContactsUpsertDto } from '../model/well-contacts-upsert-dto';
import { WellDetailDto } from '../model/well-detail-dto';
import { WellFileResourceSimpleDto } from '../model/well-file-resource-simple-dto';
import { WellLocationDto } from '../model/well-location-dto';
import { WellProgressDto } from '../model/well-progress-dto';
import { WellSimpleDto } from '../model/well-simple-dto';
import { WellStatusDto } from '../model/well-status-dto';
import { WellStatusSimpleDto } from '../model/well-status-simple-dto';
import { WellSummaryDto } from '../model/well-summary-dto';
import { WellSupportingInfoDto } from '../model/well-supporting-info-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WellService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellSimpleDto>>;
    public wellsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellSimpleDto>>>;
    public wellsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellSimpleDto>>>;
    public wellsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellSimpleDto>>(`${this.basePath}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsPost(body?: number, observe?: 'body', reportProgress?: boolean): Observable<WellSimpleDto>;
    public wellsPost(body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellSimpleDto>>;
    public wellsPost(body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellSimpleDto>>;
    public wellsPost(body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WellSimpleDto>(`${this.basePath}/wells`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsReferenceGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ReferenceWellDto>>;
    public wellsReferenceGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReferenceWellDto>>>;
    public wellsReferenceGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReferenceWellDto>>>;
    public wellsReferenceGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ReferenceWellDto>>(`${this.basePath}/wells/reference`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsSubmittedGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellSimpleDto>>;
    public wellsSubmittedGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellSimpleDto>>>;
    public wellsSubmittedGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellSimpleDto>>>;
    public wellsSubmittedGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellSimpleDto>>(`${this.basePath}/wells/submitted`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsSummaryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellSummaryDto>>;
    public wellsSummaryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellSummaryDto>>>;
    public wellsSummaryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellSummaryDto>>>;
    public wellsSummaryGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellSummaryDto>>(`${this.basePath}/wells/summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDBasicInfoGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellBasicInfoDto>;
    public wellsWellIDBasicInfoGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellBasicInfoDto>>;
    public wellsWellIDBasicInfoGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellBasicInfoDto>>;
    public wellsWellIDBasicInfoGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDBasicInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellBasicInfoDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/basicInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellBasicInfoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDBasicInfoPost(wellID: number, wellBasicInfoDto?: WellBasicInfoDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDBasicInfoPost(wellID: number, wellBasicInfoDto?: WellBasicInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDBasicInfoPost(wellID: number, wellBasicInfoDto?: WellBasicInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDBasicInfoPost(wellID: number, wellBasicInfoDto?: WellBasicInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDBasicInfoPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/basicInfo`,
            wellBasicInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDContactsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellContactsUpsertDto>;
    public wellsWellIDContactsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellContactsUpsertDto>>;
    public wellsWellIDContactsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellContactsUpsertDto>>;
    public wellsWellIDContactsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDContactsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellContactsUpsertDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/contacts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellContactsUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDContactsPost(wellID: number, wellContactsUpsertDto?: WellContactsUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDContactsPost(wellID: number, wellContactsUpsertDto?: WellContactsUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDContactsPost(wellID: number, wellContactsUpsertDto?: WellContactsUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDContactsPost(wellID: number, wellContactsUpsertDto?: WellContactsUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDContactsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/contacts`,
            wellContactsUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDDelete(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDDelete(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDDelete(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDDelete(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDDetailGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellDetailDto>;
    public wellsWellIDDetailGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellDetailDto>>;
    public wellsWellIDDetailGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellDetailDto>>;
    public wellsWellIDDetailGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDDetailGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellDetailDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellSimpleDto>;
    public wellsWellIDGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellSimpleDto>>;
    public wellsWellIDGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellSimpleDto>>;
    public wellsWellIDGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellSimpleDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelDisplayDto>>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelDisplayDto>>>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelDisplayDto>>>;
    public wellsWellIDIrrigatedParcelsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDIrrigatedParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelDisplayDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/irrigatedParcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param parcelDisplayDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDIrrigatedParcelsPut(wellID: number, parcelDisplayDto?: Array<ParcelDisplayDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, parcelDisplayDto?: Array<ParcelDisplayDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, parcelDisplayDto?: Array<ParcelDisplayDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDIrrigatedParcelsPut(wellID: number, parcelDisplayDto?: Array<ParcelDisplayDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDIrrigatedParcelsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/irrigatedParcels`,
            parcelDisplayDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDLocationGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellLocationDto>;
    public wellsWellIDLocationGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellLocationDto>>;
    public wellsWellIDLocationGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellLocationDto>>;
    public wellsWellIDLocationGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDLocationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellLocationDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDLocationPut(wellID: number, wellLocationDto?: WellLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDLocationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/location`,
            wellLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDParcelDelete(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDParcelDelete(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDParcelDelete(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDParcelDelete(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDParcelDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/parcel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDParcelGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelDisplayDto>;
    public wellsWellIDParcelGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelDisplayDto>>;
    public wellsWellIDParcelGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelDisplayDto>>;
    public wellsWellIDParcelGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDParcelGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelDisplayDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/parcel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDParcelParcelIDPut(wellID: number, parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDParcelParcelIDPut(wellID: number, parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDParcelParcelIDPut(wellID: number, parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDParcelParcelIDPut(wellID: number, parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDParcelParcelIDPut.');
        }

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling wellsWellIDParcelParcelIDPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/parcel/${encodeURIComponent(String(parcelID))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDProgressGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellProgressDto>;
    public wellsWellIDProgressGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellProgressDto>>;
    public wellsWellIDProgressGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellProgressDto>>;
    public wellsWellIDProgressGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDProgressGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellProgressDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/progress`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param referenceWellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDReferenceWellReferenceWellIDPut(wellID: number, referenceWellID: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDReferenceWellReferenceWellIDPut(wellID: number, referenceWellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDReferenceWellReferenceWellIDPut(wellID: number, referenceWellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDReferenceWellReferenceWellIDPut(wellID: number, referenceWellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDReferenceWellReferenceWellIDPut.');
        }

        if (referenceWellID === null || referenceWellID === undefined) {
            throw new Error('Required parameter referenceWellID was null or undefined when calling wellsWellIDReferenceWellReferenceWellIDPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/referenceWell/${encodeURIComponent(String(referenceWellID))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDStatusGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellStatusDto>;
    public wellsWellIDStatusGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellStatusDto>>;
    public wellsWellIDStatusGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellStatusDto>>;
    public wellsWellIDStatusGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDStatusGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellStatusDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellStatusSimpleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDStatusPut(wellID: number, wellStatusSimpleDto?: WellStatusSimpleDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDStatusPut(wellID: number, wellStatusSimpleDto?: WellStatusSimpleDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDStatusPut(wellID: number, wellStatusSimpleDto?: WellStatusSimpleDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDStatusPut(wellID: number, wellStatusSimpleDto?: WellStatusSimpleDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDStatusPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/status`,
            wellStatusSimpleDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDSupportingInfoGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDSupportingInfoGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDSupportingInfoGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDSupportingInfoGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDSupportingInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/supportingInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellSupportingInfoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDSupportingInfoPost(wellID: number, wellSupportingInfoDto?: WellSupportingInfoDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellsWellIDSupportingInfoPost(wellID: number, wellSupportingInfoDto?: WellSupportingInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellsWellIDSupportingInfoPost(wellID: number, wellSupportingInfoDto?: WellSupportingInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellsWellIDSupportingInfoPost(wellID: number, wellSupportingInfoDto?: WellSupportingInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDSupportingInfoPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/supportingInfo`,
            wellSupportingInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWellFileResourcesGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellFileResourceSimpleDto>>;
    public wellsWellIDWellFileResourcesGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellFileResourceSimpleDto>>>;
    public wellsWellIDWellFileResourcesGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellFileResourceSimpleDto>>>;
    public wellsWellIDWellFileResourcesGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWellFileResourcesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellFileResourceSimpleDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/wellFileResources`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
