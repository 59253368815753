<div class="list-view">
    <div class="page-header">
        <h2 class="page-title">Verification Keys</h2>

        <div class="copy copy-2">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div class="filter-btn-group flex-end mb-3">
            <div class="clear-btn mr-2">
                <fairyshrimp-clear-grid-filters-button [grid]="verificationKeysGrid"></fairyshrimp-clear-grid-filters-button>
            </div>

            <div class="download-btn">
                <fairyshrimp-csv-download-button [grid]="verificationKeysGrid" fileName="verificationKeys" colIDsToExclude=""></fairyshrimp-csv-download-button>
            </div>
        </div>

        <div class="table-responsive">
            <ag-grid-angular #verificationKeysGrid style="width: 100%; height: 720px;" class="ag-theme-balham" 
            [rowData]="verificationKeys" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" [enableCellTextSelection]="true" [ensureDomOrder]="true">
            </ag-grid-angular>
        </div>
    </div>
</div>