import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ParcelDisplayDto } from 'src/app/shared/generated/model/parcel-display-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellSimpleDto } from 'src/app/shared/generated/model/well-simple-dto';
import { latlng } from 'leaflet';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';

@Component({
  selector: 'irrigated-parcels',
  templateUrl: './irrigated-parcels.component.html',
  styleUrls: ['./irrigated-parcels.component.scss']
})
export class IrrigatedParcelsComponent implements OnInit {

  private currentUser: UserDto;

  private well: WellSimpleDto;
  public wellLatLng: latlng;
  public irrigatedParcels: ParcelDisplayDto[];

  public customRichTextTypeID = CustomRichTextTypeEnum.SelectIrrigatedParcels;
  public isLoadingSubmit = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private wellService: WellService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const wellID = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (wellID) {
        forkJoin({
          well: this.wellService.wellsWellIDGet(wellID),
          irrigatedParcels: this.wellService.wellsWellIDIrrigatedParcelsGet(wellID)
        }).subscribe(({well, irrigatedParcels}) => {
          this.well = well;
          this.irrigatedParcels = irrigatedParcels;

          if (well.Latitude && well.Longitude) {
            this.wellLatLng = [well.Latitude, well.Longitude];
          }

          this.cdr.detectChanges();
        });
      }
    })
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public hasReferenceWell(): boolean {
    return Boolean(this.well?.ReferenceWellID);
  }

  public updateIrrigatedParcels(continueToNextStep?: boolean) {
    this.isLoadingSubmit = true;
    this.wellService.wellsWellIDIrrigatedParcelsPut(this.well.WellID, this.irrigatedParcels).subscribe(() => {
      this.isLoadingSubmit = false;

      if (continueToNextStep) {
        this.router.navigateByUrl(`well-registry/well/${this.well.WellID}/edit/contacts`);
      } else {
        this.alertService.pushAlert(new Alert("Irrigated parcels successfully updated.", AlertContext.Success));
      }
    }, error => {
      this.isLoadingSubmit = false;
      this.cdr.detectChanges();
    });
  }
}
