
<ng-container *ngIf="errors.length > 0">
    <div class="alert alert-danger mt-2">
        <div class="alert-content">
            <i class="fa fa-exclamation"></i>
            <ul>
                <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
        </div>
    </div>
</ng-container>