<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading">
    <ng-container *ngIf="!isEditing">
        <div *ngIf="showEditButton()" class="customRichTextContent" style="position: relative; min-height: 60px;">
            <div class="hoverEditButton">
                <div style="transform: translate3d(-10px, 20px, 0);">
                    <button type="button" class="btn btn-secondary" (click)="enterEdit()">
                        Edit
                    </button>
                </div>
            </div>

            <div *ngIf="showTitle" class="headline mb-2">
                <h3><i class="fa fa-info-circle"></i> {{ customRichTextTitle }}</h3>
            </div>

            <div *ngIf="!emptyContent" [innerHtml]="customRichTextContent"></div>
            <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
        </div>
        <div *ngIf="!showEditButton()" [innerHtml]="customRichTextContent"></div>
    </ng-container>
    <div *ngIf="isEditing">

        <div *ngIf="showTitle" class="field mb-3">
            <input type="text" [(ngModel)]="editedTitle">
        </div>

        <div class="tinyMCEFontColor">
            <editor #tinyMceEditor
            [init]="tinyMceConfig"
            [(ngModel)]="editedContent"
            ></editor>
        </div>

        <div class="flex-end">
            <button type="button" class="btn btn-secondary mt-1 mr-2" (click)="saveEdit()">Save</button>
            <button type="button" class="btn btn-secondary-outline mt-1" (click)="cancelEdit()">Cancel</button>
        </div>
    </div>
</div>