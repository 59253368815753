import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StateService } from 'src/app/shared/generated/api/state.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { StateDto } from 'src/app/shared/generated/model/state-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellContactsUpsertDto } from 'src/app/shared/generated/model/well-contacts-upsert-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';

@Component({
  selector: 'well-contacts',
  templateUrl: './well-contacts.component.html',
  styleUrls: ['./well-contacts.component.scss']
})
export class WellContactsComponent implements OnInit {

  private currentUser: UserDto;
  public wellID: number;
  public model: WellContactsUpsertDto;
  public states: StateDto[];

  public customRichTextTypeID = CustomRichTextTypeEnum.WellRegistryContacts;
  public requiredFieldsCustomRichTextTypeID = CustomRichTextTypeEnum.WellContactsRequiredFields;
  public isLoadingSubmit = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private progressService: ProgressService,
    private stateService: StateService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const id = this.route.snapshot.paramMap.get(routeParams.wellID);
      if (id) {
        this.wellID = parseInt(id);
        forkJoin ({
          wellContacts: this.wellService.wellsWellIDContactsGet(this.wellID),
          states: this.stateService.statesGet()
        }).subscribe(({wellContacts, states}) => {
          
          this.initModel(wellContacts, this.currentUser);
          this.states = states;
        });
      }
      
      this.cdr.detectChanges();
    });
  }

  initModel(wellContacts : WellContactsUpsertDto, currentUser: UserDto) : void {
    this.model = {...wellContacts};

    if(this.model.LandownerContactName == null) this.model.LandownerContactName = `${currentUser.FullName}`;
    if(this.model.LandownerBusinessName == null && currentUser.BusinessName != null) this.model.LandownerBusinessName = currentUser.BusinessName;
    if(this.model.LandownerStreetAddress == null && currentUser.StreetAddress != null) this.model.LandownerStreetAddress = currentUser.StreetAddress;
    if(this.model.LandownerCity == null && currentUser.City != null) this.model.LandownerCity = currentUser.City;
    if(this.model.LandownerStateID == null && currentUser.State?.StateID != null) this.model.LandownerStateID = currentUser.State?.StateID;
    if(this.model.LandownerZipCode == null && currentUser.ZipCode != null) this.model.LandownerZipCode = currentUser.ZipCode;
    if(this.model.LandownerPhone == null && currentUser.Phone != null) this.model.LandownerPhone = currentUser.Phone;
    if(this.model.LandownerEmail == null && currentUser.Email != null) this.model.LandownerEmail = currentUser.Email;

  }


  changeLandownerOwnerOperatorDifferent(){
    this.model.isLandownerOwnerOperatorDifferent = !this.model.isLandownerOwnerOperatorDifferent;
  }
  getLandownerOwnerOperatorDifferent(){
    return this.model.isLandownerOwnerOperatorDifferent;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateWellContacts (continueToNextStep?: boolean) {
    this.isLoadingSubmit = true;
    this.alertService.clearAlerts();

    if(!this.model.isLandownerOwnerOperatorDifferent)
    {
      this.model.OwnerOperatorContactName = this.model.LandownerContactName;
      this.model.OwnerOperatorBusinessName = this.model.LandownerBusinessName;
      this.model.OwnerOperatorStreetAddress = this.model.LandownerStreetAddress;
      this.model.OwnerOperatorCity = this.model.LandownerCity;
      this.model.OwnerOperatorStateID = this.model.LandownerStateID;
      this.model.OwnerOperatorZipCode = this.model.LandownerZipCode;
      this.model.OwnerOperatorPhone = this.model.LandownerPhone;
      this.model.OwnerOperatorEmail = this.model.LandownerEmail;
    }

    this.wellService.wellsWellIDContactsPost(this.wellID, this.model).subscribe(isContactsStepComplete => {
      this.isLoadingSubmit = false;
      this.progressService.pushContactsStepProgress(isContactsStepComplete);
      
      if (continueToNextStep) {
        this.router.navigateByUrl(`well-registry/well/${this.wellID}/edit/basic-information`);
      } else {
        this.alertService.pushAlert(new Alert("Contacts successfully updated.", AlertContext.Success));

        window.scroll(0,0);
      }

    }, error => {
      this.isLoadingSubmit = false;
      this.cdr.detectChanges();
    });
  }
}
