import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomPageService } from 'src/app/shared/generated/api/custom-page.service';
import { MenuItemService } from 'src/app/shared/generated/api/menu-item.service';
import { RoleService } from 'src/app/shared/generated/api/role.service';
import { CustomPageDto } from 'src/app/shared/generated/model/custom-page-dto';
import { CustomPageUpsertDto } from 'src/app/shared/generated/model/custom-page-upsert-dto';
import { MenuItemDto } from 'src/app/shared/generated/model/menu-item-dto';
import { RoleDto } from 'src/app/shared/generated/model/role-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { forkJoin } from 'rxjs';
import { routeParams } from 'src/app/app-routing.module';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'fairyshrimp-custom-page-edit-properties',
  templateUrl: './custom-page-edit-properties.component.html',
  styleUrls: ['./custom-page-edit-properties.component.scss']
})
export class CustomPageEditPropertiesComponent implements OnInit, OnDestroy {
  
  public currentUser: UserDto;
  public isCreating: boolean;

  public menuItems: Array<MenuItemDto>;
  public roles: Array<RoleDto>;
  public customPage: CustomPageDto;
  public model: CustomPageUpsertDto;
  public originalModel: string;
  
  public isLoading: boolean = true;
  public isLoadingSubmit: boolean = false;

  constructor(
      private cdr: ChangeDetectorRef,
      private customPageService: CustomPageService,
      private menuItemService: MenuItemService,
      private roleService: RoleService,
      private route: ActivatedRoute,     
      private router: Router, 
      private authenticationService: AuthenticationService, 
      private alertService: AlertService,
      private formValidationService: FormValidationService
    ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      this.route.data.subscribe(x => {
        this.isCreating = x.create;
      });

      this.model = new CustomPageUpsertDto();
      this.formValidationService.createValidationModelFromFieldList(['MenuItemID', 'CustomPageDisplayName', 'CustomPageVanityUrl']);
      this.model.ViewableRoleIDs = [];

      const vanityUrl = this.route.snapshot.paramMap.get(routeParams.customPageVanityUrl);
      if (!this.isCreating && vanityUrl) {
        forkJoin ({
          customPage: this.customPageService.customPagesGetByURLCustomPageVanityURLGet(vanityUrl),
          pageRoles: this.customPageService.customPagesGetByURLCustomPageVanityURLRolesGet(vanityUrl),
        }).subscribe(({customPage, pageRoles}) => {
          this.customPage = customPage;

          this.model.CustomPageDisplayName = customPage.CustomPageDisplayName;
          this.model.CustomPageVanityUrl = customPage.CustomPageVanityUrl;
          this.model.CustomPageContent = customPage.CustomPageContent;
          this.model.MenuItemID = customPage.MenuItem.MenuItemID;
          this.model.ViewableRoleIDs = pageRoles.map(pageRole => pageRole.RoleID).sort();

          this.originalModel = JSON.stringify(this.model);
          this.isLoading = false;
        });
      } else {
        this.originalModel = JSON.stringify(this.model);
      }
    });

    forkJoin ({
      menuItems: this.menuItemService.menuItemsGet(),
      roles: this.roleService.rolesGet()
    }).subscribe(({menuItems, roles}) => {
      // remove admin from role picker as admins default to viewable for all custom pages
      // and remove disabled users as well since they should not have viewable rights by default
      this.roles = roles.filter(role => role.RoleID !== RoleEnum.Admin && role.RoleID !== RoleEnum.NoAccess);
      this.menuItems = menuItems;
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  canExit() {
    return this.originalModel === JSON.stringify(this.model);
  }

  isValid(field: string): boolean {
    return this.formValidationService.isFieldValid(field);
  }

  slugifyPageName(event: any): void {
    const urlSlug = event?.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    this.model.CustomPageVanityUrl = urlSlug;
  }

  onViewableRolesChange(roleID: number): void {
    if (!this.model.ViewableRoleIDs.includes(roleID)) {
      this.model.ViewableRoleIDs.push(roleID);
    } else {
      this.model.ViewableRoleIDs = 
        this.model.ViewableRoleIDs.filter(x => x != roleID).sort();
    }
  }

  private validPageName(): boolean {
    const pattern = /^[_A-Za-z0-9\-\s]{1,100}$/;
    return pattern.test(this.model.CustomPageDisplayName);
  }

  private validVanityUrl(): boolean {
    const pattern = /^[_A-Za-z0-9\-]{1,100}$/;
    return pattern.test(this.model.CustomPageVanityUrl);
  }

  private validCustomPage(): boolean {
    var isValid = true;

    if (!this.validPageName()) {
      this.formValidationService.pushError('CustomPageDisplayName', 'Page Name must consist of letters, numbers, spaces, underscores, and hyphens only.')
      isValid = false
    }
    if (!this.validVanityUrl()) {
      this.formValidationService.pushError('CustomPageVanityUrl', 'Vanity URL must consist of letters, numbers, underscores, and hyphens only.')
      isValid = false;
    }

    return isValid;
  }

  onSubmit(): void {
    this.isLoadingSubmit = true;
    this.formValidationService.clearErrors();

    if (!this.validCustomPage()) {
      this.isLoadingSubmit = false;
      return;
    }

    if (this.isCreating) {
      this.customPageService.customPagesPost(this.model).subscribe(() => {
        this.onSubmitSuccess();
      }, error => {
        this.onSubmitFailure(error);
      });

      return;
    } 

    this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, this.model).subscribe(() => {
      this.onSubmitSuccess();
    }, error => {
      this.onSubmitFailure(error);
    });
  }

  private onSubmitSuccess() {
    this.isLoadingSubmit = false;
    this.originalModel = JSON.stringify(this.model);

    this.router.navigateByUrl(`/custom-pages/${this.model.CustomPageVanityUrl}`).then(() => {
      this.alertService.pushAlert(
        new Alert(`The custom page was successfully ${this.isCreating ? 'created' : 'updated'}.`, AlertContext.Success));
    });
  }

  private onSubmitFailure(error: HttpErrorResponse) {
    this.isLoadingSubmit = false;
    this.formValidationService.pushErrorsFromHttpResponse(error);
    this.cdr.detectChanges();
  }
}

