import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { WellStatusEnum } from 'src/app/shared/generated/enum/well-status-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellStatusDto } from 'src/app/shared/generated/model/well-status-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProgressService } from 'src/app/shared/services/progress.service';

@Component({
  selector: 'submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;

  public wellID: number;
  public declarationsModel: boolean[] = [ false ];
  public isReviewing: boolean = true;
  public workflowComplete: boolean = false;

  public isLoadingSubmit = false;
  public customRichTextTypeID = CustomRichTextTypeEnum.WellRegistrySubmit;
  public cannotSubmitCustomRichTextTypeID = CustomRichTextTypeEnum.CannotSubmitWellRegistry;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private wellService: WellService,
    private progressService: ProgressService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get(routeParams.wellID));
      if (id) {
        this.wellID = id;
        this.wellService.wellsWellIDStatusGet(id).subscribe(wellStatus => {

          if (wellStatus.WellStatusID != WellStatusEnum.Draft) {
            this.declarationsModel = [ true ];
          }

          this.isReviewing = [WellStatusEnum.Submitted, WellStatusEnum.Approved].includes(wellStatus.WellStatusID);
          if (!this.isReviewing) {
            this.workflowComplete = this.progressService.isWorkflowComplete();
          }
        });
      }

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public allDeclarationsChecked(): boolean {
    return !this.declarationsModel.includes(false);
  }

  public canSubmitWell(): boolean {
    return !this.isLoadingSubmit && !this.isReviewing && this.workflowComplete && this.allDeclarationsChecked();
  }

  public submitWell() {
    this.isLoadingSubmit = true;

    const wellStatus = new WellStatusDto({
      WellStatusID: WellStatusEnum.Submitted
    });

    this.wellService.wellsWellIDStatusPut(this.wellID, wellStatus).subscribe(() => {
      this.isLoadingSubmit = false;

      this.router.navigateByUrl(this.authenticationService.getDashboardRedirectUrl() + "/wells").then(() => {
        this.alertService.pushAlert(new Alert("Well successfully submitted for review", AlertContext.Success));
      }, error => {
        this.isLoadingSubmit = false;
        this.cdr.detectChanges();
      })
    })

  }
}
