<div class="map-wrapper pb-5">
    <parcel-map mapID="parcelMap" mapHeight="500px" [(highlightedParcelID)]="highlightedParcelID" [disableDefaultClick]="true" [showOverlayLayers]="showOverlayLayers"
    [showBaseParcelLayer]="true"
        (onParcelSelect)="onParcelMapSelectionChanged($event)" [selectedParcelIDs]="associatedParcelIDs" selectedParcelStyle="parcel_yellow"
        [selectedWellLatLng]="selectedWellLatLng" visibleParcelStyle="parcel_labeled">
    </parcel-map>
</div>

<div class="module-header mb-4">
    <h3 class="module-title underline">Add & Remove Parcels</h3>
</div>


<div class="grid-12">
    <div class="g-col-6 all">
        <div class="field-label">All Parcels</div>

        <div class="parcel-list">
            <ng-select class="search" [(ngModel)]="parcelSearchModel" [typeahead]="parcelInputs$" [minTermLength]="2" [loading]="searchLoading" (add)="onSelected($event)" [multiple]="true" placeholder="Search APNs" typeToSearchText="Begin typing to search">

                <ng-option *ngFor="let parcel of parcels$ | async" [value]="parcel" [disabled]="isAssociatedParcel[parcel.ParcelID]">
                    {{ parcel.ParcelNumber }}
                    <span *ngIf="isAssociatedParcel[parcel.ParcelID]"> (Already Added)</span>
                </ng-option>
            </ng-select>

            <div class="list-item flex-between p-2" *ngFor="let parcel of selectedParcels; let i = index" [class.recently-active]="isAssociatedParcel[parcel.ParcelID] == false" (click)="highlightParcel(parcel.ParcelID)">
                <div>
                    {{ parcel.ParcelNumber }}
                    <span *ngIf="isAssociatedParcel[parcel.ParcelID] == false" class="small">Recently Deleted</span>
                </div>
                <div class="action-icon" (click)="addAssociatedParcel(parcel, i)"><i class="fas fa-plus-circle"></i></div>
            </div>
        </div>

    </div>

    <div class="g-col-6 selected">
        <div class="field-label">{{ parcelTypeDisplayName }}</div>

        <div class="parcel-list">
            <div *ngIf="parcels?.length == 0" class="no-parcels copy copy-4">
                <p><em>This user hasn't claimed any parcels yet</em></p>
            </div>
            <div class="list-item flex-between p-2" *ngFor="let parcel of parcels; let i = index" [ngClass]="{ 'recently-active': i < recentlyAddedCount, 'no-border': i == 0 }" (click)="highlightParcel(parcel.ParcelID)">
                <div>
                    {{ parcel.ParcelNumber }}
                    <span *ngIf="i < recentlyAddedCount" class="small">Recently Added</span>
                </div>
                <div class="action-icon" (click)="removeAssociatedParcel(parcel, i)"><i class="fas fa-minus-circle"></i></div>
            </div>
        </div>
    </div>

</div>
