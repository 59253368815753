<div class="page-404">
    <div class="page-header">
        <h1 class="page-title">Page Not Found</h1>
    </div>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <div class="copy copy-2">
            <p>Oh no! It appears the page you are trying to reach doesn't exist.</p>
        </div>
    </div>
</div>