<div class="page-header">
    <h4 class="sidebar-title mb-3">Well Registry Workflow / Step 7</h4>
    <h1 class="page-title">Submit</h1>
</div>

<div class="page-body grid-12">

    <div class="g-col-8">
        <app-alert-display></app-alert-display>

        <h2>Declaration</h2>
        <form class="form pt-4" #wellRegistryDeclaration="ngForm">
            <div class="field">
                <label class="field-label">I understand, acknowledge and agree to all the following:</label>

                <div class="copy copy-2 pt-3">
                    <input type="checkbox" name="declarationCheckBox1" #declarationCheckBox1 id="declarationCheckBox1" [(ngModel)]="declarationsModel[0]" />
                    <label class="checkbox-label" for="declarationCheckBox1">
                        I declare that I am authorized to provide the information requested in this form, and all of the information and statements provided herein are true and correct to the best of my knowledge.
                    </label>
                </div>
            </div>
        </form>

    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
        </div>

        <div *ngIf="!isReviewing && !workflowComplete" class="incomplete copy copy-3">
            <custom-rich-text [customRichTextTypeID]="cannotSubmitCustomRichTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer">
    <fresca-button class="ml-auto" [disabled]="!canSubmitWell()" (onClick)="submitWell()">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Submit
    </fresca-button>
</div>