import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { environment } from 'src/environments/environment';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home-index',
    templateUrl: './home-index.component.html',
    styleUrls: ['./home-index.component.scss']
})
export class HomeIndexComponent implements OnInit, OnDestroy {
    
    public currentUser: UserDto;

    public richTextTypeID: number = CustomRichTextTypeEnum.Homepage;

    constructor(private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            //We're logging in
            if (params.hasOwnProperty("code")) {
                this.router.navigate(["/signin-oidc"], { queryParams: params });
                return;
            }
            
            //We're attempting to reset password
            if (params.hasOwnProperty("resetPassword")) {
                this.authenticationService.resetPassword(params['resetPassword']);
                return;
            }

            if (localStorage.getItem("loginOnReturn")) {
                localStorage.removeItem("loginOnReturn");
                this.authenticationService.login();
            }

            //We were forced to logout or were sent a link and just finished logging in
            if (sessionStorage.getItem("authRedirectUrl")) {
                this.router.navigateByUrl(sessionStorage.getItem("authRedirectUrl"))
                    .then(() => {
                        sessionStorage.removeItem("authRedirectUrl");
                    });
            }

            this.authenticationService.getCurrentUser().subscribe(currentUser => {
                this.currentUser = currentUser;

                if (!this.userIsUnassigned() && !this.userRoleIsDisabled()) {

                    // redirect to relevant dashboard
                    if (this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.Normal])) {
                        this.router.navigate(['/dashboard']);
                    }
                }
            });
        });
    }

    ngOnDestroy(): void {
    }

    public userIsUnassigned() {
        if (!this.currentUser) {
            return false; // doesn't exist != unassigned
        }

        return this.currentUser.Role.RoleID === RoleEnum.NoAccess && this.currentUser.IsActive;
    }

    public userRoleIsDisabled() {
        if (!this.currentUser) {
            return false; // doesn't exist != unassigned
        }

        return this.currentUser.Role.RoleID === RoleEnum.NoAccess && !this.currentUser.IsActive;
    }

    public login(): void {
        this.authenticationService.login();
    }

    public createAccount(): void {
        this.authenticationService.createAccount();
    }    

    public platformLongName(): string {
        return environment.platformLongName;
    }

    resetPassword() : void {
        this.authenticationService.resetPassword();
    }

}
