import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, TemplateRef, Type, ViewContainerRef, } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ModalComponent } from '../../components/modal/modal.component';

export class ModalTemplateComponentRef {
  templateRef : TemplateRef<any>;
  componentRef: ComponentRef<ModalComponent>;
  viewContainerRef: ViewContainerRef;
  constructor(templateRef: TemplateRef<any>, componentRef: ComponentRef<ModalComponent>, viewContainerRef: ViewContainerRef){
    this.templateRef = templateRef;
    this.componentRef = componentRef;
    this.viewContainerRef = viewContainerRef;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  
  private bodyViewContainerRef : ViewContainerRef;
  private modals : ModalTemplateComponentRef[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    ) { 
      this.bodyViewContainerRef = this.applicationRef.components.length > 0 ? (this.applicationRef.components[0].instance as AppComponent).viewRef : null;
  }

  open(content: any, viewContainerRef: ViewContainerRef = null, options: any = null) : ComponentRef<ModalComponent> {
    if(!this.modals.map(x => x.templateRef).includes(content)) {
      let viewRef = viewContainerRef ?? this.bodyViewContainerRef;

      let modal;
      if (content instanceof TemplateRef) {
        modal = this.createModalAtViewRef(content, viewRef)
      } else {
        modal = this.createFromComponent(content, options, viewRef);
      }
      
      this.modals.push(new ModalTemplateComponentRef(content, modal, viewRef));
      return modal;
    }

    let modal = this.modals.find(x => x.templateRef == content).componentRef;
    (modal.instance.dialog.nativeElement as any).showModal();

    return modal;
  }

  createFromComponent(componentType: Type<any>, context: any, viewRef: ViewContainerRef) : ComponentRef<ModalComponent> {
    // create the base modal component
    let modalComponent = viewRef.createComponent<ModalComponent>(this.componentFactoryResolver.resolveComponentFactory(ModalComponent));
    modalComponent.changeDetectorRef.detectChanges();

    // inject the componentType specified into the base modal component at the component's view container
    let component = modalComponent.instance.vc.createComponent(this.componentFactoryResolver.resolveComponentFactory(componentType))

    // todo: something better here for the context options in the case for a confirm modal
    component.instance.options = context;
    component.changeDetectorRef.detectChanges();

    return modalComponent;
  }

  createModalAtViewRef(content: any, viewRef: ViewContainerRef) : ComponentRef<ModalComponent> {
    let component = viewRef.createComponent<ModalComponent>(this.componentFactoryResolver.resolveComponentFactory(ModalComponent));
    component.instance.context = content;
    component.changeDetectorRef.detectChanges();
    return component;
  }

  close(modalComponentRef: ComponentRef<ModalComponent>, result: any = null) {
    var modalRefIndex = this.modals.findIndex(x => x.componentRef == modalComponentRef);
    if(modalRefIndex >= 0) {
      this.modals[modalRefIndex].componentRef.instance.close(result);
      var modalViewContainerRef = this.modals[modalRefIndex].viewContainerRef;
      var viewIndex = modalViewContainerRef.indexOf(modalComponentRef.hostView);
      modalViewContainerRef.remove(viewIndex);
      this.modals.splice(modalRefIndex, 1);
    }
  }

}
