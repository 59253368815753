import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { routeParams } from 'src/app/app-routing.module';
import { CustomRichTextService } from 'src/app/shared/generated/api/custom-rich-text.service';
import { CustomRichTextDto } from 'src/app/shared/generated/model/custom-rich-text-dto';

@Component({
  selector: 'fairyshrimp-field-definition-edit',
  templateUrl: './field-definition-edit.component.html',
  styleUrls: ['./field-definition-edit.component.scss']
})
export class FieldDefinitionEditComponent implements OnInit {
  
  private currentUser: UserDto;

  public fieldDefinition: CustomRichTextDto;
  public originalFieldDefinitionValue: string;

  isLoadingSubmit: boolean;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      private customRichTextService: CustomRichTextService,
      private authenticationService: AuthenticationService,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
      this.authenticationService.getCurrentUser().subscribe(currentUser => {
          this.currentUser = currentUser;
          const id = parseInt(this.route.snapshot.paramMap.get(routeParams.fieldDefinitionID));
          if (id) {
            this.customRichTextService.customRichTextCustomRichTextTypeIDGet(id).subscribe(fieldDefinition => {
              this.fieldDefinition = fieldDefinition;
              this.originalFieldDefinitionValue = fieldDefinition.CustomRichTextContent;
            })
          }
      });
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  canExit(): boolean {
    return this.fieldDefinition.CustomRichTextContent == this.originalFieldDefinitionValue;
  }

  saveDefinition(): void {
    this.isLoadingSubmit = true;

    this.customRichTextService.customRichTextCustomRichTextTypeIDPut(this.fieldDefinition.CustomRichTextType.CustomRichTextTypeID, this.fieldDefinition)
      .subscribe(response => {
        this.isLoadingSubmit = false;
        this.fieldDefinition = response;
        this.originalFieldDefinitionValue = this.fieldDefinition.CustomRichTextContent;
        
        this.router.navigateByUrl("/manage/labels-and-definitions").then(x => {
          this.alertService.pushAlert(new Alert(`The definition for ${this.fieldDefinition.CustomRichTextType.CustomRichTextTypeDisplayName} was successfully updated.`, AlertContext.Success));
        });
      }
        ,
        error => {
          this.isLoadingSubmit = false;
          this.cdr.detectChanges();
        }
      );
  }
}
