import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ParcelService } from "src/app/shared/generated/api/parcel.service";
import {  Observable, of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, filter } from 'rxjs/operators';
import { ParcelDisplayDto } from "src/app/shared/generated/model/parcel-display-dto";
import { UserService } from "src/app/shared/generated/api/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { routeParams } from "src/app/app-routing.module";

@Component({
    selector: "fairyshrimp-user-parcels-edit",
    templateUrl: "./user-parcels-edit.component.html",
    styleUrls: ["./user-parcels-edit.component.scss"],
})
export class UserParcelsEditComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;

  public user: UserDto;
  public userParcels: ParcelDisplayDto[];

  public isLoadingSubmit = false;
  public richTextTypeID = CustomRichTextTypeEnum.ManageUserParcels;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute, 
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get(routeParams.userID));
      if (id) {
        this.userService.usersUserIDGet(id).subscribe(user => {
          this.user = user;

          this.userService.usersUserIDParcelsGet(user.UserID).subscribe(userParcels => {
            this.userParcels = userParcels;
          });
          
          this.cdr.detectChanges();
        });
      }
    });
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  public updateUserParcels() {
    this.isLoadingSubmit = true;

    this.userService.usersUserIDParcelsPut(this.user.UserID, this.userParcels).subscribe(() => {
      this.isLoadingSubmit = false;

      this.router.navigateByUrl(`manage/users/${this.user.UserID}`).then(() => {
        this.alertService.pushAlert(new Alert('User parcels successfully updated', AlertContext.Success));
      });
    }, error => {
      this.isLoadingSubmit = false;
      this.cdr.detectChanges();
    });
  }
}
