import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import 'leaflet.markercluster';
import { routeParams } from 'src/app/app-routing.module';
import { WellLocationDto } from 'src/app/shared/generated/model/well-location-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';

@Component({
  selector: 'well-location',
  templateUrl: './well-location.component.html',
  styleUrls: ['./well-location.component.scss']
})
export class WellLocationComponent implements OnInit, OnDestroy {

  private currentUser: UserDto;

  public wellID: number;
  public wellModel: WellLocationDto;

  public mapID: string = 'wellLocationMap';

  public isLoadingSubmit = false;
  public richTextTypeID = CustomRichTextTypeEnum.WellRegistryLocation;

  constructor(
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private wellService: WellService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
    
      const wellID = this.route.snapshot.paramMap.get(routeParams.wellID);
      if (wellID) {
        this.wellID = parseInt(wellID);
        this.wellService.wellsWellIDLocationGet(this.wellID).subscribe(well => {
          this.wellModel = well;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
