export const environment = {
  production: false,
  staging: true,
  dev: false,
  mainAppApiUrl: "https://api-miugsa-wrp.esa-qa.sitkatech.com",
  createAccountRedirectUrl: "https://miugsa-wrp.esa-qa.sitkatech.com/create-user-callback",  
  geoserverMapServiceUrl: "https://geoserver-miugsa-wrp.esa-qa.sitkatech.com/geoserver/Fairyshrimp",
  platformLongName: "MIUGSA Well Registration Portal",
  parcelBoundingBoxLeft: -120.841518219986,
  parcelBoundingBoxRight: -120.216218621317,
  parcelBoundingBoxTop: 37.4718525078379,
  parcelBoundingBoxBottom: 37.0983621505508,
  datadogClientToken: "pub882d89ccf13ba9430169fa21d333998e"
};