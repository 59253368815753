import { Component, ComponentRef, ElementRef, Input, OnInit, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('dialog') dialog: ElementRef;
  @ViewChild("vc", {read: ViewContainerRef}) vc: ViewContainerRef;
  @Input() context : TemplateRef<any>;

  /**
	 * The promise that is resolved when the modal is closed and rejected when the modal is dismissed.
	 */
	result: Promise<any>;
  private _resolve: (result?: any) => void;
	private _reject: (reason?: any) => void;

  constructor(public viewRef: ViewContainerRef) { 
    this.result = new Promise((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});
		this.result.then(null, () => {});
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    (this.dialog.nativeElement as any).showModal();
  }

  close(result: boolean = true): void {
    (this.dialog.nativeElement as any).close();
    this._resolve(result);
  }

}
