<div class="modal-header">
    <h5 class="modal-title no-wrap">{{ options.title }}</h5>
</div>

<div class="modal-body">
    <div [innerHtml]="this.sanitizer.bypassSecurityTrustHtml(options.message)"></div>
</div>

<div class="modal-footer">
    <button class="btn btn-danger" (click)="no()">{{ options.buttonText }}</button>
</div>