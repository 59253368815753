import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UserDto } from 'src/app/shared/generated/model/models';
import { tap } from "rxjs/operators";

@Component({
  selector: 'onboard-account',
  templateUrl: './onboard-account.component.html',
  styleUrls: ['./onboard-account.component.scss']
})
export class OnboardAccountComponent implements OnInit {
  public currentUser$: Observable<UserDto>;
  public customRichTextID: CustomRichTextTypeEnum = CustomRichTextTypeEnum.OnboardAccount;
  public currentUser: UserDto;
  
  constructor(
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
      tap((user) => {this.currentUser = user;}
      )
    );
  }

  ngOnDestroy(): void { }

  clickedLogin(event: any) : void {
    this.authenticationService.login();
  }

  editSaved(userDto: UserDto): void {
    this.authenticationService.refreshUserInfo(userDto);
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
      tap((user) => {this.currentUser = user;}
      )
    );
  }

  canExit(){
    var missingFields = []
    !this.currentUser.FirstName ? missingFields.push("First Name"): null;
    !this.currentUser.LastName ? missingFields.push("Last Name") : null;
    (!this.currentUser.Phone || this.currentUser.Phone.length != 10) ? missingFields.push ("Phone Number"):null;
    return missingFields;
  }
}