import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { FontAwesomeIconLinkRendererComponent } from "src/app/shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { ContextMenuRendererComponent } from "src/app/shared/components/ag-grid/context-menu/context-menu-renderer.component";
import { ConfirmService } from "src/app/services/confirm/confirm.service";


@Component({
    selector: "fairyshrimp-custom-page-list",
    templateUrl: "./custom-page-list.component.html",
    styleUrls: ["./custom-page-list.component.scss"],
})
export class CustomPageListComponent implements OnInit, OnDestroy {
    @ViewChild("pageGrid") pageGrid: AgGridAngular;

    public currentUser: UserDto;

    public canCreate: boolean;
    public canEdit: boolean;
    public canDelete: boolean;

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;

    public richTextTypeID: number = CustomRichTextTypeEnum.CustomPages;
    public isPerformingAction: boolean = false;

    constructor(
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private customPageService: CustomPageService,
        private utilityFunctionService: UtilityFunctionsService,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;

            this.canCreate = this.authenticationService.hasPermission(
                this.currentUser,
                PermissionEnum.CustomPageRights,
                RightsEnum.Create
            );
            this.canEdit = this.authenticationService.hasPermission(
                this.currentUser,
                PermissionEnum.CustomPageRights,
                RightsEnum.Update
            );
            this.canDelete = this.authenticationService.hasPermission(
                this.currentUser,
                PermissionEnum.CustomPageRights,
                RightsEnum.Delete
            );

            this.pageGrid?.api.showLoadingOverlay();
            this.createColumnDefs();
            this.updateGridData();
        });
    }

    onFirstDataRendered(params): void {
        this.pageGrid.api.sizeColumnsToFit();
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Actions",
                valueGetter: (params: any) => {
                    var actions = [];
                    if (this.canEdit) {
                        actions.push({ ActionName: "Edit", ActionIcon: "fas fa-edit", ActionLink: `./edit-properties/${params.data.CustomPageVanityUrl}` });
                    }
                    if (this.canDelete) {
                        actions.push({ ActionName: "Delete", ActionIcon: "fas fa-trash", ActionHandler: () => this.deleteCustomPage(params.data.CustomPageID, params.data.CustomPageDisplayName) });
                    }
                    return actions;
                },
                cellRendererFramework: ContextMenuRendererComponent,
                cellClass: "context-menu-container",
                sortable: false, filter: false, width: 200,
                hide: !this.canEdit && !this.canDelete,
            },
            {
                headerName: "Page Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CustomPageVanityUrl,
                        LinkDisplay: params.data.CustomPageDisplayName,
                    };
                },
                cellRendererFramework: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/custom-pages/" },
                comparator: this.utilityFunctionService.linkRendererComparator,
                filter: true,
                filterValueGetter: function (params: any) {
                    return params.data.CustomPageDisplayName;
                },
                sortable: true,
            },
            {
                headerName: "Menu",
                field: "MenuItem.MenuItemDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "MenuItem.MenuItemDisplayName",
                },
                sortable: true,
            },
            {
                headerName: "Has Content",
                field: "IsEmptyContent",
                valueGetter: function (params) {
                    return params.data.IsEmptyContent ? "No" : "Yes";
                },
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "IsEmptyContent",
                },
                sortable: true,
            },
            {
                headerName: "Viewable By",
                field: "ViewableRoles",
                valueGetter: function (params) {
                    return params.data.ViewableRoles.map(
                        (x) => x.RoleDisplayName
                    );
                },
                valueFormatter: function (params) {
                    return params.data.ViewableRoles.map(
                        (x) => x.RoleDisplayName
                    ).join(", ");
                },
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "RoleDisplayName",
                    columnContainsMultipleValues: true,
                },
                sortable: true,
            },
        ];

        this.defaultColDef = { filter: true, sortable: true, resizable: true };
    }

    public updateGridData(): void {
        this.customPageService
            .customPagesWithRolesGet()
            .subscribe((customPagesWithRoles) => {
                this.rowData = customPagesWithRoles;
                this.pageGrid.api.hideOverlay();
                this.cdr.detectChanges();
            });
    }

    public deleteCustomPage(
        customPageID: number,
        customPageDisplayName: string
    ) {
        this.confirmService.confirm({
          title: 'Delete Custom Page',
          message: `You are about to delete custom page ${customPageDisplayName}. This action cannot be undone. Are you sure you wish to proceed?`,
          buttonTextYes: 'Delete',
          buttonClassYes: 'btn-danger',
          buttonTextNo: 'Cancel'
        }).then(confirmed => {
          if (confirmed) {
            this.isPerformingAction = true;
            this.customPageService.customPagesCustomPageIDDelete(customPageID).subscribe(() => {
              this.isPerformingAction = false;
              this.alertService.pushAlert(new Alert(`Custom page successfully deleted.`, AlertContext.Success, true));
              this.updateGridData();
            }, error => {
              this.isPerformingAction = false;
              this.alertService.pushAlert(new Alert(`There was an error deleting the page. Please try again.`, AlertContext.Danger, true));
            });
          }
        });
    }
}
