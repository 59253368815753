<dialog #dialog>
    <ng-container #vc>
        <ng-content></ng-content>
        <ng-template
            [ngTemplateOutlet]="context"
        ></ng-template>
    </ng-container>
    
    
</dialog>


