<header class="site-header">
    <div *ngIf="showTestingWarning()" class="qa-warning">
        <span class="fa fa-warning"></span>
        {{ testingWarningText() }}
    </div>

    <nav class="site-nav">
        <a [routerLink]="getLogoRouterLink()" routerLinkActive="active" class="site-logo">
            <img src="assets/main/logos/well-registry-logo.svg" alt="Well Registry" />
        </a>

        <button #mobileMenuButton class="hamburger-btn" [dropdownToggle]="mobileMenuToggle" type="button" aria-controls="hamburger-btn" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
            </svg>
        </button>

        <div class="site-nav__wrapper" #mobileMenuToggle>
            <div class="close-menu-btn flex-end" (click)="toggleMobileMenu()">
                <i class="fa fa-times" [style.cursor]="'pointer'"></i>
            </div>

            <a routerLink="/" routerLinkActive="active" class="site-logo-mobile" (click)="toggleMobileMenu()">
                <img src="assets/main/logos/well-registry-logo.svg" alt="Well Registry" />
            </a>

            <ul class="site-nav__links site-nav__main">
                <li class="nav-item" *ngIf="isAuthenticated()">
                    <a routerLink="/dashboard" class="nav-link" role="button" routerLinkActive="active" (click)="toggleMobileMenu()"> Dashboard </a>
                </li>

                <li class="nav-item" *ngIf="hasManageMenu()">
                    <a routerLink="/manage" class="nav-link" role="button" routerLinkActive="active" (click)="toggleMobileMenu()"> Manage </a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="learnMorePages.length > 0">
                    <a href="javascript:void(0);" [dropdownToggle]="learnMoreToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Learn More </a>

                    <div #learnMoreToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let learnMorePage of learnMorePages" routerLink="/custom-pages/{{ learnMorePage.CustomPageVanityUrl }}" class="dropdown-item" (click)="toggleMobileMenu()">
                            {{ learnMorePage.CustomPageDisplayName }}
                        </a>
                    </div>
                </li>
            </ul>

            <div class="register-cta">
                <a *ngIf="isAuthenticated() && !isUnassignedOrDisabled()" routerLink="/well-registry/new" class="btn btn-white-outline btn-small btn-rounded" (click)="toggleMobileMenu()"> + Register a Well </a>
            </div>

            <ul class="site-nav__links site-nav__utilities" #testRef>
                <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                    <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link">Login</a>
                </li>

                <li class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="isAuthenticated()">
                    <a href="javascript:void(0);" [dropdownToggle]="helpToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="username">{{ isCurrentUserBeingImpersonated() ? "Impersonating" : "Welcome" }} {{ getUserName() }}</span>
                    </a>

                    <div #helpToggle class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <!--
                        <a routerLink="/help" class="dropdown-item" (click)="toggleMobileMenu()">
                            Get Help
                        </a>
                        -->
                        <a class="dropdown-item" [routerLink]="['/dashboard', 'profile']">Profile</a>
                        <a href="javascript:void(0);" (click)="logout()" class="dropdown-item">{{ isCurrentUserBeingImpersonated() ? "Stop Impersonating" : "Sign Out" }}</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
