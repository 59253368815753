<div class="page-header">
    <h1 class="page-title">Create an Account</h1>
</div>

<div class="page-body grid-12">
    <div class="g-col-8">
        

        <div class="account-wrapper">
            <div class="login">
                <ng-container *ngIf="currentUser$ | async as currentUser else notSignedIn">
                    <app-alert-display></app-alert-display>
                    <div class="copy copy-2 mb-4">
                        <p *ngIf="currentUser">You are signed in as <strong>{{currentUser.FullName}}</strong> (<strong>{{currentUser.Email}}</strong>)</p>
                    </div>

                    <user-profile-edit [user]="currentUser" [hideCancel]="true" (onEditClose)="editSaved($event)"></user-profile-edit>
                </ng-container>
                

                <ng-template #notSignedIn>
                    <fresca-button (onClick)="clickedLogin($event)"><i class="fa fa-user"></i> Login or Sign Up</fresca-button>
                </ng-template>
            </div>

            <ng-container *ngIf="currentUser$ | async as currentUser">
                <div class="mailer">
                    <div class="section-header">
                        <h3 class="section-title">Do you have a mailer with a verification key?</h3>
                    </div>

                    <div class="options">
                        <div class="option-a option">
                            <div class="option__header">
                                <h4><span>Yes</span>, I Do</h4>
                            </div>

                            <div class="option__body">
                                <div class="copy copy-3">
                                    <p>Great. Let's finish the onboarding process by claiming your parcels with your verification key.</p>
                                </div>
                            </div>

                            <div class="option__footer">
                                <fresca-button class="ml-auto" [routerLink]="['../verification-keys']">Continue</fresca-button>
                            </div>
                        </div>

                        <div class="option-b option">
                            <div class="option__header">
                                <h4><span>No</span>, I Do Not</h4>
                            </div>

                            <div class="option__body">
                                <div class="copy copy-3">
                                    <p>That's ok. We can take you straight to you your dashboard where you can start registering your wells immediately.</p>
                                </div>
                            </div>

                            <div class="option__footer">
                                <fresca-button [routerLink]="['/dashboard']">Dashboard</fresca-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextID"></custom-rich-text>
        </div>
    </div>
</div>