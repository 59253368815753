import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routeParams } from 'src/app/app-routing.module';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';

@Component({
  selector: 'confirm-location',
  templateUrl: './confirm-location.component.html',
  styleUrls: ['./confirm-location.component.scss']
})
export class ConfirmLocationComponent implements OnInit {

  public mapID = "confirmWellLocationMap";
  public wellID: number;
  public customRichTextTypeID = CustomRichTextTypeEnum.ConfirmWellLocation;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const wellID = this.route.snapshot.paramMap.get(routeParams.wellID);
    if (wellID) {
      this.wellID = parseInt(wellID);
    }
  }
}
