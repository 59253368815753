<div class="page-header">
    <h4 class="sidebar-title mb-3">Well Registry Workflow / Step 4</h4>
    <h1 class="page-title">Basic Information</h1>
</div>

<div class="page-body grid-12">

    <form class="form mb-3 g-col-8" *ngIf="model">
        <app-alert-display></app-alert-display>

        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="requiredFieldsCustomRichTextTypeID"></custom-rich-text>
        </div>

        <div class="field grid-9">
            <div class="module-header">
                <h2 class="module-title underline">Well Description</h2>
            </div>

            <div class="field">
                <label class="required field-label" >Owner's Well Name/No.</label>
                <input type="text" class="form-control" name="WellName" required [(ngModel)]="model.WellName" />
                <field-definition fieldDefinitionType="WellName"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class="field-label">State Well No. (SWN)</label>
                <input type="text" class="form-control" name="StateWellNumber" [(ngModel)]="model.StateWellNumber"/>
                <field-definition fieldDefinitionType="StateWellNumber"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class="field-label">State WCR No.</label>
                <input type="text" class="form-control" name="StateWellCompletionNumber" [(ngModel)]="model.StateWellCompletionNumber" />
                <field-definition fieldDefinitionType="StateWCRNumber"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class="field-label">County Well Permit</label>
                <input type="text" class="form-control" name="CountyWellPermit" [(ngModel)]="model.CountyWellPermit" />
                <field-definition fieldDefinitionType="CountyWellPermit"></field-definition>
            </div>
            <div class="field g-col-half">
                <label class="required field-label">Date Drilled</label>
                <input type="date" class="form-control" name="DateDrilled" required [(ngModel)]="model.DateDrilled"/>
                <field-validation-area field="DateDrilled"></field-validation-area>
                <field-definition fieldDefinitionType="DateDrilled"></field-definition>
            </div>

            <h2 class="module-title underline water-use-header pt-4"><span class="required">Water Use Type</span></h2>
            <div class="water-use-option" *ngFor="let w of model.WellWaterUses">
                <input type="checkbox" class="form-control" [id]="w.WellWaterUseTypeID" [name]="w.WellWaterUseTypeID" [checked]="getSelected(w) ? true : false" (click)="changeSelected(w)" />

                <label class="checkbox-label" [for]="w.WellWaterUseTypeID">
                    <b>{{w.WellWaterUseTypeName}}</b>
                </label>

                <div class="hideContainer wellWaterUseText" *ngIf="getSelected(w)">
                    <div class="field">
                        <label class="required field-label field-label__small">Water Use Description</label>
                        <textarea class="form-control" name="wellWaterUse_{{w.WellWaterUseTypeID}}" [(ngModel)]="w.WellWaterUseDescription"></textarea>
                        <field-definition *ngIf="getFieldDefinition(w.WellWaterUseTypeName) as fieldDefinitionType" [fieldDefinitionType]="fieldDefinitionType"></field-definition>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="sidebar-help g-col-4">
        <div class="copy copy-3">
            <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>

<div class="page-footer flex-end">
    <fresca-button class="mr-2" (onClick)="updateWellBasics()" [disabled]="isLoadingSubmit" cssClass="btn btn-primary-outline" iconClass="">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Save
    </fresca-button>

    <fresca-button (onClick)="updateWellBasics(true)" [disabled]="isLoadingSubmit">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Save & Continue
    </fresca-button>
</div>