
<ng-container *ngIf="params.value.LinkAction != undefined else noAction">
    <button [className]="params.cssClasses" (click)="params.value.LinkAction()">
        {{params.value.LinkDisplay}}
        </button>
</ng-container>

<ng-template #noAction>
    <ng-container *ngIf="(params.value.LinkValue) else noLinkValue">
        <a *ngIf="params.value.LinkValue" [className]="params.cssClasses"
            [routerLink]="[params.inRouterLink + params.value.LinkValue]" [queryParams]="params.value.queryParams">
            {{params.value.LinkDisplay}}
        </a>
    </ng-container>
</ng-template>

<ng-template #noLinkValue>
    <span *ngIf="params.value.LinkDisplay" [className]="params.cssClasses">{{params.value.LinkDisplay}}</span>
</ng-template>