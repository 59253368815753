import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FlagEnum } from '../generated/enum/flag-enum';

@Injectable({
  providedIn: 'root'
})

export class CanManageGuard implements CanActivate {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService) { }

    canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return forkJoin([
        this.authenticationService.getCurrentUser(),
      ]).pipe(
        map(([user]) => {
          if(this.authenticationService.hasFlag(user, FlagEnum.CanManage)) {
            return true;
          }
          return this.returnUnauthorized();
        })
      );
  }

  private returnUnauthorized() {
    this.router.navigate(["/"]).then(() => {
      this.alertService.pushNotFoundUnauthorizedAlert();
    });
    return false;
  }
}
