import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmOptions } from "src/app/services/confirm/confirm-options";
import { ConfirmState } from "src/app/services/confirm/confirm-state";
import { ModalService } from "../../services/modal/modal.service";

@Component({
    selector: "upward-confirm-modal",
    templateUrl: "./confirm-modal.component.html",
    styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent {
    options: ConfirmOptions;

    constructor(private state: ConfirmState, public sanitizer: DomSanitizer, private modalService: ModalService) {
        this.options = state.options;
    }

    yes() {
        this.modalService.close(this.state.modal, true)
    }

    no() {
        this.modalService.close(this.state.modal, false)
    }
}
