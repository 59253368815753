<div class="page-header">
	<h1 class="page-title">Users</h1>

	<div class="copy copy-2">
		<p>A list of users is shown in the grid below.</p>
	</div>
</div>

<div class="page-body">
	<app-alert-display></app-alert-display>

	<div class="all-users">
		<div class="user-actions flex-end pb-2">
			<fairyshrimp-clear-grid-filters-button class="mr-3" [grid]="usersGrid"></fairyshrimp-clear-grid-filters-button>
			<fairyshrimp-csv-download-button [grid]="usersGrid" fileName="users" colIDsToExclude=""></fairyshrimp-csv-download-button>
		</div>

		<div class="table-responsive">
			<ag-grid-angular #usersGrid style="width: 100%; height: 800px;" class="ag-theme-balham" [rowData]="users" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" [suppressMenuHide]="true">
			</ag-grid-angular>
		</div>
	</div>
</div>